import "./i18n/i18n"
import "./globals.css"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { HelmetProvider, Helmet } from "react-helmet-async"
import { PostHogProvider } from "posthog-js/react";

import AnalyticsHandler from "@/components/app/AnalyticsHandler.tsx";
import CapacitorHandler from "@/components/app/CapacitorHandler.tsx";
import CapacitorLiveUpdatesHandler from "@/components/app/CapacitorLiveUpdatesHandler.tsx";
import RevenueCatHandler from "@/components/app/RevenueCatHandler.tsx";
import DeepLinksHandler from "@/components/app/DeepLinksHandler.tsx";
import ShareIntentHandler from "@/components/app/ShareIntentHandler.tsx";

import { ThemeProvider } from "@/components/theme/ThemeProvider.tsx";
import { Toaster } from "@/components/ui/toaster.tsx";

import BottomNav from "@/components/navigation/BottomNav.tsx"
import DesktopNav from "@/components/navigation/DesktopNav.tsx"

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from "react-router-dom"

import ProtectedRoute from "./components/routes/ProtectedRoute.tsx"
import GuestRoute from "./components/routes/GuestRoute.tsx"

// Auth
import AuthAction from "./routes/auth/action.tsx"
import SignUp from "./routes/(auth)/signup.tsx"
import Login from "./routes/(auth)/login.tsx"
import ForgotPassword from "./routes/(auth)/forgotPassword.tsx"
import ResetPassword from "./routes/(auth)/resetPassword.tsx"

// Account
import Account from "./routes/account/index.tsx"
import ChangeEmail from "./routes/account/change-email/index.tsx"
import ChangePassword from "./routes/account/change-password/index.tsx"
import DeleteAccount from "./routes/account/delete-account/index.tsx"

// Subscription
import Upgrade from "./routes/subscription/upgrade"
import UpgradeSuccess from "@/routes/subscription/upgrade/success";

// Collections
import CollectionsPage from "./routes/collections/index.tsx"
import CollectionPage from "./routes/collections/[id]/index.tsx"

// Recipes
import RecipesPage from "./routes/recipes/index.tsx"
import RecipePage from "./routes/recipes/[id]/index.tsx"
import GenerateRecipe from "./routes/recipes/generate/index.tsx"
import GenerateRecipeLoadingPage from "./routes/recipes/generate/loading/index.tsx"
import ImportImage from "./routes/recipes/import-image/index.tsx"
import ImportImageLoadingPage from "./routes/recipes/import-image/loading/index.tsx"
import ImportSocialMedia from "./routes/recipes/import-social-media/index.tsx"
import ImportSocialMediaLoadingPage from "./routes/recipes/import-social-media/loading/index.tsx"
import AddRecipe from "./routes/recipes/add/index.tsx"
import EditRecipe from "./routes/recipes/[id]/edit/index.tsx"

// Lists
import ListsPage from "./routes/lists/index.tsx"
import ListPage from "./routes/lists/[id]/index.tsx"

// 404
import NotFound from "./routes/NotFound.tsx"

function App() {
  const isMobile = useIsMobile()

  return (
    <BrowserRouter>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Flavorish"
        />

        <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_API_KEY} options={{ api_host: 'https://us.i.posthog.com' }}>
          <ThemeProvider defaultTheme="dark" storageKey="flavorish-theme">
            {/* Fixes elements being visible while scrolling under the iPhone status bar in-app, doesn't affect Android  */}
            <div id="app-navbar-cover" className="sticky top-0 w-full bg-background z-[10000]" />

            {/* Used for gradients on icons - https://stackoverflow.com/a/66095144/6581796 */}
            <svg width="0" height="0">
              <linearGradient id="ai-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
                <stop stopColor="#2563EB" offset="20%" />
                <stop stopColor="#7C3AED" offset="45%" />
                <stop stopColor="#E11D48" offset="80%" />
              </linearGradient>
            </svg>

            {!isMobile && (
              <DesktopNav />
            )}

            <main className="flex flex-col flex-grow">
              <Routes>
                <Route path="/" element={<ProtectedRoute><RecipesPage /></ProtectedRoute>} />

                {/* Auth */}
                <Route path="/auth/action" element={<GuestRoute><AuthAction /></GuestRoute>} />
                <Route path="/signup" element={<GuestRoute><SignUp /></GuestRoute>} />
                <Route path="/login" element={<GuestRoute><Login /></GuestRoute>} />
                <Route path="/forgot-password" element={<GuestRoute><ForgotPassword /></GuestRoute>} />
                <Route path="/reset-password" element={<GuestRoute><ResetPassword /></GuestRoute>} />

                {/* Account */}
                <Route path="/account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
                <Route path="/account/change-email" element={<ProtectedRoute><ChangeEmail /></ProtectedRoute>} />
                <Route path="/account/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
                <Route path="/account/delete-account" element={<ProtectedRoute><DeleteAccount /></ProtectedRoute>} />

                {/* Subscription */}
                <Route path="/subscription/upgrade" element={<ProtectedRoute><Upgrade /></ProtectedRoute>} />
                <Route path="/subscription/upgrade/success" element={<ProtectedRoute><UpgradeSuccess /></ProtectedRoute>} />

                {/* Collections */}
                <Route path="/collections" element={<ProtectedRoute><CollectionsPage /></ProtectedRoute>} />
                <Route path="/collections/:id" element={<ProtectedRoute><CollectionPage /></ProtectedRoute>} />

                {/* Recipes */}
                <Route path="/recipes" element={<ProtectedRoute><RecipesPage /></ProtectedRoute>} />
                <Route path="/recipes/:id" element={<ProtectedRoute><RecipePage /></ProtectedRoute>} />
                <Route path="/recipes/generate" element={<ProtectedRoute><GenerateRecipe /></ProtectedRoute>} />
                <Route path="/recipes/generate/loading" element={<ProtectedRoute><GenerateRecipeLoadingPage /></ProtectedRoute>} />
                <Route path="/recipes/import-image" element={<ProtectedRoute><ImportImage /></ProtectedRoute>} />
                <Route path="/recipes/import-image/loading" element={<ProtectedRoute><ImportImageLoadingPage /></ProtectedRoute>} />
                <Route path="/recipes/import-social-media" element={<ProtectedRoute><ImportSocialMedia /></ProtectedRoute>} />
                <Route path="/recipes/import-social-media/loading" element={<ProtectedRoute><ImportSocialMediaLoadingPage /></ProtectedRoute>} />
                <Route path="/recipes/add" element={<ProtectedRoute><AddRecipe /></ProtectedRoute>} />
                <Route path="/recipes/:id/edit" element={<ProtectedRoute><EditRecipe /></ProtectedRoute>} />

                {/* Lists */}
                <Route path="/lists" element={<ProtectedRoute><ListsPage /></ProtectedRoute>} />
                <Route path="/lists/:id" element={<ProtectedRoute><ListPage /></ProtectedRoute>} />

                {/* 404 */}
                <Route path="*" element={<NotFoundRouteHandler />} />
              </Routes>
            </main>

            {isMobile && (
              <BottomNav />
            )}

            <Toaster />

            <AnalyticsHandler />
            <CapacitorHandler />
            <CapacitorLiveUpdatesHandler />
            <RevenueCatHandler />
            <DeepLinksHandler />
            <ShareIntentHandler />
          </ThemeProvider>
        </PostHogProvider>

      </HelmetProvider>
    </BrowserRouter>
  )
}

export default App



{/* iOS devices seem to be hitting this url for some reason, which is sending them to the 404 page */}
{/* capacitor://localhost/ai.flavorish.app://google/link/?request_ip_version=IP%5FV6&match_message=No%20pre%2Dinstall%20link%20matched%20for%20this%20device%2E */}
{/* This started happening after enabling Capacitor deep links. It seems to be caused by Firebase Dynamic Links even though we've never set that up on this project. */}
{/* I've found the following error in XCode logs when recreating the issue: */}
{/* [FirebaseAnalytics][I-ACS023001] Deep Link does not contain valid required params. URL params: {
                        "match_message" = "No pre-install link matched for this device.";
                        "request_ip_version" = "IP_V4";
                    } */}
{/* Run this command in terminal to reproduce the issue on simulator: */}
{/* xcrun simctl openurl booted "flavorish://localhost/ai.flavorish.app://google/link/?request_ip_version=IP%5FV4&match_message=No%20pre%2Dinstall%20link%20matched%20for%20this%20device%2E" */}
{/* As a temporary solution, redirect users to the home page when they encounter this link */}
{/* Thanks to Claude 3.5 Sonnet for actually being useful in trying to figure this out. ChatGPT was useless. */}
function NotFoundRouteHandler() {
  const location = useLocation()

  if(location.pathname.includes('ai.flavorish.app') && location.pathname.includes('google/link')) {
    console.log('Redirecting from:', location.pathname);
    return <Navigate to="/" replace />;
  }
  // If it's not the problematic URL, show the 404 page
  return <NotFound />;
}