import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import LogoImg from "@/assets/icon.svg"
import FavShare1 from "@/assets/images/import-social-media-guides/ios/FavShare 1.png"
import FavShare2 from "@/assets/images/import-social-media-guides/ios/FavShare 2.png"
import FavShare3 from "@/assets/images/import-social-media-guides/ios/FavShare 3.png"
import FavShare4 from "@/assets/images/import-social-media-guides/ios/FavShare 4.png"
import FavShare5 from "@/assets/images/import-social-media-guides/ios/FavShare 5.png"
import FavShare6 from "@/assets/images/import-social-media-guides/ios/FavShare 6.png"

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

export default function ImportFavouriteShareOptionGuide({ isOpen = false, setIsOpen }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-h-dvh overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <img src={LogoImg} alt="Flavorish" className="max-w-8 max-h-8" />
            {t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.title")}
          </DialogTitle>
          <DialogDescription>
            {t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.description")}
          </DialogDescription>
        </DialogHeader>

        <img src={FavShare1} alt="" className="my-4" />

        <div className="mt-4 space-y-8">
          <div>
            <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step1.title")}</h3>
            <p>{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step1.text")}</p>
            <img src={FavShare2} alt="" className="my-4" />
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step2.title")}</h3>
            <p>{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step2.text")}</p>
            <img src={FavShare3} alt="" className="my-4" />
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step3.title")}</h3>
            <p>{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step3.text")}</p>
            <img src={FavShare4} alt="" className="my-4" />
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step4.title")}</h3>
            <p>{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step4.text")}</p>
            <img src={FavShare5} alt="" className="my-4" />
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step5.title")}</h3>
            <p>{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.step5.text")}</p>
            <img src={FavShare6} alt="" className="my-4" />
          </div>

          <p>{t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.content.conclusion")}</p>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="primary">
              {t("recipes.importSocialMedia.components.ImportFavouriteShareOptionGuide.actions.close")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}