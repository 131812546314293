import { useState, useEffect } from 'react';

import { auth, analytics } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { setUserProperties } from "firebase/analytics";

export default function useUserSubscriptionStatus() {
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const [subscriptionPlatform, setSubscriptionPlatform] = useState<"web" | "app" | null>(null)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown | undefined>(undefined);

  const [user, loadingUser, errorUser] = useAuthState(auth)

  useEffect(() => {
    async function fetchSubscription() {
      if(user) {
        try {
          // Force refresh is required
          await user.getIdToken(true)
          const decodedToken = await user.getIdTokenResult()

          if(decodedToken.claims.stripeRole && decodedToken.claims.stripeRole === "premium") {
            setIsUserSubscribed(true)
            setSubscriptionPlatform("web")

            setUserProperties(analytics, { is_user_subscribed: true })

            // @ts-ignore
          } else if(decodedToken.claims.revenueCatEntitlements && decodedToken.claims.revenueCatEntitlements.includes(import.meta.env.VITE_REVENUE_CAT_PREMIUM_ENTITLEMENT_ID)) {
            setIsUserSubscribed(true)
            setSubscriptionPlatform("app")

            setUserProperties(analytics, { is_user_subscribed: true })
          } else {
            setUserProperties(analytics, { is_user_subscribed: false })
          }

          setLoading(false)
        } catch(e) {
          console.error(e)

          setError(e)
          setLoading(false)
        }
      }

      if(errorUser) {
        setError(errorUser)
        setLoading(false)
      }
    }

    fetchSubscription()
  }, [user])

  return { isUserSubscribed, subscriptionPlatform, loading, error };
}