import { useEffect } from "react";

import { Capacitor } from "@capacitor/core";
import { LiveUpdate } from '@capawesome/capacitor-live-update';

// https://capawesome.io/blog/announcing-the-capacitor-live-update-plugin/
export default function CapacitorLiveUpdatesHandler() {
  useEffect(() => {
    (async function() {
      if(Capacitor.isNativePlatform()) {
        await LiveUpdate.ready();

        const result = await LiveUpdate.sync();
        if (result.nextBundleId) {
          await LiveUpdate.reload();
        }
      }
    })()
  }, [])

  return null
}