import { useEffect } from "react"

import { auth } from "@/firebase/app.ts"
import { useAuthState } from "react-firebase-hooks/auth"

import { usePostHog } from "posthog-js/react";
import * as Sentry from "@sentry/react";

export default function AnalyticsHandler() {
  const posthog = usePostHog()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  useEffect(() => {
    if(user) {
      const userObj: any = {
        id: user.uid
      }
      if(user.email) userObj.email = user.email

      posthog?.identify(user.uid, {
        email: user?.email
      })

      // Set user in Sentry so we can find which user faced what error
      Sentry.setUser(userObj)
    }
  }, [user]);

  return null
}