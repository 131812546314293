import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { Button, ButtonProps } from "@/components/ui/button.tsx";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu.tsx";

import { Ellipsis, Pencil, Trash2 } from "lucide-react";

import { Collection } from "@/firebase/types.ts";

type Props = {
  collection: Collection
  setShowRenameCollectionModal: Dispatch<SetStateAction<boolean>>
  setCurrentRenamingCollection?: (collection: Collection) => void
  setShowDeleteCollectionModal: Dispatch<SetStateAction<boolean>>
  setCurrentDeletingCollection?: (collection: Collection) => void
}
export default function CollectionOptions({ collection, setShowRenameCollectionModal, setCurrentRenamingCollection, setShowDeleteCollectionModal, setCurrentDeletingCollection }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

  const TriggerButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ ...props }, ref) => (
    <Button
      type="button"
      variant="ghost"
      size="icon"
      ref={ref}
      {...props}
    >
      <Ellipsis size={20} />
    </Button>
  ))

  const RenameCollectionButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={e => {
        e.preventDefault()
        if(setCurrentRenamingCollection) {
          setCurrentRenamingCollection(collection)
        }
        setShowRenameCollectionModal(true)

        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <Pencil size={isMobile ? 20 : 16} />
      {t("collections.[id].components.general.CollectionOptions.actions.rename")}
    </Button>
  )

  const DeleteCollectionButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={e => {
        e.preventDefault()
        if(setCurrentDeletingCollection) {
          setCurrentDeletingCollection(collection)
        }
        setShowDeleteCollectionModal(true)

        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <Trash2 size={isMobile ? 20 : 16} />
      {t("collections.[id].components.general.CollectionOptions.actions.delete")}
    </Button>
  )

  return (
    <>
      {isMobile ? (
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          <DrawerTrigger asChild>
            <TriggerButton />
          </DrawerTrigger>

          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>
                {t("collections.[id].components.general.CollectionOptions.menuTitle")}
              </DrawerTitle>
            </DrawerHeader>

            <div className="flex flex-col gap-2 mx-auto w-full py-4">
              <RenameCollectionButton />
              <DeleteCollectionButton />
            </div>
          </DrawerContent>
        </Drawer>
      ) : (
        <DropdownMenu open={dropdownMenuOpen} onOpenChange={setDropdownMenuOpen}>
          <DropdownMenuTrigger asChild>
            <TriggerButton />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              {t("collections.[id].components.general.CollectionOptions.menuTitle")}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              <DropdownMenuItem className="p-0">
                <RenameCollectionButton />
              </DropdownMenuItem>

              <DropdownMenuItem className="p-0">
                <DeleteCollectionButton />
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}