import { useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";

import { loginWithApple, loginWithGoogle } from "@/lib/auth/oauth.tsx";
import { checkIsWebView } from "@/lib/helpers/checkIsWebView.ts";

import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";

export default function SocialLogins({ callback = (() => {}) }) {
  const { t } = useTranslation()
  const { toast } = useToast()

  // If user is viewing this page from a webview (in-app browser from instagram, facebook, tikitok, etc.)
  // Don't show Google login option as it's blocked in these browsers. This prevents the user from seeing an error
  let isWebview = false

  if(window) {
    isWebview = checkIsWebView(window.navigator.userAgent)
  }

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle(callback)
    } catch(e) {
      console.error(e)

      toast({
        title: t("(auth).components.socialLogins.google.button.onClick.error.title"),
        description: t("(auth).components.socialLogins.google.button.onClick.error.description"),
        variant: "destructive"
      })
    }
  }

  const handleAppleLogin = async () => {
    try {
      await loginWithApple(callback)
    } catch(e) {
      console.error(e)

      toast({
        title: t("(auth).components.socialLogins.apple.button.onClick.error.title"),
        description: t("(auth).components.socialLogins.apple.button.onClick.error.description"),
        variant: "destructive"
      })
    }
  }

  return (
    <div className="space-y-4">
      {/* Don't show Google login in webviews like Instagram or Tiktok built-in browsers */}
      {/* Keep showing it in the webview of our app though */}
      {!isWebview || (isWebview && Capacitor.isNativePlatform()) ? (
        <Button
          type="button"
          variant="outline"
          className="flex items-center gap-2 border-foreground w-full"
          onClick={handleGoogleLogin}
        >
          <FcGoogle size={24} className="flex-shrink-0" />
          {t("(auth).components.socialLogins.google.button.text")}
        </Button>
      ) : null}

      <Button
        type="button"
        variant="outline"
        className="flex items-center gap-2 border-foreground w-full"
        onClick={handleAppleLogin}
      >
        <FaApple size={24} className="flex-shrink-0" />
        {t("(auth).components.socialLogins.apple.button.text")}
      </Button>
    </div>
  )
}