import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import Page from "@/components/page/Page.tsx";

import { Button } from "@/components/ui/button.tsx";

import AIStars from "@/components/icons/ai-stars/AIStars.tsx";

import { BadgeCheck } from "lucide-react";

export default function UpgradeSuccess() {
  const { t } = useTranslation()

  return (
    <Page className="justify-center items-center gap-4 px-8 max-w-lg mx-auto">
      <Helmet>
        <title>{t("subscription.upgradeSuccess.page.head.title")}</title>
      </Helmet>

      <BadgeCheck size={100} className="text-primary" />

      <h2 className="font-semibold text-2xl text-center">{t("subscription.upgradeSuccess.page.title")}</h2>
      <p className="text-center mb-4">{t("subscription.upgradeSuccess.page.text")}</p>

      <Button
        variant="outline"
        asChild
        className="gap-2"
      >
        <Link to="/recipes/generate">
          <AIStars size={24} />
          {t("subscription.upgradeSuccess.page.actions.generateAIRecipe")}
        </Link>
      </Button>

      <Button
        variant="link"
        asChild
      >
        <Link to="/">
          {t("subscription.upgradeSuccess.page.actions.home")}
        </Link>
      </Button>
    </Page>
  )
}