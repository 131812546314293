import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RECIPE_IMAGE_IMPORTS } from "@/globals.js";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Progress } from "@/components/ui/progress.tsx";

type Props = {
  freeRecipeImageImportsUsed: number
}
export default function FreeRecipeImageImportsUsed({ freeRecipeImageImportsUsed }: Props) {
  const { t } = useTranslation()
  const importsUsedPercentage = (100 * freeRecipeImageImportsUsed) / RECIPE_IMAGE_IMPORTS.FREE

  return (
    <>
      {freeRecipeImageImportsUsed > 0 && (
        <Card className="mx-4 mb-4">
          <CardHeader className="flex flex-row justify-between items-center pb-2">
            <CardTitle className="text-lg">
              {t("recipes.components.general.FreeRecipeImageImportsUsed.title")}
            </CardTitle>

            <Button
              type="button"
              variant="primary"
              size="sm"
              asChild
              className="h-6 !m-0"
            >
              <Link to="/subscription/upgrade">
                {t('recipes.components.general.FreeRecipeImageImportsUsed.actions.upgrade')}
              </Link>
            </Button>
          </CardHeader>

          <CardContent>
            <Progress value={importsUsedPercentage} />

            <div className="flex justify-between items-center mt-2">
              <p className="text-sm">
                {t('recipes.components.general.FreeRecipeImageImportsUsed.progress.numUsed', { numUsed: freeRecipeImageImportsUsed, total: RECIPE_IMAGE_IMPORTS.FREE })}
              </p>
              <p className="text-sm">{t('recipes.components.general.FreeRecipeImageImportsUsed.progress.percentageUsed', { percentage: importsUsedPercentage })}</p>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  )
}