import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form";

import { analytics, db } from "@/firebase/app"
import { deleteDoc, doc } from "firebase/firestore"
import { logEvent } from "firebase/analytics";

import { deleteRecipeImage } from "@/lib/images.ts"

import { Button } from "@/components/ui/button.tsx";
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog.tsx";
import { Form, FormRootErrorMessage } from "@/components/ui/form.tsx";

import { Loader2 } from "lucide-react";

import { Recipe } from "@/firebase/types"

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  recipe: Recipe | null
}

export default function DeleteRecipeModal({ isOpen = false, setIsOpen, recipe }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const form = useForm()

  async function onSubmit() {
    try {
      if(recipe?.id) {
        if(recipe.image) {
          // Delete the image
          try {
            await deleteRecipeImage(recipe.id, recipe.image.name)
          } catch(e) {
            console.error(e)
            // Don't do anything on fail, continue deleting the recipe
            // TODO: If something goes wrong, log this somewhere?
          }
        }

        // Removed await to fix an issue where the recipe page shows the 404 page before we navigate away from it on delete
        deleteDoc(doc(db, "recipes", recipe.id))

        logEvent(analytics, 'delete_recipe', { isImported: recipe.imported, isAIGenerated: recipe.aiGenerated, isImageImported: recipe.imageImported, isSocialMediaImported: recipe.socialMediaImported })

        navigate("/recipes")
      } else {
        throw new Error(`${t('recipes.[id].components.modals.DeleteRecipeModal.modal.form.onSubmit.error.missingParams')}`)
      }
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("recipes.[id].components.modals.DeleteRecipeModal.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <AlertDialogHeader>
              <AlertDialogTitle>{t("recipes.[id].components.modals.DeleteRecipeModal.modal.title", { recipeTitle: recipe?.title })}</AlertDialogTitle>
              <AlertDialogDescription>
                {t('recipes.[id].components.modals.DeleteRecipeModal.modal.description')}
              </AlertDialogDescription>
            </AlertDialogHeader>

            <AlertDialogFooter>
              <AlertDialogCancel>{t('recipes.[id].components.modals.DeleteRecipeModal.modal.form.actions.cancel')}</AlertDialogCancel>

              <Button
                type="submit"
                variant="destructive"
                disabled={form.formState.isSubmitting}
              >
                {form.formState.isSubmitting ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t("recipes.[id].components.modals.DeleteRecipeModal.modal.form.actions.delete")}
              </Button>
            </AlertDialogFooter>

            <FormRootErrorMessage />
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}