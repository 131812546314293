import { useState } from "react";
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form";
import { z } from "zod";

import { analytics, auth } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { logEvent } from "firebase/analytics";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx";
import ErrorLoading from "@/components/page/ErrorLoading.tsx";

import useUserSubscriptionStatus from "@/hooks/useUserSubscriptionStatus.tsx";
import useUserData from "@/hooks/useUserData.tsx";

import { RECIPE_GENERATIONS } from "@/globals.js";

import Header from "@/components/navigation/Header.tsx";
import FreeAIRecipeGenerationsUsed from "@/routes/recipes/generate/components/general/FreeAIRecipeGenerationsUsed.tsx"

import ByRecipe from "@/routes/recipes/generate/components/steps/ByRecipe.tsx";
import ByIngredients from "@/routes/recipes/generate/components/steps/ByIngredients.tsx";
import Options from "@/routes/recipes/generate/components/steps/Options.tsx";
import Nutrition from "@/routes/recipes/generate/components/steps/Nutrition.tsx";
import AdditionalInformation from "@/routes/recipes/generate/components/steps/AdditionalInformation.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Form, FormRootErrorMessage } from "@/components/ui/form.tsx";

import AIStars from "@/components/icons/ai-stars/AIStars.tsx";
import { Carrot, ChevronRight, Gift, Loader2, Utensils } from "lucide-react";

import { GenerateRecipeFormInputs as formSchema, ActiveStep } from "@/routes/recipes/generate/types";

export default function GenerateRecipe() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [user, loading, error] = useAuthState(auth)

  const [activePath, setActivePath] = useState<'byRecipe' | 'byIngredients' | null>(null)
  const [activeStep, setActiveStep] = useState<ActiveStep>(null)
  let activeStepNum = 0
  if(activeStep === 'options') {
    activeStepNum = 1
  } else if(activeStep === 'nutrition') {
    activeStepNum = 2
  } else if(activeStep === 'additionalInformation') {
    activeStepNum = 3
  }

  const { isUserSubscribed, loading: loadingUserSubscriptionStatus } = useUserSubscriptionStatus()
  const { userData } = useUserData()

  // Ensure count is not out of date
  let freeAIRecipeGenerationsUsed = userData?.freeAIRecipeGenerationsUsed || 0
  const freeAIRecipeGenerationsLimitReached = !loadingUserSubscriptionStatus && !isUserSubscribed && freeAIRecipeGenerationsUsed >= RECIPE_GENERATIONS.FREE

  const form = useForm<z.infer<typeof formSchema>>({
    // Using the normal form register methods instead of this
    // resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      title: "",
      ingredients: [{ name: "" }],
      onlyIngredients: false,
      cuisine: "",
      mealType: "",
      skillLevel: 2,
      time: 75,
      spiceLevel: 5,
      dietaryPreferences: "",
      allergies: "",
      calories: "",
      carbs: "",
      proteins: "",
      fats: "",
      additionalInformation: ""
    }
  })

  function goToNextStep() {
    if(activeStep === 'byRecipe') {
      setActiveStep('options')
    } else if(activeStep === 'byIngredients') {
      setActiveStep('options')
    } else if(activeStep === 'options') {
      setActiveStep('nutrition')
    } else if(activeStep === 'nutrition') {
      // This state change triggers the submit button to be shown
      // Without the timeout, react is running a click function on the button when it renders...
      setTimeout(() => setActiveStep('additionalInformation'), 0)
    }
  }

  async function submitRecipe(fields: any) {
    if(user && userData) {
      logEvent(analytics, 'generated_ai_recipe')

      // Navigate to the loading page where we will submit the API requests
      // Pass all form info we need there
      navigate(`/recipes/generate/loading`, {
        state: {
          fields
        }
      })
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      // Just in case the uid is missing
      if(!user?.uid) {
        throw new Error("User is missing uid!")
      }

      const submittedFields: any = { ...values }

      if(activePath === 'byIngredients') {
        let ingredientsArr = values.ingredients.map(ingredient => ingredient.name)
        ingredientsArr = ingredientsArr.filter(ingredient => ingredient !== "")

        submittedFields.ingredients = ingredientsArr
        delete submittedFields.title
      } else {
        delete submittedFields.ingredients
        delete submittedFields.onlyIngredients
      }

      submitRecipe(submittedFields)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "recipes.generate.page.form.onSubmit.generic",
        message: `${t("recipes.generate.page.form.onSubmit.error.generic")}`
      })
    }
  }

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("recipes.generate.page.head.title")}</title>
      </Helmet>

      <Header
        goBackFunction={() => {
          if(activeStep === null) {
            navigate('/recipes')
          } else {
            if(activeStep === 'byRecipe' || activeStep === 'byIngredients') {
              setActiveStep(null)
            } else if(activeStep === 'options') {
              if(activePath === 'byRecipe') {
                setActiveStep('byRecipe')
              } else if(activePath === 'byIngredients') {
                setActiveStep('byIngredients')
              }
            } else if(activeStep === 'nutrition') {
              setActiveStep('options')
            } else if(activeStep === 'additionalInformation') {
              setActiveStep('nutrition')
            }
          }
        }}
      />

      <div className="flex flex-col flex-grow w-full max-w-4xl mx-auto">
        {/* Page before the form */}
        {activeStep === null && (
          <>
            {!loadingUserSubscriptionStatus && !isUserSubscribed && (
              <FreeAIRecipeGenerationsUsed freeAIRecipeGenerationsUsed={freeAIRecipeGenerationsUsed} />
            )}

            <div className="p-4">
              <h2 className="flex flex-wrap justify-center items-center gap-2 font-semibold text-2xl text-center">
                <AIStars size={24} />
                {t('recipes.generate.page.title')}
              </h2>
              <p className="text-center">{t('recipes.generate.page.description')}</p>

              <div className="flex flex-col gap-2 mt-4">
                <Button
                  type="button"
                  variant="outline"
                  size="lg"
                  className="justify-between h-auto text-lg py-2 px-6"
                  onClick={() => {
                    setActiveStep('byRecipe')
                    setActivePath('byRecipe')
                  }}
                  disabled={freeAIRecipeGenerationsLimitReached}
                >
                  <div className="flex items-center gap-4">
                    <div className="bg-primary/20 rounded-md p-2">
                      <Utensils size={24} className="text-primary" />
                    </div>
                    {t('recipes.generate.page.buttons.byRecipe')}
                  </div>
                  <ChevronRight size={24} />
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  size="lg"
                  className="justify-between h-auto text-lg py-2 px-6"
                  onClick={() => {
                    setActiveStep('byIngredients')
                    setActivePath('byIngredients')
                  }}
                  disabled={freeAIRecipeGenerationsLimitReached}
                >
                  <div className="flex items-center gap-4">
                    <div className="bg-primary/20 rounded-md p-2">
                      <Carrot size={24} className="text-primary" />
                    </div>
                    {t('recipes.generate.page.buttons.byIngredients')}
                  </div>
                  <ChevronRight size={24} />
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  size="lg"
                  className="justify-between h-auto text-lg py-2 px-6"
                  onClick={() => submitRecipe({ randomize: true })}
                  disabled={freeAIRecipeGenerationsLimitReached}
                >
                  <div className="flex items-center gap-4">
                    <div className="bg-primary/20 rounded-md p-2">
                      <Gift size={24} className="text-primary" />
                    </div>
                    {t('recipes.generate.page.buttons.byRandom')}
                  </div>
                  <ChevronRight size={24} />
                </Button>
              </div>
            </div>
          </>
        )}

        {/* Stepper above the form */}
        {activeStep !== null && (
          <div className="w-full flex justify-between gap-4 mb-8 px-4">
            {activePath === 'byRecipe' && (
              <button
                type="button"
                className={`flex-grow border-t-[3px] text-sm text-left pt-1 ${activeStepNum >= 0 ? 'border-primary' : ''}`}
                onClick={() => setActiveStep("byRecipe")}
              >
                {t('recipes.generate.page.steps.byRecipe')}
              </button>
            )}

            {activePath === 'byIngredients' && (
              <button
                type="button"
                className={`flex-grow border-t-[3px] text-sm text-left pt-1 ${activeStepNum >= 0 ? 'border-primary' : ''}`}
                onClick={() => setActiveStep("byIngredients")}
              >
                {t('recipes.generate.page.steps.byIngredients')}
              </button>
            )}

            <button
              type="button"
              className={`flex-grow border-t-[3px] text-sm text-left pt-1 ${activeStepNum >= 1 ? 'border-primary' : ''}`}
              onClick={() => setActiveStep("options")}
            >
              {t('recipes.generate.page.steps.options')}
            </button>

            <button
              type="button"
              className={`flex-grow border-t-[3px] text-sm text-left pt-1 ${activeStepNum >= 2 ? 'border-primary' : ''}`}
              onClick={() => setActiveStep("nutrition")}
            >
              {t('recipes.generate.page.steps.nutrition')}
            </button>

            <button
              type="button"
              className={`flex-grow border-t-[3px] text-sm text-left pt-1 ${activeStepNum >= 3 ? 'border-primary' : ''}`}
              onClick={() => setActiveStep("additionalInformation")}
            >
              {t('recipes.generate.page.steps.additionalInformation')}
            </button>
          </div>
        )}

        <Form {...form}>
          <form
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col flex-grow max-lg:justify-between"
          >
            <div className="px-4 pb-4 space-y-4">
              {activeStep === 'byRecipe' && (
                <ByRecipe form={form} />
              )}

              {activeStep === 'byIngredients' && (
                <ByIngredients form={form} />
              )}

              {activeStep === 'options' && (
                <Options form={form} />
              )}

              {activeStep === 'nutrition' && (
                <Nutrition form={form} />
              )}

              {activeStep === 'additionalInformation' && (
                <AdditionalInformation form={form} />
              )}

              <FormRootErrorMessage />
            </div>

            {activeStep !== null && (
              <div id="generate-recipe-form-buttons" className="bg-background max-lg:border-t p-4">
                {activeStep === 'additionalInformation' ? (
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    className="w-full"
                    disabled={form.formState.isSubmitting}
                  >
                    {form.formState.isSubmitting ? (
                      <Loader2 size={24} className="animate-spin" />
                    ) : t("recipes.generate.page.actions.submit")}
                  </Button>
                ) : (
                  <div className="flex items-center gap-2">
                    <Button
                      type="button"
                      variant="accent"
                      size="lg"
                      className="w-full"
                      disabled={!form.formState.isValid}
                      onClick={goToNextStep}
                    >
                      {t('recipes.generate.page.actions.skip')}
                    </Button>

                    <Button
                      type="button"
                      variant="primary"
                      size="lg"
                      className="w-full"
                      disabled={!form.formState.isValid}
                      onClick={goToNextStep}
                    >
                      {t('recipes.generate.page.actions.continue')}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </form>
        </Form>
      </div>
    </Page>
  )
}
