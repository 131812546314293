import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";

import Apple from "@/assets/images/app-store-badges/apple.svg"
import Google from "@/assets/images/app-store-badges/google.png"

export default function DownloadTheApp() {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("account.components.sections.downloadTheApp.title")}
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col">
        <div className="flex items-center gap-2">
          <Link to="https://apps.apple.com/us/app/flavorish-smart-recipe-app/id6478546136" target="_blank">
            <img src={Apple} alt="Download on the App Store" className="w-auto h-[52px]" />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=ai.flavorish.app" target="_blank">
            <img src={Google} alt="Get it on Google Play" className="w-auto max-h-[52px]" />
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}