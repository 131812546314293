import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { Capacitor } from "@capacitor/core";
import { Purchases, PURCHASES_ERROR_CODE, PurchasesOffering, PurchasesOfferings } from "@revenuecat/purchases-capacitor";

import Loading from "@/components/page/Loading.tsx";
import ErrorLoading from "@/components/page/ErrorLoading.tsx";

import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import LogoImg from "@/assets/icon.svg";
import DeliciousImg from "@/assets/images/delicious.png"

import { ArrowLeft, Check, Loader2 } from "lucide-react";

export default function App() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [offerings, setOfferings] = useState<PurchasesOfferings | null>(null)
  const [selectedPackage, setSelectedPackage] = useState<PurchasesOffering["identifier"] | null>(null)

  const [isPurchasing, setIsPurchasing] = useState(false)
  const [isRestoring, setIsRestoring] = useState(false)

  const [erroredOut, setErroredOut] = useState(false)

  useEffect(() => {
    (async function() {
      try {
        const fetchedOfferings = await Purchases.getOfferings();
        if(fetchedOfferings.current !== null) {
          setOfferings(fetchedOfferings)
          setSelectedPackage(fetchedOfferings.current.availablePackages[0].identifier)
        }
      } catch(error) {
        console.error(error)

        setErroredOut(true)
      }
    })()
  }, []);

  async function purchaseSubscription() {
    setIsPurchasing(true)

    try {
      if(offerings && offerings.current) {
        const packageToPurchase = offerings.current.availablePackages.filter((currPackage) => currPackage.identifier === selectedPackage)[0]

        const purchaseResult = await Purchases.purchasePackage({
          aPackage: packageToPurchase
        });

        if(purchaseResult.customerInfo.entitlements.active.hasOwnProperty(import.meta.env.VITE_REVENUE_CAT_PREMIUM_ENTITLEMENT_ID)) {
          navigate('/subscription/upgrade/success')
        }

        setIsPurchasing(false)
      }
    } catch(error: any) {
      console.error(error)

      if(error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        toast({
          title: t("subscription.upgrade.components.App.purchaseSubscription.error.cancelled.title"),
          description: t("subscription.upgrade.components.App.purchaseSubscription.error.cancelled.description"),
          variant: "destructive"
        })
      } else {
        toast({
          title: t("subscription.upgrade.components.App.purchaseSubscription.error.generic.title"),
          description: t("subscription.upgrade.components.App.purchaseSubscription.error.generic.description"),
          variant: "destructive"
        })
      }

      setIsPurchasing(false)
    }
  }

  async function restorePurchase() {
    setIsRestoring(true)

    try {
      const customerInfo = await Purchases.restorePurchases();

      if(customerInfo.customerInfo.entitlements.active.hasOwnProperty(import.meta.env.VITE_REVENUE_CAT_PREMIUM_ENTITLEMENT_ID)) {
        toast({
          title: t("subscription.upgrade.components.App.restorePurchase.success.title"),
          description: t("subscription.upgrade.components.App.restorePurchase.success.description"),
          variant: "success"
        })
        navigate('/')
      } else {
        toast({
          title: t("subscription.upgrade.components.App.restorePurchase.error.noEntitlement.title"),
          description: t("subscription.upgrade.components.App.restorePurchase.error.noEntitlement.description"),
          variant: "destructive"
        })
      }

      setIsRestoring(false)
    } catch(error) {
      console.error(error)

      toast({
        title: t("subscription.upgrade.components.Web.startCheckout.error.generic.title"),
        description: t("subscription.upgrade.components.Web.startCheckout.error.generic.description"),
        variant: "destructive"
      })

      setIsRestoring(false)
    }
  }

  if(offerings === null && !erroredOut) {
    return <Loading />
  }

  if(erroredOut) {
    return <ErrorLoading />
  }

  return (
    <div className="lg:flex lg:items-center lg:flex-grow lg:gap-8 lg:max-w-7xl lg:mx-auto">
      <div className="relative flex flex-col justify-center items-center max-h-60 sm:max-h-96 lg:max-h-none overflow-hidden">
        <div className="absolute left-0 top-0 right-0 w-full p-4">
          <Button
            type="button"
            variant="background"
            size="icon"
            className="z-10"
            onClick={() => navigate(-1)}
            aria-label={t('subscription.upgrade.components.App.header.goBackButton.label')}
          >
            <ArrowLeft size={20} />
          </Button>
        </div>
        <img
          src={DeliciousImg}
          alt={t('subscription.upgrade.components.App.header.image.alt')}
          className="object-cover object-center w-full h-full"
        />
      </div>

      <div className="max-w-2xl mx-auto lg:min-w-[40%] px-4 py-4 lg:px-8">
        <div className="flex items-center mb-4">
          <img
            src={LogoImg}
            alt="Flavorish"
            className="w-8 mr-4"
          />
          <h2 className="font-poppins text-2xl font-semibold">{t('subscription.upgrade.components.App.title')}</h2>
        </div>

        <p className="text-lg font-semibold mb-2">{t('subscription.upgrade.components.App.subtitle')}</p>

        <ul className="flex flex-col gap-4 mb-8">
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.App.benefits.1')}
            </p>
          </li>
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.App.benefits.2')}
            </p>
          </li>
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.App.benefits.3')}
            </p>
          </li>
          <li className="flex gap-2">
            <Check
              size={24}
              strokeWidth={3}
              className="flex-shrink-0 text-primary"
            />
            <p>
              {t('subscription.upgrade.components.App.benefits.4')}
            </p>
          </li>
        </ul>

        {offerings && (
          <>
            <div className="flex flex-col gap-2 mb-8">
              {offerings.current?.availablePackages.map((currPackage) => (
                <Button
                  key={currPackage.identifier}
                  type="button"
                  variant="outline"
                  className={`block w-full h-auto ${selectedPackage === currPackage.identifier ? 'border-primary' : ''}`}
                  onClick={() => setSelectedPackage(currPackage.identifier)}
                >
                  <div className="flex justify-between items-center gap-4 mb-2">
                    <div className="flex items-center gap-2">
                      <div className={`flex justify-center items-center w-6 h-6 ${selectedPackage === currPackage.identifier ? 'bg-primary' : 'bg-muted'} rounded-full`}>
                        {selectedPackage === currPackage.identifier && (
                          <Check
                            size={18}
                            strokeWidth={3}
                            className="flex-shrink-0 text-primary-foreground"
                          />
                        )}
                      </div>

                      <p className="text-lg font-semibold">
                        {currPackage.product.priceString}
                        {currPackage.packageType === 'ANNUAL' && t('subscription.upgrade.components.App.offerings.perYear')}
                        {currPackage.packageType === 'MONTHLY' && t('subscription.upgrade.components.App.offerings.perMonth')}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-left">{currPackage.product.description}</p>
                  </div>
                </Button>
              ))}
            </div>

            <div className="flex flex-col items-center gap-4">
              <Button
                type="button"
                size="lg"
                variant="primary"
                className="w-full"
                onClick={purchaseSubscription}
                disabled={isPurchasing}
              >
                {isPurchasing ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t('subscription.upgrade.components.App.actions.subscribe')}
              </Button>

              <Button
                type="button"
                size="sm"
                variant="ghost"
                className="w-full"
                onClick={restorePurchase}
                disabled={isRestoring}
              >
                {isRestoring ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t('subscription.upgrade.components.App.actions.restore')}
              </Button>
            </div>

            {Capacitor.getPlatform() === 'ios' && (
              <p className="my-8 text-xs text-neutral dark:text-neutral-content/60">
                <Trans i18nKey="subscription.upgrade.components.App.iosLegalText">
                  Subscriptions will be applied to your iTunes account on confirmation. Subscriptions will
                  automatically renew unless canceled within 24-hours before the end of the current period.
                  You can cancel anytime with your iTunes account settings. For more information, see our
                  <Link to="https://www.flavorish.ai/terms-of-use" target="_blank" className="link">terms of use</Link>
                  and
                  <Link to="https://www.flavorish.ai/privacy-policy" target="_blank" className="link">privacy policy</Link>.
                </Trans>
              </p>
            )}

            {Capacitor.getPlatform() === 'android' && (
              <p className="my-8 text-xs text-neutral dark:text-neutral-content/60">
                <Trans i18nKey="subscription.upgrade.components.App.androidLegalText">
                  Subscriptions will be applied to your Google account on confirmation. Subscriptions will
                  automatically renew unless canceled within 24-hours before the end of the current period.
                  You can cancel anytime with your Google account settings. For more information, see our
                  <Link to="https://www.flavorish.ai/terms-of-use" target="_blank" className="link">terms of use</Link>
                  and
                  <Link to="https://www.flavorish.ai/privacy-policy" target="_blank" className="link">privacy policy</Link>.
                </Trans>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}