import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Capacitor } from "@capacitor/core";

import { analytics, auth, db } from "@/firebase/app"
import { useAuthState } from "react-firebase-hooks/auth"
import { doc, serverTimestamp, setDoc } from "firebase/firestore"
import { logEvent } from "firebase/analytics";
import { uuidv4 } from "@firebase/util"

import getRecipeData, { RecipeData } from "@/lib/recipe-importer"

import ImportRecipeGuide from "@/routes/recipes/components/modals/ImportRecipeGuide.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { Info, Loader2 } from "lucide-react";

import { Recipe } from "@/firebase/types"

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

export default function ImportRecipe({ isOpen = false, setIsOpen }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [showImportRecipeGuideModal, setShowImportRecipeGuideModal] = useState(false)

  const [user, loading, error] = useAuthState(auth)

  const formSchema = z.object({
    url: z.string()
      .min(1, {
        message: t("recipes.components.modals.ImportRecipe.modal.form.fields.url.validation.required")
      })
      .refine((val) => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g.test(val), {
        message: t("recipes.components.modals.ImportRecipe.modal.form.fields.url.validation.pattern")
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: "",
    }
  })

  // Check if importRecipe exists in url query, if it does, show the importRecipe modal with the url autofilled
  // Only works on pages with this component imported, but honestly we don't need it anywhere else
  // Note this is used when a link from a recipe website is shared to the Flavorish app
  useEffect(() => {
    const importRecipe = searchParams.get('importRecipe')

    if(importRecipe) {
      setIsOpen(true)
      form.setValue('url', importRecipe)

      // Delete link from search params so it doesn't run again
      searchParams.delete('importRecipe')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { url } = values

    const result = await getRecipeData(url)

    if(result === "invalidURL") {
      form.setError("url", {
        message: `${t("recipes.components.modals.ImportRecipe.modal.form.fields.url.validation.pattern")}`
      })
    }
    if(result === "genericError") {
      form.setError("root", {
        message: `${t("recipes.components.modals.ImportRecipe.modal.form.onSubmit.error.generic")}`
      })
    }

    if(
      typeof result === 'object' &&
      !Array.isArray(result) &&
      result !== null
    ) {
      try {
        // Just in case the uid is missing
        if(!user?.uid) {
          throw new Error("User is missing uid!")
        }

        const id = uuidv4()

        const importedRecipe: Recipe = {
          _created: serverTimestamp(),
          _updated: serverTimestamp(),
          userId: user.uid,
          id: id,
          ...result as RecipeData
        }

        await setDoc(doc(db, "recipes", id), importedRecipe)

        logEvent(analytics, 'import_recipe')

        setIsOpen(false)

        navigate(`/recipes/${id}`)
      } catch(e) {
        console.error(e)

        form.setError("root", {
          type: "recipes.components.modals.ImportRecipe.modal.form.onSubmit.error.generic",
          message: `${t("recipes.components.modals.ImportRecipe.modal.form.onSubmit.error.generic")}`
        })
      }
    }
  }

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("recipes.components.modals.ImportRecipe.modal.title")}</DialogTitle>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="url"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex justify-between items-center">
                      <FormLabel>{t("recipes.components.modals.ImportRecipe.modal.form.fields.url.label")}</FormLabel>

                      {Capacitor.isNativePlatform() && (
                        <button
                          type="button"
                          className="flex items-center gap-1 link text-sm"
                          onClick={() => setShowImportRecipeGuideModal(true)}
                        >
                          <Info size={16} />
                          {t("recipes.components.modals.ImportRecipe.modal.form.fields.url.saveRecipesGuide")}
                        </button>
                      )}
                    </div>

                    <FormControl>
                      <Input placeholder={t("recipes.components.modals.ImportRecipe.modal.form.fields.url.placeholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full"
                  disabled={form.formState.isSubmitting}
                >
                  {form.formState.isSubmitting ? (
                    <Loader2 size={24} className="animate-spin" />
                  ) : t("recipes.components.modals.ImportRecipe.modal.form.actions.save")}
                </Button>
              </DialogFooter>

              <FormRootErrorMessage />
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      {Capacitor.isNativePlatform() && (
        <ImportRecipeGuide isOpen={showImportRecipeGuideModal} setIsOpen={setShowImportRecipeGuideModal} />
      )}
    </>
  )
}