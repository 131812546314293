const instagramHostnames = [
  "instagram.com", "www.instagram.com", "instagr.am", "ig.me"
]
const tiktokHostnames = [
  "tiktok.com", "www.tiktok.com", "vm.tiktok.com"
]
const youtubeHostnames = [
  "youtube.com", "www.youtube.com", "youtu.be"
]

// Returns favicon url from the correct social media url, or just the normal url provided if it's not a social media site
export function GetFavicon(url: string) {
  try {
    const parsedUrl = new URL(url)
    if(instagramHostnames.includes(parsedUrl.hostname)) {
      return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${instagramHostnames[0]}&size=64`
    } else if(tiktokHostnames.includes(parsedUrl.hostname)) {
      return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${tiktokHostnames[0]}&size=64`
    } else if(youtubeHostnames.includes(parsedUrl.hostname)) {
      return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${youtubeHostnames[0]}&size=64`
    }

    return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${parsedUrl.origin}&size=64`
  } catch(e) {
    console.error(e)

    return undefined
  }
}