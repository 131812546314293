import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";

import { GenerateRecipeFormInputs } from "@/routes/recipes/generate/types";

type Props = {
  form: UseFormReturn<GenerateRecipeFormInputs>
}
export default function Nutrition({ form }: Props) {
  const { t } = useTranslation()

  form.register("dietaryPreferences", {
    maxLength: {
      value: 1000,
      message: `${t("recipes.generate.components.steps.Nutrition.fields.dietaryPreferences.validation.maxLength", { maxLength: 1000 })}`
    }
  })

  form.register("allergies", {
    maxLength: {
      value: 1000,
      message: `${t("recipes.generate.components.steps.Nutrition.fields.allergies.validation.maxLength", { maxLength: 1000 })}`
    }
  })

  form.register("calories", { valueAsNumber: true })
  form.register("carbs", { valueAsNumber: true })
  form.register("proteins", { valueAsNumber: true })
  form.register("fats", { valueAsNumber: true })

  return (
    <>
      <FormField
        control={form.control}
        name="dietaryPreferences"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("recipes.generate.components.steps.Nutrition.fields.dietaryPreferences.label")}</FormLabel>
            <FormControl>
              <Textarea placeholder={t("recipes.generate.components.steps.Nutrition.fields.dietaryPreferences.placeholder")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="allergies"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("recipes.generate.components.steps.Nutrition.fields.allergies.label")}</FormLabel>
            <FormControl>
              <Textarea placeholder={t("recipes.generate.components.steps.Nutrition.fields.allergies.placeholder")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <label className="label block">
        <span className="text-lg font-medium">{t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.label")}</span>
      </label>

      <FormField
        control={form.control}
        name="calories"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.calories.label")}</FormLabel>
            <FormControl>
              <Input type="number" min={0} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="carbs"
        render={({ field }) => (
          <FormItem>
            <div className="flex justify-between items-start">
              <FormLabel>{t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.carbs.label")}</FormLabel>
              <FormDescription>
                {t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.unit")}
              </FormDescription>
            </div>
            <FormControl>
              <Input type="number" min={0} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="proteins"
        render={({ field }) => (
          <FormItem>
            <div className="flex justify-between items-start">
              <FormLabel>{t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.proteins.label")}</FormLabel>
              <FormDescription>
                {t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.unit")}
              </FormDescription>
            </div>
            <FormControl>
              <Input type="number" min={0} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="fats"
        render={({ field }) => (
          <FormItem>
            <div className="flex justify-between items-start">
              <FormLabel>{t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.fats.label")}</FormLabel>
              <FormDescription>
                {t("recipes.generate.components.steps.Nutrition.fields.nutritionTargets.unit")}
              </FormDescription>
            </div>
            <FormControl>
              <Input type="number" min={0} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}