import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { FIELD_LIMITS } from "@/globals.js";

import { ProviderId } from "firebase/auth"
import { analytics, auth } from "@/firebase/app"
import {
  AuthErrorCodes,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"
import { logEvent } from "firebase/analytics";

import Header from "@/components/navigation/Header"
import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import { Button } from "@/components/ui/button.tsx";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { Loader2 } from "lucide-react";

import { UserAccount } from "@/firebase/types"

export default function ChangePassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)
  const { toast } = useToast()

  // Redirect the user back to the account page if they logged in with a provider instead of email/password (no password to change)
  let shouldRedirect = true
  if(user && user.providerData) {
    user.providerData.forEach(provider => {
      if(provider.providerId === ProviderId.PASSWORD) {
        shouldRedirect = false
      }
    })

    if(shouldRedirect) {
      navigate("/account")
    }
  }

  const formSchema = z.object({
    currentPassword: UserAccount.shape.password
      .min(1, {
        message: t("account.changePassword.page.form.fields.currentPassword.validation.required")
      }),

    password: UserAccount.shape.password
      .min(FIELD_LIMITS.USER_ACCOUNT.PASSWORD.MIN_LENGTH, {
        message: t("account.changePassword.page.form.fields.password.validation.minLength")
      })
      .max(FIELD_LIMITS.USER_ACCOUNT.PASSWORD.MAX_LENGTH, {
        message: t("account.changePassword.page.form.fields.password.validation.maxLength")
      })
      .refine((val) => /\d/.test(val) && /[A-Z]/.test(val), {
        message: t("account.changePassword.page.form.fields.password.validation.passwordRules")
      }),

    confirmPassword: UserAccount.shape.password
      .min(1, {
        message: t("account.changePassword.page.form.fields.confirmPassword.validation.required")
      })
  }).refine((data) => data.password === data.confirmPassword, {
    message: t("account.changePassword.page.form.fields.confirmPassword.validation.match"),
    path: ["confirmPassword"]
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currentPassword: "",
      password: "",
      confirmPassword: ""
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { password, currentPassword } = values

    if(user && user.email) {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      )

      return reauthenticateWithCredential(user, credential).then(() => {
        return updatePassword(user, password).then(() => {
          logEvent(analytics, 'change_password', { status: 'success' })

          toast({
            title: t("account.changePassword.page.form.onSubmit.success.title"),
            description: t("account.changePassword.page.form.onSubmit.success.description"),
            variant: "success"
          })

          navigate('/account')
        }).catch((error) => {
          console.error(error)

          if(error.code === AuthErrorCodes.WEAK_PASSWORD) {
            form.setError("password", {
              type: error.code,
              message: `${t("account.changePassword.page.form.onSubmit.error.weakPassword")}`
            })
          } else {
            form.setError("root", {
              type: error.code,
              message: `${t("account.changePassword.page.form.onSubmit.error.generic")}`
            })
          }
        })
      }).catch((error) => {
        console.error(error)

        if(error.code === AuthErrorCodes.INVALID_PASSWORD) {
          form.setError("currentPassword", {
            type: error.code,
            message: `${t("account.changePassword.page.form.onSubmit.error.currentPasswordMismatch")}`
          })
        } else {
          form.setError("root", {
            type: error.code,
            message: `${t("account.changePassword.page.form.onSubmit.error.generic")}`
          })
        }
      })
    }
  }

  if(loading || shouldRedirect) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("account.changePassword.page.head.title")}</title>
      </Helmet>

      <Header title={t("account.changePassword.components.Header.title")} goBackURL="/account" />

      <div className="w-full max-w-4xl mx-auto px-4 pb-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="currentPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("account.changePassword.page.form.fields.currentPassword.label")}</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder={t("account.changePassword.page.form.fields.currentPassword.placeholder")} {...field} />
                  </FormControl>
                  <FormDescription>
                    {t("account.changePassword.page.form.fields.currentPassword.description")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("account.changePassword.page.form.fields.password.label")}</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder={t("account.changePassword.page.form.fields.password.placeholder")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("account.changePassword.page.form.fields.confirmPassword.label")}</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder={t("account.changePassword.page.form.fields.confirmPassword.placeholder")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="max-lg:w-full"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? (
                <Loader2 size={24} className="animate-spin" />
              ) : t("account.changePassword.page.form.actions.submit")}
            </Button>

            <FormRootErrorMessage />
          </form>
        </Form>
      </div>
    </Page>
  )
}