import { useTranslation } from "react-i18next";

import { Clock } from "lucide-react";

import { Recipe } from "@/firebase/types"

type Props = {
  prepTime?: Recipe["prepTime"]
  cookTime?: Recipe["cookTime"]
  totalTime?: Recipe["totalTime"]
}
export default function Stats({ prepTime, cookTime, totalTime }: Props) {
  const { t } = useTranslation()

  return (
    <>
      {/* Only show if we have literally any number that's not 0 */}
      {((prepTime && (prepTime?.hours > 0 || prepTime?.minutes > 0)) || (cookTime && (cookTime?.hours > 0 || cookTime?.minutes > 0)) || (totalTime && (totalTime?.hours > 0 || totalTime?.minutes > 0))) && (
        <div className="flex items-center gap-4">
          <Clock size={20} className="-mr-2" />

          {(prepTime && (prepTime?.hours > 0 || prepTime?.minutes > 0)) && (
            <p className="flex flex-wrap">
              {t("recipes.[id].components.recipe.Stats.prepTime")}
              &nbsp;
              <span className="font-semibold">
                {prepTime?.hours > 0 ? `${prepTime?.hours}h ` : ''}
                {prepTime?.minutes > 0 ? `${prepTime?.minutes}m` : ''}
              </span>
            </p>
          )}

          {(cookTime && (cookTime?.hours > 0 || cookTime?.minutes > 0)) && (
            <p className="flex flex-wrap">
              {t("recipes.[id].components.recipe.Stats.cookTime")}
              &nbsp;
              <span className="font-semibold">
                {cookTime?.hours > 0 ? `${cookTime?.hours}h ` : ''}
                {cookTime?.minutes > 0 ? `${cookTime?.minutes}m` : ''}
              </span>
            </p>
          )}

          {(totalTime && (totalTime?.hours > 0 || totalTime?.minutes > 0)) && (
            <p className="flex flex-wrap">
              {t("recipes.[id].components.recipe.Stats.totalTime")}
              &nbsp;
              <span className="font-semibold">
                {totalTime?.hours > 0 ? `${totalTime?.hours}h ` : ''}
                {totalTime?.minutes > 0 ? `${totalTime?.minutes}m` : ''}
              </span>
            </p>
          )}
        </div>
      )}
    </>
  )
}