import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { db } from "@/firebase/app"
import { doc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import CollectionHeader from "@/routes/collections/[id]/components/general/CollectionHeader.tsx";
import NoRecipes from "@/routes/collections/[id]/components/general/NoRecipes.tsx";
import AddRecipe from "@/routes/collections/[id]/components/AddRecipe.tsx";
import ListRecipes from "@/routes/collections/[id]/components/ListRecipes.tsx";

import { Collection } from "@/firebase/types"

export default function CollectionPage() {
  const { t } = useTranslation()
  const params = useParams()
  const isMobile = useIsMobile()

  const collectionID = params.id as string
  const [collectionData, loading, error, snapshot] = useDocumentData(
    doc(db, "collections", collectionID)
  )
  const collection = collectionData as Collection | undefined

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{collection?.title}</title>
      </Helmet>

      <CollectionHeader collection={collection} />

      <div className="flex flex-col flex-grow w-full max-w-4xl mx-auto mb-20 px-4">
        <div className="flex justify-between gap-4 mb-4">
          <div>
            <h2 className="text-xl font-semibold">{collection?.title}</h2>

            <p className="text-sm text-muted-foreground">
              {t("collections.[id].page.numRecipes", { count: collection?.recipeIds.length })}
            </p>
          </div>

          {collection && !isMobile && (
            <AddRecipe collection={collection} />
          )}
        </div>

        {collection && collection.recipeIds.length > 0 ? (
          <ListRecipes collection={collection} />
        ) : (
          <NoRecipes />
        )}
      </div>

      {collection && isMobile && (
        <AddRecipe collection={collection} />
      )}
    </Page>
  )
}
