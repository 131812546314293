import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { auth } from "@/firebase/app"
import {
  AuthErrorCodes,
  reauthenticateWithCredential,
  EmailAuthProvider,
  ProviderId,
  deleteUser,
} from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"

import { loginWithApple, loginWithGoogle } from "@/lib/auth/oauth.tsx";

import Header from "@/components/navigation/Header"
import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import { Button } from "@/components/ui/button.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { Loader2 } from "lucide-react";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";

import { UserAccount } from "@/firebase/types"

const defaultProvidersState = {
  password: false,
  google: false,
  apple: false
}

export default function DeleteAccount() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  // Tracks which auth methods the user has signed in with, so we know which one's the user can reauthenticate with
  const [availableReauthenticationMethods, setAvailableReauthenticationMethods] = useState({ ...defaultProvidersState })

  useEffect(() => {
    if(user) {
      const userProviders = { ...defaultProvidersState }

      user.providerData.forEach((providerData) => {
        if(providerData.providerId === ProviderId.PASSWORD) {
          userProviders.password = true
        } else if(providerData.providerId === ProviderId.GOOGLE) {
          userProviders.google = true
        } else if(providerData.providerId === 'apple.com') {
          // For some reason Apple is missing from ProviderId
          userProviders.apple = true
        }
      })

      setAvailableReauthenticationMethods(userProviders)
    }
  }, [user])

  const formSchema = z.object({
    password: UserAccount.shape.password
      .refine((val) => {
        if(availableReauthenticationMethods.password && (!availableReauthenticationMethods.google && !availableReauthenticationMethods.apple)) {
          return val.length > 1
        } else {
          return true
        }
      }, {
        message: t("account.deleteAccount.page.form.fields.password.validation.required")
      }),
    confirmDeletion: z.boolean()
      .refine((val) => val, {
        message: t("account.deleteAccount.page.form.fields.confirmDeletion.validation.required")
      })
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmDeletion: false
    }
  })

  async function handleGoogleLogin() {
    try {
      await loginWithGoogle()
    } catch(error: any) {
      console.error(error)

      form.setError("root", {
        type: error.code,
        message: t("account.deleteAccount.page.form.socialLogins.google.button.onClick.error")
      })
    }
  }

  async function handleAppleLogin() {
    try {
      await loginWithApple()
    } catch(error: any) {
      console.error(error)

      form.setError("root", {
        type: error.code,
        message: t("account.deleteAccount.page.form.socialLogins.apple.button.onClick.error")
      })
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { password, confirmDeletion } = values

    if(user) {
      try {
        if(availableReauthenticationMethods.password && user.email && password) {
          const credential = EmailAuthProvider.credential(
            user.email,
            password
          )

          await reauthenticateWithCredential(user, credential)
        }

        // User should be reauthenticated by this point
        await deleteUser(user)

        toast({
          title: t("account.deleteAccount.page.form.onSubmit.success.title"),
          description: t("account.deleteAccount.page.form.onSubmit.success.description"),
          variant: "success"
        })

        navigate('/signup')
      } catch(error: any) {
        console.error(error)

        if(error.code === AuthErrorCodes.INVALID_PASSWORD) {
          form.setError("password", {
            type: error.code,
            message: `${t("account.deleteAccount.page.form.onSubmit.error.passwordMismatch")}`
          })
        } else if(error.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
          form.setError("root", {
            type: error.code,
            message: `${t("account.deleteAccount.page.form.onSubmit.error.reauthenticationRequired")}`
          })
        } else {
          form.setError("root", {
            type: error.code,
            message: `${t("account.deleteAccount.page.form.onSubmit.error.generic")}`
          })
        }
      }
    }
  }

  if(loadingUser) {
    return <Loading />
  }

  if(errorUser) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("account.deleteAccount.page.head.title")}</title>
      </Helmet>

      <Header title={t("account.deleteAccount.components.Header.title")} goBackURL="/account" />

      <div className="w-full max-w-4xl mx-auto px-4 pb-4">
        <h2 className="text-2xl font-semibold mb-4">{t('account.deleteAccount.page.title')}</h2>
        <div className="flex flex-col gap-2">
          <p>
            {t('account.deleteAccount.page.text.1')}
          </p>
          <p>
            <Trans i18nKey="account.deleteAccount.page.text.2">
              <strong>If you have an active subscription with Flavorish through Apple, you must cancel the subscription yourself through your Apple account.</strong> Flavorish cannot cancel your Apple
              subscription for you.
            </Trans>
          </p>
          <p>
            {t('account.deleteAccount.page.text.3')}
          </p>
        </div>

        <div className="py-4">
          <Separator />
        </div>

        <h3 className="text-lg font-semibold mb-4">{t('account.deleteAccount.page.form.title')}</h3>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            {(availableReauthenticationMethods.google || availableReauthenticationMethods.apple) && (
              <>
                {availableReauthenticationMethods.google && (
                  <Button
                    type="button"
                    variant="outline"
                    onClick={handleGoogleLogin}
                    className="flex items-center gap-2 w-full border-foreground"
                  >
                    <FcGoogle size={24} className="flex-shrink-0" />
                    {t("account.deleteAccount.page.form.socialLogins.google.button.text")}
                  </Button>
                )}

                {availableReauthenticationMethods.apple && (
                  <Button
                    type="button"
                    variant="outline"
                    onClick={handleAppleLogin}
                    className="flex items-center gap-2 w-full border-foreground"
                  >
                    <FaApple size={24} className="flex-shrink-0" />
                    {t("account.deleteAccount.page.form.socialLogins.apple.button.text")}
                  </Button>
                )}

                {/* From: https://github.com/shadcn-ui/ui/blob/main/apps/www/app/(app)/examples/cards/components/create-account.tsx#L36 */}
                {availableReauthenticationMethods.password && (
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                    <div className="relative flex justify-center uppercase">
                      <span className="bg-background px-2">
                        OR
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}

            {availableReauthenticationMethods.password && (
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("account.deleteAccount.page.form.fields.password.label")}</FormLabel>
                    <FormControl>
                      <Input type="password" placeholder={t("account.deleteAccount.page.form.fields.password.placeholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="confirmDeletion"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        className="w-5 h-5 mt-1"
                      />
                    </FormControl>
                    <FormLabel className="text-base">
                      {t("account.deleteAccount.page.form.fields.confirmDeletion.label")}
                    </FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              variant="destructive"
              className="max-lg:w-full !mt-8"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? (
                <Loader2 size={24} className="animate-spin" />
              ) : t("account.deleteAccount.page.form.actions.submit")}
            </Button>

            <FormRootErrorMessage />
          </form>
        </Form>
      </div>
    </Page>
  )
}