import { useState } from "react"

import Header from "@/components/navigation/Header"

import CollectionOptions from "@/routes/collections/[id]/components/general/CollectionOptions.tsx";
import RenameCollectionModal from "@/routes/collections/[id]/components/modals/RenameCollectionModal.tsx";
import DeleteCollectionModal from "@/routes/collections/[id]/components/modals/DeleteCollectionModal.tsx";

import { Collection } from "@/firebase/types"

type Props = {
  collection?: Collection
}

export default function CollectionHeader({ collection }: Props) {
  const [showRenameCollectionModal, setShowRenameCollectionModal] = useState(false)
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false)

  return (
    <>
      <Header
        goBackURL='/collections'
        options={
          <>
            {collection && (
              <CollectionOptions
                collection={collection}
                setShowRenameCollectionModal={setShowRenameCollectionModal}
                setShowDeleteCollectionModal={setShowDeleteCollectionModal}
              />
            )}
          </>
        }
      />

      {collection && <RenameCollectionModal isOpen={showRenameCollectionModal} setIsOpen={setShowRenameCollectionModal} collection={collection} />}
      {collection && <DeleteCollectionModal isOpen={showDeleteCollectionModal} setIsOpen={setShowDeleteCollectionModal} collection={collection} />}
    </>
  )
}