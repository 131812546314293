import { useEffect } from "react";

import Typesense from "typesense"
import { SearchResponse } from "typesense/lib/Typesense/Documents";
import { SearchResponseHit } from "typesense/src/Typesense/Documents.ts";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent } from "@/components/ui/card"

import { Ingredient, List } from "@/firebase/types.ts";
import { CircleCheckBig, CirclePlus } from "lucide-react";

let client = new Typesense.Client({
  "nodes": [{
    host: import.meta.env.VITE_TYPESENSE_HOST,
    port: import.meta.env.VITE_TYPESENSE_PORT,
    protocol: import.meta.env.VITE_TYPESENSE_PROTOCOL
  }],
  "apiKey": import.meta.env.VITE_TYPESENSE_SEARCH_INGREDIENTS_API_KEY,
  "connectionTimeoutSeconds": 2
})

type Props = {
  hits: SearchResponseHit<Ingredient>[] | undefined
  setHits: (hits: SearchResponseHit<Ingredient>[] | undefined) => void
  query: string
  addItemToList: (newItem: Partial<List["items"][0]>) => Promise<void>
  listItems: List["items"]
}
export default function AddItemAutocomplete({ hits, setHits, query, addItemToList, listItems }: Props) {
  const unCheckedItems = listItems.filter(item => !item.checked)

  useEffect(() => {
    searchIngredients(query)
  }, [query])

  function searchIngredients(query: string) {
    const searchParameters = {
      "q": query,
      "query_by": "name",
      "per_page": 10,
      "prioritize_token_position": true
    }
    // Specifies items to show at the top of the query. Milk, eggs, etc
    if(query === "") {
      searchParameters["pinned_hits"] = "1704:1,1539:2,1436:3,3181:4,2661:5,2541:6,2767:7,3391:8,2613:9,1885:10"
    }

    client.collections("ingredients")
      .documents()
      .search(searchParameters)
      // @ts-ignore
      .then(function(searchResults: SearchResponse<Ingredient>) {
        setHits(searchResults.hits)
      })
  }

  return (
    <>
      {hits && hits.length > 0 && (
        <Card>
          <CardContent className="p-2">
            <ul className="flex flex-col gap-1">
              {hits.map((hit) => (
                <li key={hit.document.id}>
                  <Button
                    type="button"
                    variant="ghost"
                    className="w-full justify-between gap-2"
                    onClick={async () => {
                      await addItemToList({
                        name: hit.document.name,
                        category_id: hit.document.category_id,
                      })
                    }}
                  >
                    {hit.highlight.name?.snippet ? (
                      <span dangerouslySetInnerHTML={{ __html: `${hit.highlight.name.snippet}` }} className="truncate"></span>
                    ) : (
                      <span className="truncate">{hit.document.name}</span>
                    )}

                    {unCheckedItems.some(item => item.name === hit.document.name) ? (
                      <CircleCheckBig size={24} className="flex-shrink-0 text-success" />
                    ) : (
                      <CirclePlus size={24} className="flex-shrink-0" />
                    )}
                  </Button>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
    </>
  )
}