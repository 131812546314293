import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"

import Page from "@/components/page/Page.tsx";
import { Button } from "@/components/ui/button.tsx";

import PageNotFound4Image from "@/assets/images/Page Not Found 4.svg"

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <Page className="justify-center items-center p-4 mb-20">
      <Helmet>
        <title>{t("notFound.head.title")}</title>
      </Helmet>

      <img src={PageNotFound4Image} alt={t("notFound.image.alt")} className="max-w-xs" />

      <div className="text-center mt-4">
        <h1 className="text-4xl font-semibold mb-2">
          {t("notFound.title")}
        </h1>
        <p>{t("notFound.text")}</p>

        <Button asChild className="mt-4">
          <NavLink to="/">
            {t("notFound.button")}
          </NavLink>
        </Button>
      </div>
    </Page>
  )
}
