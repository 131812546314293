import { Navigate } from 'react-router-dom'

import { auth } from "@/firebase/app.ts"
import { useAuthState } from "react-firebase-hooks/auth"

import Loading from "../page/Loading.tsx"

type Props = {
  children: JSX.Element
}
export default function ProtectedRoute({ children }: Props) {
  const [user, loading, error] = useAuthState(auth)

  if(loading) {
    return <Loading />
  }

  if (!user) return <Navigate to={`/signup${window.location.pathname !== '/' ? `?redirectTo=${window.location.pathname}` : ''}`} />

  return children
}
