import { useTranslation } from "react-i18next"

import Item from "./Item"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";

import { IngredientCategories } from "@/constants"

import { List } from "@/firebase/types"

type Props = {
  list: List
  handleItemCheck: (itemID: List["items"][0]["id"]) => void
  handleItemClick: (item: List["items"][0]) => void
}
export default function AisleSortItems({ list, handleItemCheck, handleItemClick }: Props) {
  const { t } = useTranslation()

  // Create object with keys as category ids
  const aisles: {
    [IngredientCategories: string]: {
      name: string,
      items: List["items"]
    }
  } = {}
  for(let k in IngredientCategories) {
    // @ts-ignore
    aisles[IngredientCategories[k]] = {
      // Set name of category id to translated name
      // @ts-ignore
      name: t(`ingredientCategories.${IngredientCategories[k]}`),
      items: []
    }
  }

  const items = [...list.items]
  // Sort items alphabetically
  items.sort((a, b) => a.name.localeCompare(b.name))

  // Add items to aisles by category
  items.map(item => {
    if(!item.checked) {
      if(item.category_id) {
        aisles[item.category_id].items.push(item)
      } else {
        aisles[IngredientCategories.Other].items.push(item)
      }
    }
  })

  return (
    <>
      {Object.keys(aisles).map((aisleID) => {
        if(aisles[aisleID].items.length > 0) {
          return (
            <Card key={aisleID} className="mb-2">
              <CardHeader className="pt-4 pb-2">
                <CardTitle className="text-base text-muted-foreground">{aisles[aisleID].name}</CardTitle>
              </CardHeader>
              <CardContent className="px-2 pb-4">
                <ul className="flex flex-col gap-2">
                  {aisles[aisleID].items.map((item) => (
                    <li key={item.id}>
                      <Item item={item} handleItemCheck={handleItemCheck} handleItemClick={handleItemClick} />
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          )
        }
      })}
    </>
  )
}