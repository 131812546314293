import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { auth, db, analytics } from "@/firebase/app"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore"
import { logEvent } from "firebase/analytics";

import IngredientText from "@/components/ingredients/IngredientText.tsx"

import { Button } from "@/components/ui/button.tsx";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"

import { Check, ChevronsUpDown, Loader2 } from "lucide-react";

import { Recipe, List } from "@/firebase/types"

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  recipe: Recipe
  scaleRatio?: number
}

export default function ExportIngredientsToListModal({ isOpen = false, setIsOpen, recipe, scaleRatio = 1 }: Props) {
  const { t } = useTranslation()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [lists, setLists] = useState<List[] | null>(null)
  const [fetchingLists, setFetchingLists] = useState(false)

  const [popoverOver, setPopoverOpen] = useState(false)

  const formSchema = z.object({
    list: List.shape.id
      .min(1, {
        message: t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.fields.list.validation.required")
      }),
    ingredients: List.shape.items
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      list: "",
      ingredients: recipe.ingredients.map((ingredient) => {
        return {
          ...ingredient,
          checked: true,
          recipe: {
            id: recipe.id,
            title: recipe.title
          },
          notes: ""
        }
      })
    }
  })

  const {
    fields: ingredientsFields
  } = useFieldArray({
    control: form.control,
    name: "ingredients"
  })

  // Fetch lists after the modal opens, doesn't use firebase reads if this feature isn't used.
  useEffect(() => {
    if(isOpen) {
      (async function() {
        setFetchingLists(true)

        const querySnapshot = await getDocs(query(
          collection(db, "lists"),
          where("userId", "==", user?.uid),
          orderBy("_created", "desc")
        ))

        const fetchedLists: List[] = []
        querySnapshot.forEach(doc => {
          fetchedLists.push(doc.data() as List)
        })

        setLists(fetchedLists)

        setFetchingLists(false)

        form.reset()
        // Set the default value for list field to the first one
        form.setValue('list', fetchedLists[0].id)
      })()
    }
  }, [isOpen])

  // TODO: Get the category ID for ingredients
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { list: listID, ingredients } = values

    try {
      const list = lists!.find((list) => list.id === listID)

      // Pick checked ingredients only and set checked to false for the list
      const checkedIngredients: List["items"] = ingredients.filter((ingredient) => {
        if(ingredient.checked) {
          const newIngredient = ingredient
          newIngredient.checked = false

          // Set ingredient quantities to the scaled version, not the saved version
          const ingredientQuantityScaled = (ingredient.quantity || 0) * scaleRatio
          const ingredientQuantity2Scaled = (ingredient.quantity2 || 0) * scaleRatio
          newIngredient.quantity = ingredientQuantityScaled
          newIngredient.quantity2 = ingredientQuantity2Scaled

          let shouldReturn = true

          // TODO: It should do this when the ingredient is the same, not just when it's the same id
          // If ingredient has the same ID, don't add another one, just increase the quantity
          if(list && list.items && list.items.length > 0) {
            list.items.map((listIngredient) => {
              if(listIngredient.id === ingredient.id) {

                listIngredient.quantity = (listIngredient.quantity || 0) + ingredientQuantityScaled
                listIngredient.quantity === 0 ? listIngredient.quantity = null : null // Set to null if the answer is 0
                listIngredient.quantity2 = (listIngredient.quantity2 || 0) + ingredientQuantity2Scaled
                listIngredient.quantity2 === 0 ? listIngredient.quantity2 = null : null  // Set to null if the answer is 0

                shouldReturn = false
              }
            })
          }

          if(shouldReturn) {
            return newIngredient
          }
        }
      })

      await updateDoc(doc(db, "lists", listID), {
        _updated: serverTimestamp(),
        items: [...list!.items, ...checkedIngredients]
      })


      logEvent(analytics, 'export_recipe_ingredients_to_list')

      setIsOpen(false)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>{t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.title")}</DialogTitle>
        </DialogHeader>

        {lists && !fetchingLists ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="list"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>{t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.fields.list.label")}</FormLabel>
                    <Popover open={popoverOver} onOpenChange={setPopoverOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className="justify-between font-medium"
                          >
                            {field.value ? lists.find((list) => list.id === field.value)?.title : t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.fields.list.placeholder")}

                            <ChevronsUpDown size={16} className="ml-2 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="PopoverContent p-0">
                        <Command
                          filter={(value, search) => {
                            const filteredLists = lists.filter((list) => list.title.toLowerCase().includes(search.toLowerCase()))
                            for(const list of filteredLists) {
                              if(list.id === value) {
                                return 1
                              }
                            }

                            return 0
                          }}
                        >
                          <CommandInput placeholder={t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.fields.list.popover.placeholder")} />
                          <CommandEmpty>{t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.fields.list.popover.empty")}</CommandEmpty>
                          <CommandGroup>
                            <CommandList>
                              {lists.map((list) => (
                                <CommandItem
                                  key={list.id}
                                  value={list.id}
                                  onSelect={() => {
                                    form.setValue("list", list.id)
                                    setPopoverOpen(false)
                                  }}
                                >
                                  <Check size={20} className={`mr-2 ${list.id === field.value ? 'opacity-100' : 'opacity-0'}`} />

                                  {list.title}
                                </CommandItem>
                              ))}
                            </CommandList>
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="space-y-2">
                <FormLabel>{t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.fields.ingredients.label")}</FormLabel>

                {/* The example from this post was helpful in making this: https://stackoverflow.com/questions/78426565/how-to-work-with-shadcn-ui-error-messages-for-an-array-of-formfields */}
                <ul className="flex flex-col gap-4">
                  {ingredientsFields.map((ingredient, index) => (
                    <li key={ingredient.id} className="w-full">
                      <FormField
                        control={form.control}
                        name={`ingredients.${index}`}
                        render={({ field }) => (
                          <FormItem>
                            <div className="flex flex-row items-start gap-2">
                              <FormControl>
                                <Checkbox
                                  checked={field.value.checked}
                                  onCheckedChange={(value) => {
                                    field.onChange({
                                      ...field.value,
                                      checked: value
                                    })
                                  }}
                                  className="w-5 h-5"
                                />
                              </FormControl>
                              <FormLabel className="text-base">
                                <IngredientText ingredient={field.value} scaleRatio={scaleRatio} />
                              </FormLabel>
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </li>
                  ))}
                </ul>
              </div>

              <DialogFooter className="max-sm:gap-2">
                <Button
                  type="button"
                  variant="accent"
                  className="w-full"
                  onClick={() => setIsOpen(false)}
                >
                  {t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.actions.cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full"
                  disabled={form.formState.isSubmitting}
                >
                  {form.formState.isSubmitting ? (
                    <Loader2 size={24} className="animate-spin" />
                  ) : t("recipes.[id].components.modals.ExportIngredientsToListModal.modal.form.actions.submit")}
                </Button>
              </DialogFooter>

              <FormRootErrorMessage />
            </form>
          </Form>
        ) : (
          <div className="flex justify-center items-center my-8">
            <Loader2 size={48} className="animate-spin" />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}