import { useEffect } from "react"

import { Capacitor } from "@capacitor/core";
import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor'

import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "@/firebase/app.ts"

// Initializes RevenueCat SDK
export default function RevenueCatHandler() {
  const [user, loadingUser, errorUser] = useAuthState(auth)

  useEffect(() => {
    if(user && Capacitor.isNativePlatform()) {
      (async function() {
        if(import.meta.env.VITE_ENV !== "production") {
          await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG })
        }

        if(Capacitor.getPlatform() === 'ios') {
          await Purchases.configure({
            apiKey: import.meta.env.VITE_REVENUE_CAT_APPLE_PUBLIC_API_KEY,
            appUserID: user.uid
          })
        } else if(Capacitor.getPlatform() === 'android') {
          await Purchases.configure({
            apiKey: import.meta.env.VITE_REVENUE_CAT_ANDROID_PUBLIC_API_KEY,
            appUserID: user.uid
          })
        }
      })()
    }
  }, [user]);

  return null
}