import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { analytics, auth, db } from "@/firebase/app"
import { useAuthState } from "react-firebase-hooks/auth";
import { doc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { logEvent } from "firebase/analytics";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"
import NotFound from "@/routes/NotFound.tsx";

import RecipeHeader from "./components/general/RecipeHeader.tsx"
import RecipeLayout from "@/components/recipes/RecipeLayout.tsx";
import SaveRecipeToast from "@/routes/recipes/[id]/components/general/SaveRecipeToast.tsx";

import { Recipe } from "@/firebase/types"

export default function RecipePage() {
  const params = useParams()

  const recipeID = params.id as string
  const [recipeData, loading, error, snapshot] = useDocumentData(
    doc(db, "recipes", recipeID)
  )
  const recipe = recipeData as Recipe | undefined

  const [user, loadingUser, errorUser] = useAuthState(auth)

  let userOwnsRecipe: boolean | null = null
  if(recipe && user) {
    if(recipe.userId === user.uid) {
      userOwnsRecipe = true
    } else {
      userOwnsRecipe = false
    }
  }

  if(userOwnsRecipe === false) {
    logEvent(analytics, 'view_shared_recipe')
  }

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  if(!loading && !recipe && !error) {
    return <NotFound />
  }

  return (
    <Page className="relative">
      <Helmet>
        <title>{recipe?.title}</title>
      </Helmet>

      <RecipeHeader recipe={recipe} />

      {recipe && (
        <>
          <RecipeLayout recipe={recipe} />

          {user && userOwnsRecipe === false && (
            <SaveRecipeToast recipe={recipe} userId={user.uid} />
          )}
        </>
      )}
    </Page>
  )
}