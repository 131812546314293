import * as React from "react";
import { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Capacitor } from "@capacitor/core";
import { Share } from '@capacitor/share';

import { analytics, auth } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { logEvent } from "firebase/analytics";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import SaveToCollectionsModal from "@/routes/recipes/[id]/components/modals/SaveToCollectionsModal.tsx";
import DeleteRecipeModal from "../modals/DeleteRecipeModal.tsx"

import { Button, ButtonProps } from "@/components/ui/button.tsx";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { ArrowLeft, Bookmark, Copy, Ellipsis, Pencil, Share2, Trash2 } from "lucide-react";

import { Recipe } from "@/firebase/types"

type Props = {
  recipe?: Recipe
}
export default function RecipeHeader({ recipe }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { toast } = useToast()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

  const [showSaveToCollectionsModal, setShowSaveToCollectionsModal] = useState(false)
  const [showDeleteRecipeModal, setShowDeleteRecipeModal] = useState(false)

  const TriggerButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ ...props }, ref) => (
    <Button
      type="button"
      variant="background"
      size="icon"
      ref={ref}
      {...props}
    >
      <Ellipsis size={20} />
    </Button>
  ))

  const CopyRecipeToTextButton = () => (
    <Button
      type="button"
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={() => {
        if(recipe) {
          copyRecipe(recipe)
        }

        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <Copy size={isMobile ? 20 : 16} />
      {t("recipes.[id].components.general.RecipeHeader.optionsDropdown.options.copyRecipeToText")}
    </Button>
  )

  const EditRecipeButton = () => (
    <Button
      type="button"
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      asChild
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
    >
      <Link to={`/recipes/${recipe?.id}/edit`}>
        <Pencil size={isMobile ? 20 : 16} />
        {t("recipes.[id].components.general.RecipeHeader.optionsDropdown.options.editRecipe")}
      </Link>
    </Button>
  )

  const DeleteRecipeButton = () => (
    <Button
      type="button"
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={e => {
        e.preventDefault()
        setShowDeleteRecipeModal(true)

        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <Trash2 size={isMobile ? 20 : 16} />
      {t("recipes.[id].components.general.RecipeHeader.optionsDropdown.options.deleteRecipe")}
    </Button>
  )

  return (
    <>
      <div className="relative z-40 flex justify-between items-center gap-4 max-lg:absolute top-0 left-0 right-0 w-full max-w-7xl mx-auto bg-transparent p-2 lg:p-4">
        <Button
          type="button"
          variant="background"
          size="icon"
          asChild
        >
          <Link to="/recipes">
            <ArrowLeft size={20} />
          </Link>
        </Button>

        {recipe && user && recipe.userId === user.uid && (
          <div className="flex gap-2">
            <Button
              type="button"
              variant="background"
              size="icon"
              onClick={async (e) => {
                e.preventDefault()

                logEvent(analytics, 'share_recipe')

                const shareUrl = `${import.meta.env.VITE_WEB_BASE_URL}/recipes/${recipe.id}`

                if(Capacitor.isNativePlatform()) {
                  await Share.share({
                    dialogTitle: t('recipes.[id].components.general.RecipeHeader.share.title'),
                    title: t('recipes.[id].components.general.RecipeHeader.share.title'),
                    text: t('recipes.[id].components.general.RecipeHeader.share.text'),
                    url: shareUrl,
                  })
                } else {
                  if(isMobile && navigator.share) {
                    navigator.share({
                      title: t('recipes.[id].components.general.RecipeHeader.share.title'),
                      text: t('recipes.[id].components.general.RecipeHeader.share.text'),
                      url: shareUrl
                    })
                  } else {
                    navigator.clipboard.writeText(shareUrl)
                    toast({
                      variant: "success",
                      title: t("recipes.[id].components.general.RecipeHeader.share.copyLinkToast.title"),
                      description: t("recipes.[id].components.general.RecipeHeader.share.copyLinkToast.description")
                    })
                  }
                }
              }}
            >
              <Share2 size={20} />
            </Button>

            <Button
              type="button"
              variant="background"
              size="icon"
              onClick={e => {
                e.preventDefault()
                setShowSaveToCollectionsModal(true)
              }}
            >
              <Bookmark size={20} />
            </Button>

            {isMobile ? (
              <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
                <DrawerTrigger asChild>
                  <TriggerButton />
                </DrawerTrigger>

                <DrawerContent>
                  <DrawerHeader>
                    <DrawerTitle>
                      {t("recipes.[id].components.general.RecipeHeader.optionsDropdown.title")}
                    </DrawerTitle>
                  </DrawerHeader>

                  <div className="flex flex-col gap-2 mx-auto w-full py-4">
                    <CopyRecipeToTextButton />
                    <EditRecipeButton />
                    <DeleteRecipeButton />
                  </div>
                </DrawerContent>
              </Drawer>
            ) : (
              <DropdownMenu open={dropdownMenuOpen} onOpenChange={setDropdownMenuOpen}>
                <DropdownMenuTrigger asChild>
                  <TriggerButton />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>
                    {t("recipes.[id].components.general.RecipeHeader.optionsDropdown.title")}
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />

                  <DropdownMenuGroup>
                    <DropdownMenuItem className="p-0">
                      <CopyRecipeToTextButton />
                    </DropdownMenuItem>

                    <DropdownMenuItem className="p-0">
                      <EditRecipeButton />
                    </DropdownMenuItem>

                    <DropdownMenuItem className="p-0">
                      <DeleteRecipeButton />
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        )}
      </div>

      {recipe && <SaveToCollectionsModal isOpen={showSaveToCollectionsModal} setIsOpen={setShowSaveToCollectionsModal} recipe={recipe} />}
      {recipe && <DeleteRecipeModal isOpen={showDeleteRecipeModal} setIsOpen={setShowDeleteRecipeModal} recipe={recipe} />}
    </>
  )
}

// Just don't want to crowd the component with this massive thing
function copyRecipe(recipe: Recipe) {
  if(recipe) {
    let recipeText = recipe.title + "\n\n"

    if(recipe.description) {
      recipeText += recipe.description + "\n\n"
    }
    if(recipe.prepTime.hours || recipe.prepTime.minutes || recipe.cookTime.hours || recipe.cookTime.minutes || recipe.totalTime.hours || recipe.totalTime.minutes) {
      if(recipe.prepTime.hours || recipe.prepTime.minutes) {
        recipeText += `Prep: ${recipe.prepTime.hours > 0 ? `${recipe.prepTime.hours}h` : ""}${recipe.prepTime.minutes > 0 ? `${recipe.prepTime.minutes}m` : ""} `
      }
      if(recipe.cookTime.hours || recipe.cookTime.minutes) {
        recipeText += `Cook: ${recipe.cookTime.hours > 0 ? `${recipe.cookTime.hours}h` : ""}${recipe.cookTime.minutes > 0 ? `${recipe.cookTime.minutes}m` : ""} `
      }
      if(recipe.totalTime.hours || recipe.totalTime.minutes) {
        recipeText += `Total: ${recipe.totalTime.hours > 0 ? `${recipe.totalTime.hours}h` : ""}${recipe.totalTime.minutes > 0 ? `${recipe.totalTime.minutes}m` : ""} `
      }
      recipeText += "\n"
    }
    if(recipe.servings) {
      recipeText += `Serves: ${recipe.servings}\n\n`
    }
    if(recipe.ingredients) {
      recipeText += "Ingredients:\n"
      recipeText += recipe.ingredients.map(ingredient => ingredient.name).join("\n") + "\n\n"
    }
    if(recipe.instructions) {
      recipeText += "Instructions:\n"
      recipeText += recipe.instructions.map((instruction, i) => `${i + 1}. ` + instruction.text).join("\n") + "\n\n"
    }
    if(recipe.nutrition && Object.keys(recipe.nutrition).length) {
      recipeText += "Nutrition:\n"
      recipeText += recipe.nutrition.servingSize ? `Serving Size: ${recipe.nutrition.servingSize} \n` : ""
      recipeText += recipe.nutrition.calories ? `Calories: ${recipe.nutrition.calories} \n` : ""
      recipeText += recipe.nutrition.carbohydrateContent ? `Carbohydrate Content: ${recipe.nutrition.carbohydrateContent} \n` : ""
      recipeText += recipe.nutrition.cholesterolContent ? `Cholesterol Content: ${recipe.nutrition.cholesterolContent} \n` : ""
      recipeText += recipe.nutrition.fatContent ? `Fat Content: ${recipe.nutrition.fatContent} \n` : ""
      recipeText += recipe.nutrition.fiberContent ? `Fiber Content: ${recipe.nutrition.fiberContent} \n` : ""
      recipeText += recipe.nutrition.proteinContent ? `Protein Content: ${recipe.nutrition.proteinContent} \n` : ""
      recipeText += recipe.nutrition.saturatedFatContent ? `Saturated Fat Content: ${recipe.nutrition.saturatedFatContent} \n` : ""
      recipeText += recipe.nutrition.sodiumContent ? `Sodium Content: ${recipe.nutrition.sodiumContent} \n` : ""
      recipeText += recipe.nutrition.sugarContent ? `Sugar Content: ${recipe.nutrition.sugarContent} \n` : ""
      recipeText += recipe.nutrition.transFatContent ? `Trans Fat Content: ${recipe.nutrition.transFatContent} \n` : ""
      recipeText += recipe.nutrition.unsaturatedFatContent ? `Unsaturated Fat Content: ${recipe.nutrition.unsaturatedFatContent} \n` : ""
      recipeText += "\n"
    }
    if(recipe.notes) {
      recipeText += "Notes:\n"
      recipeText += recipe.notes + "\n\n"
    }

    if(recipe.source && (recipe.source.name || recipe.source.url)) {
      recipeText += "Source:\n"
      if(recipe.source.name) {
        recipeText += recipe.source.name + "\n"
      }
      if(recipe.source.url) {
        recipeText += recipe.source.url + "\n"
      }
      recipeText += "\n"
    }

    if(recipe.aiGenerated) {
      recipeText += "This recipe was generated using AI.\n\n"
    }

    recipeText += "Shared with Flavorish.\n"

    navigator.clipboard.writeText(recipeText)
  }
}