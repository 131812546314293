import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { analytics, auth } from "@/firebase/app.ts"
import {
  AuthErrorCodes,
  sendPasswordResetEmail
} from "firebase/auth"
import { logEvent } from "firebase/analytics";

import Page from "@/components/page/Page.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { ArrowLeft, Loader2 } from "lucide-react";

import LogoImg from "@/assets/icon.svg"

import { UserAccount } from "@/firebase/types"

export default function ForgotPassword() {
  const { t } = useTranslation()
  const { toast } = useToast()

  const formSchema = z.object({
    email: UserAccount.shape.email
      .min(1, {
        message: t("(auth).forgotPassword.page.card.form.fields.email.validation.required")
      })
      .email({
        message: t("(auth).forgotPassword.page.card.form.fields.email.validation.pattern")
      })
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ""
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { email } = values

    return sendPasswordResetEmail(auth, email)
      .then(() => {
        logEvent(analytics, 'forgot_password', { status: 'success' })

        toast({
          title: t("(auth).forgotPassword.page.card.form.onSubmit.success.title"),
          description: t("(auth).forgotPassword.page.card.form.onSubmit.success.description"),
          variant: "success"
        })
      })
      .catch((error) => {
        console.error(error)

        // Auth error codes: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
        if(error.code === AuthErrorCodes.INVALID_EMAIL) {
          form.setError("email", {
            type: error.code,
            message: `${t("(auth).forgotPassword.page.card.form.onSubmit.error.invalidEmail")}`
          })
        } else if(error.code === AuthErrorCodes.USER_DELETED) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).forgotPassword.page.card.form.onSubmit.error.userNotFound")}`
          })
        } else {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).forgotPassword.page.card.form.onSubmit.error.generic")}`
          })
        }
      })
  }

  return (
    <Page className="justify-center py-4">
      <Helmet>
        <title>{t("(auth).forgotPassword.page.head.title")}</title>
      </Helmet>

      <div className="m-4">
        <div className="flex justify-center items-center my-4">
          <img src={LogoImg} alt="Flavorish" className="w-12 mr-4" />
          <p className="font-poppins text-4xl text-base-content dark:text-white">Flavorish</p>
        </div>

        <Card className="max-w-lg mx-auto">
          <CardHeader className="space-y-1">
            <CardTitle className="text-2xl">{t("(auth).forgotPassword.page.card.title")}</CardTitle>
            <CardDescription>{t("(auth).forgotPassword.page.card.subtitle")}</CardDescription>
          </CardHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col space-y-4">
              <CardContent className="flex flex-col gap-4 pb-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("(auth).forgotPassword.page.card.form.fields.email.label")}</FormLabel>
                      <FormControl>
                        <Input type="email" placeholder={t("(auth).forgotPassword.page.card.form.fields.email.placeholder")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>

              <CardFooter className="flex-col gap-4">
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full"
                  disabled={form.formState.isSubmitting}
                >
                  {form.formState.isSubmitting ? (
                    <Loader2 size={24} className="animate-spin" />
                  ) : t("(auth).forgotPassword.page.card.form.actions.submit")}
                </Button>

                <FormRootErrorMessage />
              </CardFooter>
            </form>
          </Form>
        </Card>

        <Link to="/login" className="link mt-4 flex justify-center items-center gap-2 w-full">
          <ArrowLeft size={20} />
          {t("(auth).forgotPassword.page.backToLogin")}
        </Link>
      </div>
    </Page>
  )
}