import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'

// https://capacitorjs.com/docs/guides/deep-links
export default function DeepLinksHandler() {
  const navigate = useNavigate()
  
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://app.flavorish.ai/recipes/1234
      // slug = /recipes/1234
      const slug = event.url.split('.ai').pop()
      if (slug) {
        navigate(slug)
      }
      // If no match, do nothing - let regular routing logic take over
    })
  }, [])

  return null
}