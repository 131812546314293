import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";

import { Button } from "@/components/ui/button.tsx";
import { Form, FormControl, FormField, FormItem, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { X, Loader2 } from "lucide-react";

type Props = {
  form: UseFormReturn<{ itemName: string; }, any, undefined>
  onSubmit: (values: { itemName: string; }) => Promise<void>
  lastItemAdded: string | null
}
export default function AddItemForm({ form, onSubmit, lastItemAdded }: Props) {
  const { t } = useTranslation()

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="itemName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative flex w-full items-center space-x-2">
                  <div className="relative w-full">
                    <Input placeholder={t("lists.[id].components.AddItem.modal.form.fields.itemName.placeholder")} {...field} className="pr-10" />

                    {field.value !== '' && (
                      <Button
                        type="button"
                        size="icon"
                        variant="ghost"
                        className="absolute right-0 top-0 bottom-0"
                        onClick={() => form.reset()}
                      >
                        <X size={20} className="text-muted-foreground" />
                      </Button>
                    )}
                  </div>

                  <Button
                    type="submit"
                    variant="primary"
                    disabled={form.formState.isSubmitting}
                  >
                    {form.formState.isSubmitting ? (
                      <Loader2 size={24} className="animate-spin" />
                    ) : t('lists.[id].components.AddItem.modal.form.actions.submit')}
                  </Button>
                </div>
              </FormControl>

              {lastItemAdded && Object.keys(form.formState.errors).length <= 0 && (
                <p className="text-sm font-medium text-success break-words">{t('lists.[id].components.AddItemForm.addedItemSuccess', { item: lastItemAdded })}</p>
              )}

              <FormMessage />
            </FormItem>
          )}
        />

        <FormRootErrorMessage />
      </form>
    </Form>
  )
}