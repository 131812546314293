import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

import { buttonVariants } from "@/components/ui/button.tsx";
import CircularProgress from "@/components/ui/custom/CircularProgress.tsx";

import ListOptions from "@/routes/lists/components/ListOptions.tsx";

import { List } from "@/firebase/types.ts";

type Props = {
  list: List
  setShowRenameListModal: Dispatch<SetStateAction<boolean>>
  setCurrentRenamingList: (list: List) => void
  setShowDeleteListModal: Dispatch<SetStateAction<boolean>>
  setCurrentDeletingList: (list: List) => void
}
export default function ListItem({ list, setShowRenameListModal, setCurrentRenamingList, setShowDeleteListModal, setCurrentDeletingList }: Props) {
  const unCheckedItems = list?.items.filter(item => !item.checked)
  const checkedItems = list?.items.filter(item => item.checked)

  const percentOfItemsComplete = (checkedItems?.length / list?.items.length) * 100 || 0

  return (
    <div
      className={`${buttonVariants({ variant: "outline", size: "lg" })} h-auto flex justify-between items-center !p-0 truncate`}
    >
      <Link to={`/lists/${list.id}`} className="w-full px-4 py-2 truncate">
        <div className="flex gap-2 text-left">
          <CircularProgress size={40} percentage={percentOfItemsComplete} className="flex-shrink-0" />
          <div className="truncate">
            <p className="text-base font-semibold truncate">{list.title}</p>
            <p className="text-xs">{checkedItems?.length}/{list?.items.length}</p>
          </div>
        </div>
      </Link>

      <ListOptions
        list={list}
        setShowRenameListModal={setShowRenameListModal}
        setCurrentRenamingList={setCurrentRenamingList}
        setShowDeleteListModal={setShowDeleteListModal}
        setCurrentDeletingList={setCurrentDeletingList}
        buttonClass="mx-4"
      />
    </div>
  )
}