import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { GetFavicon } from "@/lib/helpers/favicon.ts";

import Title from "@/routes/recipes/[id]/components/recipe/Title.tsx";
import Description from "@/routes/recipes/[id]/components/recipe/Description.tsx";
import Stats from "@/routes/recipes/[id]/components/recipe/Stats.tsx";
import Image from "@/routes/recipes/[id]/components/recipe/Image.tsx";
import Ingredients from "@/routes/recipes/[id]/components/recipe/Ingredients.tsx";
import Instructions from "@/routes/recipes/[id]/components/recipe/Instructions.tsx";
import Nutrition from "@/routes/recipes/[id]/components/recipe/Nutrition.tsx";
import Notes from "@/routes/recipes/[id]/components/recipe/Notes.tsx";

import { Badge } from "@/components/ui/badge.tsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

import { Image as LuImage } from "lucide-react";

import { Ingredient, Recipe } from "@/firebase/types.ts";

type Props = {
  recipe?: Recipe | Partial<Recipe>
  isLoading?: boolean
}
export default function RecipeLayout({ recipe, isLoading = false }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [checkedIngredients, setCheckedIngredients] = useState<Ingredient["id"][]>([])
  const [checkedInstructions, setCheckedInstructions] = useState<Recipe["instructions"][0]["id"][]>([])

  let sourceName = recipe?.source?.name

  if(recipe && recipe.source && !recipe.source.name && recipe.source.url) {
    const url = new URL(recipe.source.url)
    sourceName = url.host.replace("www.", "")
  }

  const hasNutritionInfo = !!(recipe?.nutrition && Object.keys(recipe?.nutrition).length > 0)

  return (
    <div className="w-full max-w-7xl mx-auto lg:px-4 mb-4">
      <div className="lg:flex lg:items-start lg:gap-4">
        <div className="lg:w-1/3 lg:min-w-[33.33333333%] lg:space-y-4">
          {recipe?.image || isLoading ? (
            <Image
              image={recipe?.image}
              alt={recipe?.title}
            />
          ) : (
            <div className="flex justify-center items-center h-96 min-h-96 lg:min-h-full bg-muted">
              <LuImage size={48} className="text-muted-foreground" />
            </div>
          )}

          {!isMobile && (
            <>
              {hasNutritionInfo && (
                <Nutrition nutrition={recipe.nutrition} />
              )}
            </>
          )}
        </div>

        <div className="w-full space-y-4 max-lg:p-4">
          {recipe?.source && recipe.source.url && (
            <Link to={recipe.source.url} target="_blank" className="block mb-2">
              <Badge variant="accent">
                <img
                  src={recipe.source.favicon || GetFavicon(recipe.source.url)}
                  alt={`${sourceName} favicon`}
                  className="w-4 h-4 mr-2"
                />
                {sourceName}
              </Badge>
            </Link>
          )}

          <div className="space-y-2 !mt-0">
            <Title
              title={recipe?.title}
              aiGenerated={recipe?.aiGenerated}
              imageImported={recipe?.imageImported}
              socialMediaImported={recipe?.socialMediaImported}
            />

            <Stats
              prepTime={recipe?.prepTime}
              cookTime={recipe?.cookTime}
              totalTime={recipe?.totalTime}
            />
          </div>

          {(recipe?.description || isLoading) && (
            <Description description={recipe?.description} />
          )}

          {!isMobile && (
            <>
              <Ingredients
                recipe={recipe}
                isLoading={isLoading}
                checkedIngredients={checkedIngredients}
                setCheckedIngredients={setCheckedIngredients}
              />

              <Instructions
                instructions={recipe?.instructions}
                checkedInstructions={checkedInstructions}
                setCheckedInstructions={setCheckedInstructions}
              />

              {recipe?.notes && (
                <Notes notes={recipe.notes} />
              )}
            </>
          )}
        </div>
      </div>

      {isMobile && (
        <div className="space-y-4 max-lg:px-4">
          <Tabs defaultValue="ingredients">
            <TabsList className={`w-full grid ${hasNutritionInfo ? 'grid-cols-3' : 'grid-cols-2'}`}>
              <TabsTrigger value="ingredients">{t('components.recipes.RecipeLayout.tabs.ingredients')}</TabsTrigger>

              <TabsTrigger value="instructions">{t('components.recipes.RecipeLayout.tabs.instructions')}</TabsTrigger>

              {hasNutritionInfo && (
                <TabsTrigger value="nutrition">{t('components.recipes.RecipeLayout.tabs.nutrition')}</TabsTrigger>
              )}
            </TabsList>

            <TabsContent value="ingredients">
              <Ingredients
                recipe={recipe}
                isLoading={isLoading}
                checkedIngredients={checkedIngredients}
                setCheckedIngredients={setCheckedIngredients}
              />
            </TabsContent>

            <TabsContent value="instructions">
              <Instructions
                instructions={recipe?.instructions}
                checkedInstructions={checkedInstructions}
                setCheckedInstructions={setCheckedInstructions}
              />
            </TabsContent>

            {hasNutritionInfo && (
              <TabsContent value="nutrition">
                <Nutrition nutrition={recipe.nutrition} />
              </TabsContent>
            )}
          </Tabs>

          {recipe?.notes && (
            <Notes notes={recipe.notes} />
          )}
        </div>
      )}

      {recipe?.aiGenerated || recipe?.imageImported || recipe?.socialMediaImported && (
        <p className="w-full text-sm text-muted-foreground text-center p-4 pb-0">
          {t("components.recipes.RecipeLayout.disclaimer")}
        </p>
      )}
    </div>
  )
}