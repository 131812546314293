import { Link, useNavigate } from "react-router-dom"

import { cn } from "@/lib/utils.ts";

import { Button } from "@/components/ui/button.tsx";

import { ArrowLeft } from "lucide-react";

type Props = {
  title?: string,

  // If it's a boolean, go back to the previous page in the user's browser history
  // If it's a string, go to the specified url
  goBackURL?: boolean | string,
  goBackFunction?: () => void,
  options?: JSX.Element
  className?: string
}

export default function Header({ title, goBackURL, goBackFunction, options, className }: Props) {
  const navigate = useNavigate()

  return (
    <div className="border-b lg:border-none mb-4 z-40">
      <div
        className={cn(
          "flex justify-center items-center w-full max-w-4xl min-h-16 mx-auto p-2 lg:p-4",
          className
        )}
      >
        <div className="flex-1 flex items-center truncate">
          {goBackURL && (
            <Button
              variant="ghost"
              size="icon"
              asChild
              className="lg:mr-2"
            >
              <Link
                to={typeof goBackURL === 'string' ? goBackURL : '#'}
                onClick={(e) => {
                  if(typeof goBackURL === 'boolean') {
                    e.preventDefault()
                    navigate(-1)
                  }
                }}
              >
                <ArrowLeft size={20} />
              </Link>
            </Button>
          )}

          {goBackFunction && (
            <Button
              variant="ghost"
              size="icon"
              onClick={goBackFunction}
              className="lg:mr-2"
            >
              <ArrowLeft size={20} />
            </Button>
          )}

          <h1 className="text-xl font-medium max-lg:pl-2 truncate">{title}</h1>
        </div>

        {options}
      </div>
    </div>
  )
}