import IngredientText from "@/components/ingredients/IngredientText.tsx"

import { Button } from "@/components/ui/button.tsx";

import { Ingredient as IngredientType } from "@/firebase/types"

type Props = {
  ingredient: IngredientType
  scaleRatio: number
  checkedIngredients: string[]
  setCheckedIngredients: (checkedIngredients: string[]) => void
}
export default function Ingredient({ ingredient, scaleRatio, checkedIngredients, setCheckedIngredients }: Props) {
  const checked = checkedIngredients.includes(ingredient.id)

  return (
    <Button
      type="button"
      variant="ghost"
      className={`block w-full h-auto text-base font-normal text-left whitespace-normal ${checked ? "!text-muted-foreground line-through" : ""} ${ingredient.isGroupHeader ? "font-semibold" : ""}`}
      onClick={() => {
        let newCheckedIngredients = [...checkedIngredients]
        if(checked) {
          newCheckedIngredients = newCheckedIngredients.filter(id => id !== ingredient.id)
        } else {
          newCheckedIngredients.push(ingredient.id)
        }

        setCheckedIngredients(newCheckedIngredients)
      }}
    >
      <IngredientText ingredient={ingredient} scaleRatio={scaleRatio} />
    </Button>
  )
}