import { z } from "zod";

import { serverTimestamp, FieldValue } from "firebase/firestore"
import { ListSortNamesSchema } from "./constants"

import { IngredientCategoriesSchema } from "@/constants.ts";

// Firebase auth
export const UserAccount = z.object({
  email: z.string(),
  password: z.string()
})
export type UserAccount = z.infer<typeof UserAccount>

// Firestore
export const User = z.object({
  _created: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),
  _updated: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),

  id: z.string().uuid(),
  email: z.string().email(),
  displayName: z.string(),
  agreeToTerms: z.array(z.object({
    version: z.number(),
    agreed: z.boolean(),
    timestamp: z.number(),
  })),
  freeAIRecipeGenerationsUsed: z.number(),
  freeRecipeImageImportsUsed: z.number(),
  freeRecipeSocialMediaImportsUsed: z.number(),

  // Removed - some user records still have this, but it is unused now
  // aiRecipesPerMonthCount: z.object({
  //   count: z.number(),
  //   date: z.object({
  //     year: z.number(),
  //     month: z.number(),
  //   }),
  // }),
})
export type User = z.infer<typeof User>

export const Ingredient = z.object({
  id: z.string().uuid(),

  name: z.string(),

  category_id: IngredientCategoriesSchema.optional().or(z.literal("")),

  // https://github.com/jakeboone02/parse-ingredient
  quantity: z.number().nullable(),
  quantity2: z.number().nullable(), // For range (ex. 1-2 pears: { quantity: 1, quantity2: 2 })
  unitOfMeasure: z.string().nullable(),
  unitOfMeasureID: z.string().nullable(),
  description: z.string(),
  isGroupHeader: z.boolean(),
})
export type Ingredient = z.infer<typeof Ingredient>

// Generally follows this schema: https://developers.google.com/search/docs/appearance/structured-data/recipe / https://schema.org/Recipe
export const Recipe = z.object({
  _created: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),
  _updated: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),

  userId: z.string(),
  id: z.string().uuid(),

  title: z.string(),

  description: z.string().optional(),

  // TODO: Add limits. Support multiple images
  image: z.object({
    name: z.string(),
    url: z.string(),
  }).optional(),

  ingredients: z.array(Ingredient),

  instructions: z.array(z.object({
    id: z.string().uuid(),
    text: z.string(),
    isGroupHeader: z.boolean(),
    url: z.string().optional(),
    image: z.union([z.string(), z.array(z.string())]).optional(),
  })),

  prepTime: z.object({
    hours: z.number(),
    minutes: z.number(),
  }),
  cookTime: z.object({
    hours: z.number(),
    minutes: z.number(),
  }),
  // Calculated onSubmit
  totalTime: z.object({
    hours: z.number(),
    minutes: z.number(),
  }),

  servings: z.number(),

  source: z.object({
    name: z.string().optional(),
    url: z.string().optional(),
    favicon: z.string().optional(),
  }).optional(),

  authors: z.array(z.object({
    name: z.string().optional(),
    url: z.string().optional(),
  })).optional(),

  datePublished: z.string().optional(),
  dateModified: z.string().optional(),

  keywords: z.string().optional(),
  category: z.union([z.string(), z.array(z.string())]).optional(),
  cuisine: z.union([z.string(), z.array(z.string())]).optional(),

  aggregateRating: z.object({
    ratingValue: z.string().optional(),
    ratingCount: z.string().optional(),
  }).optional(),

  video: z.object({
    name: z.string().optional(),
    description: z.string().optional(),
    thumbnailUrl: z.array(z.string()).optional(),
    contentUrl: z.string(),
    embedUrl: z.string().optional(),
    uploadDate: z.string().optional(),
    duration: z.object({
      hours: z.number(),
      minutes: z.number(),
    }).optional(),
  }).optional(),

  nutrition: z.object({
    servingSize: z.string().optional(),
    calories: z.string().optional(),
    carbohydrateContent: z.string().optional(),
    cholesterolContent: z.string().optional(),
    fatContent: z.string().optional(),
    fiberContent: z.string().optional(),
    proteinContent: z.string().optional(),
    saturatedFatContent: z.string().optional(),
    sodiumContent: z.string().optional(),
    sugarContent: z.string().optional(),
    transFatContent: z.string().optional(),
    unsaturatedFatContent: z.string().optional(),
  }).optional(),

  notes: z.string().optional(),

  imported: z.boolean().optional(),
  aiGenerated: z.boolean().optional(),
  imageImported: z.boolean().optional(),
  socialMediaImported: z.boolean().optional(),
})
export type Recipe = z.infer<typeof Recipe>

export const Collection = z.object({
  _created: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),
  _updated: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),

  userId: z.string(),
  id: z.string().uuid(),
  title: z.string(),

  // It's weird to keep these as 2 separate arrays, but it makes querying more reliable as firebase array-contains queries only work on exact matches, so objects are more difficult to query
  // Arrays are kept in sync by adding null or an image url to the recipeImages in the same spot as the recipe id in the recipeIds array
  recipeIds: z.array(Recipe.shape.id),
  recipeImages: z.array(z.string().or(z.null())),
})
export type Collection = z.infer<typeof Collection>

export const ListItem = Ingredient.extend({
  notes: z.string(),
  checked: z.boolean(),
  recipe: z.object({
    id: Recipe.shape.id,
    title: Recipe.shape.title,
  }).optional(),
})
export type ListItem = z.infer<typeof ListItem>

export const List = z.object({
  _created: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),
  _updated: z.custom<FieldValue>((value) => {
    return value instanceof FieldValue || value === serverTimestamp();
  }),

  userId: z.string(),
  id: z.string().uuid(),
  title: z.string(),
  sort: ListSortNamesSchema,
  items: z.array(ListItem),
})
export type List = z.infer<typeof List>