import { useTranslation } from "react-i18next"

import { ThemeToggleSelect } from "@/components/theme/ThemeToggleSelect.tsx";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";

export default function Theme() {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("account.components.sections.theme.title")}
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col">
        <ThemeToggleSelect />
      </CardContent>
    </Card>
  )
}