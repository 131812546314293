export default function IsSupportedSocialMediaUrl(url: string) {
  try {
    const parsedUrl = new URL(url);
    const validHostnames = [
      "instagram.com", "www.instagram.com", "instagr.am", "ig.me",
      "tiktok.com", "www.tiktok.com", "vm.tiktok.com",
      "youtube.com", "www.youtube.com", "youtu.be"
    ];
    return validHostnames.includes(parsedUrl.hostname);
  } catch(e) {
    console.error(e)

    return false
  }
}