import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"

import { Capacitor } from "@capacitor/core";

import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "@/firebase/app"

import Header from "@/components/navigation/Header"
import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import Theme from "@/routes/account/components/sections/Theme.tsx";
import AccountInformation from "@/routes/account/components/sections/AccountInformation.tsx";
import Subscription from "@/routes/account/components/sections/Subscription.tsx";
import DownloadTheApp from "@/routes/account/components/sections/DownloadTheApp.tsx";
import FollowUs from "@/routes/account/components/sections/FollowUs.tsx";
import ContactUs from "@/routes/account/components/sections/ContactUs.tsx";
import Legal from "@/routes/account/components/sections/Legal.tsx";

export default function Account() {
  const { t } = useTranslation()
  const [user, loading, error] = useAuthState(auth)

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("account.page.head.title")}</title>
      </Helmet>

      <Header title={t("account.components.Header.title")} />

      <div className="flex flex-col w-full max-w-4xl mx-auto gap-2 mb-4 lg:mb-16 px-4">
        <Theme />

        <Subscription />

        {!Capacitor.isNativePlatform() && (
          <DownloadTheApp />
        )}

        <FollowUs />

        <ContactUs />

        <Legal />

        <AccountInformation user={user} />
      </div>
    </Page>
  )
}