import AIStars from "@/components/icons/ai-stars/AIStars.tsx";

import { Skeleton } from "@/components/ui/skeleton.tsx";

import { Recipe } from "@/firebase/types"
import { Camera, Grid2x2Check } from "lucide-react";

type Props = {
  title?: Recipe["title"]
  aiGenerated?: Recipe["aiGenerated"]
  imageImported?: Recipe["imageImported"]
  socialMediaImported?: Recipe["socialMediaImported"]
}
export default function Title({ title, aiGenerated = false, imageImported = false, socialMediaImported = false }: Props) {
  return (
    <>
      {title ? (
        <h2 className="flex gap-2 text-2xl font-semibold">
          {aiGenerated && (
            <div className="mt-[2px]">
              <AIStars size={28} />
            </div>
          )}
          {imageImported && (
            <div className="mt-[2px]">
              <Camera size={28} style={{ stroke: "url(#ai-gradient)" }} />
            </div>
          )}
          {socialMediaImported && (
            <div className="mt-[2px]">
              <Grid2x2Check size={28} style={{ stroke: "url(#ai-gradient)" }} />
            </div>
          )}
          {title}
        </h2>
      ) : (
        <div className="flex gap-2">
          {aiGenerated && (
            <div>
              <AIStars size={32} />
            </div>
          )}

          <Skeleton className="w-full min-h-8" />
        </div>
      )}
    </>
  )
}