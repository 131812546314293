import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { analytics, db } from "@/firebase/app.ts";
import { deleteDoc, doc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";

import { Button } from "@/components/ui/button.tsx";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog.tsx"
import { Form, FormRootErrorMessage } from "@/components/ui/form.tsx";

import { Loader2 } from "lucide-react";

import { List } from "@/firebase/types.ts";

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  list: List | null
}

export default function DeleteListModal({ isOpen = false, setIsOpen, list }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const form = useForm()

  useEffect(() => {
    if(list) {
      form.reset()
    }
  }, [list])

  async function onSubmit() {
    try {
      if(list?.id) {
        await deleteDoc(doc(db, "lists", list.id))

        logEvent(analytics, 'delete_list')

        // This modal is used on the /lists and /lists/[id] pages
        // If on the ID page, go back to lists
        // Otherwise just close the modal
        if(params.hasOwnProperty('id')) {
          navigate('/lists')
        } else {
          setIsOpen(false)
        }
      } else {
        throw new Error(`${t('lists.components.modals.DeleteListModal.modal.form.onSubmit.error.missingParams')}`)
      }
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("lists.components.modals.DeleteListModal.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <AlertDialogHeader>
              <AlertDialogTitle>{t('lists.components.modals.DeleteListModal.modal.title', { listTitle: list?.title })}</AlertDialogTitle>
              <AlertDialogDescription>
                {t('lists.components.modals.DeleteListModal.modal.description')}
              </AlertDialogDescription>
            </AlertDialogHeader>

            <AlertDialogFooter>
              <AlertDialogCancel>{t('lists.components.modals.DeleteListModal.modal.form.actions.cancel')}</AlertDialogCancel>

              <Button
                type="submit"
                variant="destructive"
                disabled={form.formState.isSubmitting}
              >
                {form.formState.isSubmitting ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t("lists.components.modals.DeleteListModal.modal.form.actions.delete")}
              </Button>
            </AlertDialogFooter>

            <FormRootErrorMessage />
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}