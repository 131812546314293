import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";

import { ChevronRight } from "lucide-react";

export default function Legal() {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("account.components.sections.legal.title")}
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col px-2">
        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://www.flavorish.ai/terms-of-use"
            target="_blank"
          >
            {t("account.components.sections.legal.termsOfUse")}
            <ChevronRight size={20} />
          </Link>
        </Button>

        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://www.flavorish.ai/privacy-policy"
            target="_blank"
          >
            {t("account.components.sections.legal.privacyPolicy")}
            <ChevronRight size={20} />
          </Link>
        </Button>
      </CardContent>
    </Card>
  )
}