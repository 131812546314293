import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { auth } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";

import Typesense from "typesense";
import { SearchResponse, SearchResponseHit } from "typesense/lib/Typesense/Documents";

import SearchRecipesHit from "@/routes/recipes/components/modals/SearchRecipesHit.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import InfiniteScroll from "@/components/ui/expansions/infinite-scroll.tsx";

import { Loader2, Search, X } from "lucide-react";

import { Recipe } from "@/firebase/types.ts";

let client = new Typesense.Client({
  "nodes": [{
    host: import.meta.env.VITE_TYPESENSE_HOST,
    port: import.meta.env.VITE_TYPESENSE_PORT,
    protocol: import.meta.env.VITE_TYPESENSE_PROTOCOL
  }],
  "apiKey": import.meta.env.VITE_TYPESENSE_SEARCH_RECIPES_API_KEY,
  "connectionTimeoutSeconds": 2
})

const hitsPerPage = 10

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}
export default function SearchRecipes({ isOpen = false, setIsOpen }: Props) {
  const { t } = useTranslation()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [hits, setHits] = useState<SearchResponseHit<Recipe>[] | undefined>(undefined)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const page = useRef(1)

  const formSchema = z.object({
    query: z.string()
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      query: ""
    },
  })

  // Initial query
  useEffect(() => {
    if(isOpen && !hits) {
      searchQuery("")
    }
  }, [isOpen])

  // Submit form onChange
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())
    return () => subscription.unsubscribe();
  }, [form.handleSubmit, form.watch]);

  function searchQuery(q: string) {
    const searchParameters = {
      "q": q,
      "query_by": "title,description,keywords",
      "filter_by": `userId: ${user?.uid}`,
      "page": page.current,
      "per_page": hitsPerPage,
      "prioritize_token_position": true
    }

    client.collections(import.meta.env.VITE_TYPESENSE_RECIPES_COLLECTION)
      .documents()
      .search(searchParameters)
      // @ts-ignore
      .then(function(searchResults: SearchResponse<Recipe>) {
        // If loading more results from another page of hits, update the array instead of replacing it
        if(page.current > 1 && Array.isArray(hits) && Array.isArray(searchResults.hits)) {
          setHits([...hits, ...searchResults.hits])
        } else {
          setHits(searchResults.hits)
        }

        if((searchResults.hits && searchResults.hits.length >= hitsPerPage) || (hits && hits.length < searchResults.found)) {
          setCanFetchMore(true)
        } else {
          setCanFetchMore(false)
        }
      })
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { query } = values

    try {
      page.current = 1
      searchQuery(query)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "SearchRecipes.onSubmit.generic",
        message: `${t("recipes.components.modals.SearchRecipes.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  async function next() {
    if(hits) {
      setLoadingMore(true)

      page.current++
      searchQuery(form.getValues("query"))

      setLoadingMore(false)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="flex flex-col w-full max-w-4xl max-lg:h-dvh lg:top-0 lg:translate-y-0 max-h-dvh overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("recipes.components.modals.SearchRecipes.modal.title")}</DialogTitle>
        </DialogHeader>

        <div className="py-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 mb-4">
              <FormField
                control={form.control}
                name="query"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="relative w-full">
                        <Input placeholder={t("recipes.components.modals.SearchRecipes.modal.form.fields.query.placeholder")} className="pl-10" {...field} />

                        <Button
                          type="button"
                          size="icon"
                          variant="ghost"
                          className="absolute right-0 top-0 left-0 !opacity-100"
                          disabled
                        >
                          <Search size={20} className="text-muted-foreground" />
                        </Button>

                        {field.value !== '' && (
                          <Button
                            type="button"
                            size="icon"
                            variant="ghost"
                            className="absolute right-0 top-0 bottom-0"
                            onClick={() => form.setValue('query', '')}
                          >
                            <X size={20} className="text-muted-foreground" />
                          </Button>
                        )}
                      </div>
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormRootErrorMessage />
            </form>
          </Form>

          {hits && hits.length > 0 ? (
            <>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-2 gap-y-6">
                {hits.map((hit) => (
                  <SearchRecipesHit key={hit.document.id} id={hit.document.id} />
                ))}
              </div>

              <InfiniteScroll hasMore={canFetchMore} isLoading={loadingMore} next={next} threshold={1}>
                {canFetchMore && (
                  <div className="flex justify-center items-center my-16">
                    <Loader2 size={48} className="animate-spin" />
                  </div>
                )}
              </InfiniteScroll>
            </>
          ) : (
            <div className="flex justify-center items-center my-16">
              {t("recipes.components.modals.SearchRecipes.modal.form.noResults")}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}