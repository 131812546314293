import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { parseIngredient } from "parse-ingredient"

import { db } from "@/firebase/app"
import { doc, serverTimestamp, updateDoc } from "firebase/firestore"

import { SearchResponseHit } from "typesense/src/Typesense/Documents.ts";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import EditItemForm from "@/routes/lists/[id]/components/EditItemForm.tsx";

import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "@/components/ui/drawer.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import { FIELD_LIMITS } from "@/globals.js";

import { Ingredient, List, ListItem } from "@/firebase/types.ts";

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  item: List["items"][0] | null,
  list: List
}
export default function EditItem({ isOpen = false, setIsOpen, item, list }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [hits, setHits] = useState<SearchResponseHit<Ingredient>[] | undefined>(undefined)
  const [showAutocomplete, setShowAutocomplete] = useState(false)

  // onOpenChange function doesn't run when Dialog opens for some reason... Works fine on the Drawer
  useEffect(() => {
    if(isOpen) {
      form.reset()
      if(item) {
        form.setValue('itemName', item.name)
        form.setValue('notes', item.notes)
      }
    }

    setShowAutocomplete(false)
  }, [isOpen])

  const formSchema = z.object({
    itemName: ListItem.shape.name
      .min(1, {
        message: t("lists.[id].components.EditItem.modal.form.fields.name.validation.required")
      })
      .max(FIELD_LIMITS.INGREDIENT.NAME.MAX_LENGTH, {
        message: t("lists.[id].components.EditItem.modal.form.fields.name.validation.maxLength", { maxLength: FIELD_LIMITS.INGREDIENT.NAME.MAX_LENGTH })
      }),
    notes: ListItem.shape.notes
      .max(FIELD_LIMITS.LIST.ITEMS.NOTES.MAX_LENGTH, {
        message: t("lists.[id].components.EditItem.modal.form.fields.notes.validation.maxLength", { maxLength: FIELD_LIMITS.LIST.ITEMS.NOTES.MAX_LENGTH })
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      itemName: "",
      notes: ""
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { itemName, notes } = values

    let newItem: Pick<List["items"][0], "name" | "category_id" | "notes"> = {
      name: itemName,
      category_id: "",
      notes,
    }

    try {
      // If user didn't edit text (ie. edited notes only), don't change the category
      if(item?.name === itemName) {
        newItem.category_id = item?.category_id || ""
      }

      // Match the text with a hit to enable categorization
      if(hits) {
        const matchedHit = hits.find(hit => hit.document.name.toLowerCase() === newItem.name.toLowerCase())
        if(matchedHit) {
          newItem.name = matchedHit.document.name
          newItem.category_id = matchedHit.document.category_id
        }
      }

      // Parse ingredient
      newItem = {
        ...newItem,
        ...parseIngredient(itemName)[0]
      }

      if(item) {
        const newItems: List["items"] = list.items.map(currItem => {
          if(item.id === currItem.id) {
            return {
              ...currItem,
              ...newItem
            }
          }
          return currItem
        })

        await updateDoc(doc(db, "lists", list.id), {
          _updated: serverTimestamp(),
          items: newItems
        })

        setIsOpen(false)
      }
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "EditItem.onSubmit.generic",
        message: `${t("lists.[id].components.EditItem.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <>
      {isMobile ? (
        <Drawer
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>
                {t("lists.[id].components.EditItem.modal.title")}
              </DrawerTitle>
            </DrawerHeader>

            <EditItemForm
              form={form}
              onSubmit={onSubmit}
              hits={hits}
              setHits={setHits}
              showAutocomplete={showAutocomplete}
              setShowAutocomplete={setShowAutocomplete}
            />
          </DrawerContent>
        </Drawer>
      ) : (
        <Dialog
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <DialogContent className="sm:max-w-sm">
            <DialogHeader>
              <DialogTitle>{t("lists.[id].components.EditItem.modal.title")}</DialogTitle>
            </DialogHeader>

            <EditItemForm
              form={form}
              onSubmit={onSubmit}
              hits={hits}
              setHits={setHits}
              showAutocomplete={showAutocomplete}
              setShowAutocomplete={setShowAutocomplete}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}