type Props = {
  size?: number,
  strokeWidth?: number
  percentage?: number
  className?: string
}
const CircularProgress = ({ size = 50, strokeWidth = 3, percentage = 0, className, ...props }: Props) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <svg
      width={size}
      height={size}
      className={`-rotate-90 ${className}`}
      {...props}
    >
      <circle
        className="stroke-primary opacity-20"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className="stroke-primary"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: offset,
        }}
      />
    </svg>
  );
};

export default CircularProgress;
