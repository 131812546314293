import { useTranslation } from "react-i18next";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Header from "@/components/navigation/Header.tsx";
import AddList from "@/routes/lists/components/AddList.tsx";

export default function ListsHeader() {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Header
      title={t("lists.components.general.ListsHeader.title")}
      options={
        <>
          {!isMobile && (
            <AddList />
          )}
        </>
      }
    />
  )
}