import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { db } from "@/firebase/app.ts"
import { doc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import Header from "@/components/navigation/Header.tsx"

import AddEditRecipeForm from "@/routes/recipes/components/form/AddEditRecipeForm.tsx";

import { Recipe } from "@/firebase/types"

export default function EditRecipe() {
  const { t } = useTranslation()
  const params = useParams()

  const recipeID = params.id as string
  const [recipeData, loading, error, snapshot] = useDocumentData(
    doc(db, "recipes", recipeID)
  )
  const recipe = recipeData as Recipe | undefined


  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("recipes.[id].edit.page.head.title", { recipeName: recipe?.title })}</title>
      </Helmet>

      {recipe && (
        <>
          <Header
            title={t("recipes.[id].edit.components.Header.title", { recipeTitle: recipe?.title })}
            goBackURL={`/recipes/${recipe.id}`}
          />

          <AddEditRecipeForm recipe={recipe} isEditing={true} />
        </>
      )}
    </Page>
  )
}
