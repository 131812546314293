import { useTranslation } from "react-i18next";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Header from "@/components/navigation/Header.tsx";

import AddCollection from "@/routes/collections/components/AddCollection.tsx";

export default function CollectionsHeader() {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Header
      title={t("collections.components.general.CollectionsHeader.title")}
      options={
        <>
          {!isMobile && (
            <AddCollection />
          )}
        </>
      }
    />
  )
}