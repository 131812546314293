// https://github.com/carsten-klaffke/send-intent

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Capacitor } from "@capacitor/core";
import { AppLauncher } from "@capacitor/app-launcher";
import { SendIntent } from "send-intent"

import IsSupportedSocialMediaUrl from "@/lib/helpers/IsSupportedSocialMediaUrl.ts"

export default function ShareIntentHandler() {
  const navigate = useNavigate()

  function checkSendIntent() {
    SendIntent.checkSendIntentReceived().then(async (result: any) => {
      if(result) {
        // Instagram's share button (at least on web) puts text in the result.url field - this fixes that and also matches a plain url correctly
        const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        const urls = decodeURIComponent(result.url).match(urlRegex)
        // This is stupid but some apps like youtube or the web version of instagram put the url in the title instead of the url field...
        const urlsFromTitle = decodeURIComponent(result.title).match(urlRegex)
        let parsedUrl = urls ? urls[0] : null

        if(parsedUrl === null) {
          parsedUrl = urlsFromTitle ? urlsFromTitle[0] : null
        }

        if(parsedUrl !== null) {
          // If it's a supported social media link, go to the social media import tool
          // Else go to the import recipe tool
          let nextUrl: string = "/"

          if(IsSupportedSocialMediaUrl(parsedUrl)) {
            nextUrl = `/recipes/import-social-media?link=${parsedUrl}`
          } else {
            nextUrl = `/recipes?importRecipe=${parsedUrl}`
          }

          // Instructions mention a potential issue on Android - https://github.com/carsten-klaffke/send-intent?tab=readme-ov-file
          // This fixes it - https://github.com/carsten-klaffke/send-intent/issues/69#issuecomment-1544619608
          if (Capacitor.getPlatform() === 'android') {
            await AppLauncher.openUrl({
              url: `${import.meta.env.VITE_WEB_BASE_URL}${nextUrl}`,
            }).catch(console.error)
          } else {
            navigate(nextUrl)
          }
        }
      }
    }).catch(err => {
      console.error(err)
    })
  }

  // Runs on app startup
  useEffect(() => {
    // Listen for intents while app is open
    window.addEventListener("sendIntentReceived", checkSendIntent)

    // Check on app startup
    checkSendIntent()
  }, [])

  return null
}