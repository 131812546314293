import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"

import { auth, db } from "@/firebase/app"
import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { useCollectionData } from "react-firebase-hooks/firestore"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import ListsHeader from "@/routes/lists/components/general/ListsHeader.tsx";
import NoLists from "@/routes/lists/components/general/NoLists.tsx";

import ListItem from "@/routes/lists/components/ListItem.tsx";
import AddList from "@/routes/lists/components/AddList"
import RenameListModal from "@/routes/lists/components/modals/RenameListModal.tsx"
import DeleteListModal from "@/routes/lists/components/modals/DeleteListModal.tsx"

import InfiniteScroll from "@/components/ui/expansions/infinite-scroll.tsx";

import { Loader2 } from "lucide-react";

import { List } from "@/firebase/types"

const PAGE_LIMIT = 10

export default function ListsPage() {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [listsData, loading, error] = useCollectionData(
    query(
      collection(db, "lists"),
      where("userId", "==", user?.uid),
      orderBy("_created", "desc"),
      limit(PAGE_LIMIT)
    )
  )

  const [lists, setLists] = useState<List[] | null>(null)

  const [canFetchMore, setCanFetchMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  // Stores 1 list item and passes it to the rename modal to handle
  const [showRenameListModal, setShowRenameListModal] = useState(false)
  const [currentRenamingList, setCurrentRenamingList] = useState<List | null>(null)

  // Stores 1 list item and passes it to the delete modal to handle
  const [showDeleteListModal, setShowDeleteListModal] = useState(false)
  const [currentDeletingList, setCurrentDeletingList] = useState<List | null>(null)

  useEffect(() => {
    if(listsData && !loading && !error) {
      setLists(listsData as List[])

      if(listsData.length >= PAGE_LIMIT) {
        setCanFetchMore(true)
      }
    }
  }, [listsData])

  async function next() {
    if(lists) {
      setLoadingMore(true)

      const querySnapshot = await getDocs(query(
        collection(db, "lists"),
        where("userId", "==", user?.uid),
        orderBy("_created", "desc"),
        startAfter(lists[lists.length - 1]._created),
        limit(PAGE_LIMIT)
      ))

      const newLists: List[] = []
      querySnapshot.forEach(doc => {
        newLists.push(doc.data() as List)
      })

      setLists([...lists, ...newLists])

      if(newLists.length < PAGE_LIMIT) {
        setCanFetchMore(false)
      }

      setLoadingMore(false)
    }
  }

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("lists.page.head.title")}</title>
      </Helmet>

      <ListsHeader />

      <div className="flex flex-col flex-grow w-full max-w-4xl mx-auto px-4">
        {lists && lists?.length > 0 ? (
          <>
            <div className="flex flex-col gap-2 mb-16">
              {lists?.map((list) => (
                <ListItem
                  key={list.id}
                  list={list}
                  setShowRenameListModal={setShowRenameListModal}
                  setCurrentRenamingList={setCurrentRenamingList}
                  setShowDeleteListModal={setShowDeleteListModal}
                  setCurrentDeletingList={setCurrentDeletingList}
                />
              ))}

              <InfiniteScroll hasMore={canFetchMore} isLoading={loadingMore} next={next} threshold={1}>
                {canFetchMore && (
                  <div className="flex justify-center items-center my-16">
                    <Loader2 size={48} className="animate-spin" />
                  </div>
                )}
              </InfiniteScroll>
            </div>

            <RenameListModal
              isOpen={showRenameListModal}
              setIsOpen={setShowRenameListModal}
              list={currentRenamingList}
            />
            <DeleteListModal
              isOpen={showDeleteListModal}
              setIsOpen={setShowDeleteListModal}
              list={currentDeletingList}
            />
          </>
        ) : (
          <NoLists />
        )}
      </div>

      {isMobile && (
        <AddList />
      )}
    </Page>
  )
}
