import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next";

import Page from "@/components/page/Page.tsx";

import Header from "@/components/navigation/Header"

import AddEditRecipe from "@/routes/recipes/components/form/AddEditRecipeForm.tsx";

export default function AddRecipe() {
  const { t } = useTranslation()

  return (
    <Page>
      <Helmet>
        <title>{t("recipes.add.page.head.title")}</title>
      </Helmet>

      <Header title={t("recipes.add.components.Header.title")} goBackURL="/recipes" />

      <AddEditRecipe />
    </Page>
  )
}
