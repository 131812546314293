import React, { useEffect, useState } from "react";

import RecipeLayout from "@/components/recipes/RecipeLayout.tsx";

import { Button } from "@/components/ui/button.tsx";

import { ChevronDown } from "lucide-react";

import { Recipe } from "@/firebase/types";

type Props = {
  recipe: Partial<Recipe>
}
export default function RecipeLoading({ recipe }: Props) {
  const [stickScrollToBottom, setStickScrollToBottom] = useState(true)

  if(stickScrollToBottom) {
    window.scrollTo(0, document.body.scrollHeight);
  }

  useEffect(() => {
    const handleScroll = () => {
      if(window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 50) {
        // Re-enable sticky scroll again if user scrolls to bottom of the page
        setStickScrollToBottom(true)
      } else {
        // Disable sticky scroll if user scrolls up
        setStickScrollToBottom(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <RecipeLayout recipe={recipe} isLoading={true} />

      {!stickScrollToBottom && (
        <Button
          size="icon"
          className="floating-action-btn w-12 h-12"
          onClick={() => {
            setStickScrollToBottom(true)
          }}
        >
          <ChevronDown size={28} />
        </Button>
      )}
    </>
  )
}