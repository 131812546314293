import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

import { auth } from "@/firebase/app.ts"
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth"

import { Button } from "@/components/ui/button.tsx";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { UserCircle, LogOut } from "lucide-react";

import LogoImg from "@/assets/icon.svg"

export default function DesktopNav() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [user, loading, error] = useAuthState(auth)

  function signOutUser() {
    {
      signOut(auth).then(() => {
        // If native app, logout of the native layer too
        if(Capacitor.isNativePlatform()) {
          FirebaseAuthentication.signOut()
            .finally(() => {
              // Redirect user to login page no matter what
              navigate("/login")
            })
        }
      }).catch((e) => {
        console.error(e)

        toast({
          title: t("account.components.sections.account.logout.onClick.error.generic.title"),
          description: t("account.components.sections.account.logout.onClick.error.generic.description"),
          variant: "destructive"
        })
      })
    }
  }

  if(!user) {
    return null
  }

  return (
    <div className="bg-background border-b border-input z-50">
      <div className="grid grid-cols-3 items-center max-w-7xl mx-auto p-2">
        <div>
          <Button variant="ghost" asChild className="h-14 gap-2">
            <NavLink to="/">
              <img src={LogoImg} alt="Flavorish" className="h-full" />
              <p className="font-poppins text-2xl">Flavorish</p>
            </NavLink>
          </Button>
        </div>

        <div className="flex justify-center">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavLink
                  to="/recipes"
                  className={({ isActive }) =>
                    [
                      isActive || pathname === "/" ? "active" : "",
                      navigationMenuTriggerStyle(),
                      "!text-base"
                    ].join(' ')
                  }
                >
                  {t("components.navigation.DesktopNav.links.recipes")}
                </NavLink>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavLink
                  to="/collections"
                  className={({ isActive }) =>
                    [
                      isActive ? "active" : "",
                      navigationMenuTriggerStyle(),
                      "!text-base"
                    ].join(' ')
                  }
                >
                  {t("components.navigation.DesktopNav.links.collections")}
                </NavLink>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavLink
                  to="/lists"
                  className={({ isActive }) =>
                    [
                      isActive ? "active" : "",
                      navigationMenuTriggerStyle(),
                      "!text-base"
                    ].join(' ')
                  }
                >
                  {t("components.navigation.DesktopNav.links.lists")}
                </NavLink>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </div>

        <div className="flex justify-end">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="relative h-9 w-9 rounded-full">
                <Avatar className="h-9 w-9 bg-transparent">
                  {user && user.photoURL && user.displayName && (
                    <AvatarImage src={user.photoURL} alt={user.displayName} />
                  )}
                  <AvatarFallback>
                    <UserCircle size={40} />
                  </AvatarFallback>
                </Avatar>
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end">
              <DropdownMenuGroup>
                <Link to="/account" className="w-full">
                  <DropdownMenuItem className="cursor-pointer">
                    {t("components.navigation.DesktopNav.userDropdown.links.account")}
                  </DropdownMenuItem>
                </Link>

                <DropdownMenuSeparator />

                <DropdownMenuItem>
                  <button
                    className="flex justify-between items-center w-full"
                    onClick={signOutUser}
                  >
                    <span>{t("components.navigation.DesktopNav.userDropdown.links.logout")}</span>
                    <LogOut size={20} />
                  </button>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}