import { useTranslation } from "react-i18next";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";

import { Recipe } from "@/firebase/types"

type Props = {
  notes?: Recipe["notes"]
}
export default function Notes({ notes }: Props) {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader className="flex flex-row justify-between items-start pt-4 pb-4">
        <CardTitle className="text-lg font-semibold">{t("recipes.[id].components.recipe.Notes.title")}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="whitespace-pre-wrap break-words">{notes}</p>
      </CardContent>
    </Card>
  )
}