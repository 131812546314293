import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { GenerateRecipeFormInputs } from "@/routes/recipes/generate/types";

type Props = {
  form: UseFormReturn<GenerateRecipeFormInputs>
}
export default function ByRecipe({ form }: Props) {
  const { t } = useTranslation()

  form.register("title", {
    maxLength: {
      value: 500,
      message: `${t("recipes.generate.components.steps.ByRecipe.fields.title.validation.maxLength", { maxLength: 500 })}`
    }
  })

  return (
    <FormField
      control={form.control}
      name="title"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t("recipes.generate.components.steps.ByRecipe.fields.title.label")}</FormLabel>
          <FormControl>
            <Input placeholder={t("recipes.generate.components.steps.ByRecipe.fields.title.placeholder")} {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}