// Thanks chatGPT
export default async function base64ImageToFile(base64Image, fileNameWithoutExtension) {
  let base64Data = base64Image;
  let mimeType = '';

  // Check if the string starts with "data:image"
  if(base64Image.startsWith('data:image')) {
    mimeType = base64Image.match(/data:(image\/[a-zA-Z]+);base64,/)[1];
  } else {
    // Try to infer the image format if no prefix is present
    // You can add more sophisticated checks or default to a common type like jpeg
    const defaultMimeType = 'image/jpeg'; // Default to jpeg if uncertain
    const formatCheck = base64Image.trim().slice(0, 5);

    if(formatCheck.includes('iVBOR')) {
      mimeType = 'image/png';
    } else if(formatCheck.includes('/9j/4')) {
      mimeType = 'image/jpeg';
    } else if(formatCheck.includes('UklGR')) {
      mimeType = 'image/webp';
    } else {
      mimeType = defaultMimeType;
    }

    base64Data = `data:${mimeType};base64,${base64Image}`;
  }

  const imageRes = await fetch(base64Data);
  const imageBlob = await imageRes.blob();

  // Extract the extension from the MIME type
  const extension = mimeType.split('/')[1];
  const adjustedExtension = extension === 'jpeg' ? 'jpg' : extension;

  return new File([imageBlob], `${fileNameWithoutExtension}.${adjustedExtension}`, { type: mimeType });
}
