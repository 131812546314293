import { useSearchParams, Navigate, useLocation } from "react-router-dom"
import Loading from "@/components/page/Loading.tsx"

export default function AuthAction() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation()
  const mode = searchParams.get('mode')

  if(mode === 'resetPassword') {
    return <Navigate to={`/reset-password${search}`} replace />
  }

  return <Loading />
}