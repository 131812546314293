import { useState } from "react"
import { useTranslation } from "react-i18next"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Header from "@/components/navigation/Header.tsx";

import SearchRecipes from "@/routes/recipes/components/modals/SearchRecipes.tsx";
import AddRecipe from "@/routes/recipes/components/AddRecipe.tsx";

import { Button } from "@/components/ui/button.tsx";

import { Search } from "lucide-react";

type Props = {
  hasRecipes: boolean
}
export default function RecipesHeader({ hasRecipes }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [showSearchRecipesModal, setShowSearchRecipesModal] = useState(false)

  return (
    <>
      <div className="w-full max-w-7xl mx-auto">
        {(!hasRecipes || !isMobile) && (
          <Header
            title={t("recipes.components.general.RecipesHeader.title")}
            className="max-w-7xl"
            options={
              <AddRecipe />
            }
          />
        )}

        {hasRecipes && (
          <div className="p-4 lg:pt-0 lg:-mt-4">
            <Button
              type="button"
              variant="ghost"
              size="icon"
              // Most styles copied from input ui component
              className="flex justify-between items-center gap-2 h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background text-muted-foreground font-normal focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => {
                setShowSearchRecipesModal(true)
              }}
            >
              <Search size={20} />
              <span>{t("recipes.components.general.RecipesHeader.searchButton")}</span>
              <span />
            </Button>
          </div>
        )}
      </div>

      <SearchRecipes isOpen={showSearchRecipesModal} setIsOpen={setShowSearchRecipesModal} />
    </>
  )
}