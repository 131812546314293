import { z } from "zod";

export enum ListSortNames {
  Aisle = "aisle",
  Recipe = "recipe",
  Custom = "custom"
}

export const ListSortNamesSchema = z.enum([
  ListSortNames.Aisle,
  ListSortNames.Recipe,
  ListSortNames.Custom,
]);