import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { analytics, auth, db } from "@/firebase/app"
import { setDoc, serverTimestamp, doc } from "firebase/firestore"
import { logEvent } from "firebase/analytics";
import { useAuthState } from "react-firebase-hooks/auth"
import { uuidv4 } from "@firebase/util"
import { ListSortNames } from "@/firebase/constants"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { FIELD_LIMITS } from "@/globals.js";

import { Loader2, Plus } from "lucide-react";

import { List } from "@/firebase/types"

export default function AddList() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const [user, loading, error] = useAuthState(auth)

  const date = new Date()

  const formSchema = z.object({
    title: List.shape.title
      .min(1, {
        message: t("lists.components.AddList.modal.form.fields.title.validation.required")
      })
      .max(FIELD_LIMITS.LIST.TITLE.MAX_LENGTH, {
        message: t('lists.components.AddList.modal.form.fields.title.validation.maxLength', { maxLength: FIELD_LIMITS.LIST.TITLE.MAX_LENGTH })
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: `${t('lists.components.AddList.modal.form.fields.title.defaultValue', { date: `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}` })}`,
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { title } = values

    try {
      // Just in case the uid is missing
      if(!user?.uid) {
        throw new Error('User is missing uid!')
      }

      const id = uuidv4()

      const newListObj: List = {
        _created: serverTimestamp(),
        _updated: serverTimestamp(),
        id: id,
        userId: user.uid,
        title: title,
        sort: ListSortNames.Aisle,
        items: []
      }

      await setDoc(doc(db, "lists", id), newListObj)

      logEvent(analytics, 'create_list')

      form.reset()

      navigate(`/lists/${id}`)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "AddList.onSubmit.generic",
        message: `${t("lists.components.AddList.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <Dialog>
      {isMobile ? (
        <DialogTrigger asChild>
          <Button
            variant="primary"
            size="icon"
            className="floating-action-btn w-12 h-12"
          >
            <Plus size={28} />
          </Button>
        </DialogTrigger>
      ) : (
        <DialogTrigger asChild>
          <Button
            variant="primary"
            className="gap-2"
          >
            <Plus size={20} />
            {t("lists.components.AddList.button")}
          </Button>
        </DialogTrigger>
      )}
      <DialogContent className="sm:max-w-sm">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <DialogHeader>
              <DialogTitle>{t("lists.components.AddList.modal.title")}</DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("lists.components.AddList.modal.form.fields.title.label")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("lists.components.AddList.modal.form.fields.title.placeholder")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="submit"
                variant="primary"
                className="w-full"
                disabled={form.formState.isSubmitting}
              >
                {form.formState.isSubmitting ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t("lists.components.AddList.modal.form.actions.create")}
              </Button>
            </DialogFooter>

            <FormRootErrorMessage />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
