import { Sparkle } from "lucide-react";

type Props = {
  size?: number
}
export default function AIStars({ size = 24 }: Props) {
  return (
    <div className="relative" style={{ width: size, height: size }}>
      {/* Main star */}
      <Sparkle size={size} style={{ stroke: "url(#ai-gradient)" }} />
      {/* Small star */}
      <Sparkle size={size} style={{ stroke: "url(#ai-gradient)" }} strokeWidth={4} className="absolute right-0 top-0 w-1/3 h-1/3" />
      {/* Smallest star */}
      <Sparkle size={size} style={{ stroke: "url(#ai-gradient)" }} strokeWidth={4} className="absolute left-[1px] bottom-[1px] w-1/5 h-1/5" />
    </div>
  )
}