import { useEffect } from "react";
import { useTranslation } from "react-i18next"
import { UseFormReturn } from "react-hook-form"
import { z } from "zod";
import { FileDrop } from "react-file-drop"

import { Image as LuImage } from "lucide-react"

import { Button } from "@/components/ui/button.tsx";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { Camera } from '@capacitor/camera';

import { formSchema } from "@/routes/recipes/components/form/AddEditRecipeForm.tsx";
import { Recipe } from "@/firebase/types.ts";

type Props = {
  form: UseFormReturn<z.infer<typeof formSchema>>
  recipeImage?: Recipe["image"]
}

export default function ImageManager({ form, recipeImage }: Props) {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
    watch
  } = form

  const currImage = watch("image")

  // TODO: Allow multiple images
  register("image")

  // If we have an initial image, set it here - setting it using react-hook-form methods doesn't work
  useEffect(() => {
    if(!isSubmitting && recipeImage) {
      setValue("image", recipeImage?.url)
    }
  }, [])

  async function validateImage(imageStr) {
    if(imageStr) {
      const res = await fetch(imageStr)
      const imageBlob = await res.blob()
      const imageFile = new File([imageBlob], `image.${imageBlob.type.replace('image/', '')}`, { type: imageBlob.type })

      const fileSizeMB = (imageFile.size / (1024 * 1024)).toFixed(2) // MB

      if(parseInt(fileSizeMB) > 10) {
        setError('image', { message: `${t("lib.images.validation.upload.tooLarge")}` })
        setValue('image', "")
        return false
      } else if(imageFile.type !== "image/png" && imageFile.type !== "image/jpeg" && imageFile.type !== "image/webp") {
        setError('image', { message: `${t("lib.images.validation.upload.badType")}` })
        setValue('image', "")
        return false
      } else {
        clearErrors('image')
        return true
      }
    } else {
      return false
    }
  }

  async function pickImage() {
    if(!isSubmitting) {
      try {
        const galleryPhotos = await Camera.pickImages({
          limit: 1
        })

        if(galleryPhotos.photos && galleryPhotos.photos.length > 0) {
          const pickedImage = galleryPhotos.photos[0]

          if(await validateImage(pickedImage.webPath)) {
            setValue('image', pickedImage.webPath)
          }
        }
      } catch(e) {
        console.error(e)
        // Ignore errors
      }
    }
  }

  function deleteImage() {
    if(!isSubmitting) {
      setValue('image', "")
      clearErrors('image')
    }
  }

  return (
    <FormField
      control={form.control}
      name="image"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t("recipes.components.form.components.ImageManager.label")}</FormLabel>

          <FormControl>
            <>
              {currImage ? (
                <div>
                  <img
                    src={currImage}
                    alt=""
                    className="w-full rounded-md"
                  />

                  <div className="flex gap-2 w-full py-2">
                    <Button
                      type="button"
                      variant="accent"
                      className="w-full"
                      disabled={isSubmitting}
                      onClick={pickImage}
                    >
                      {t("recipes.components.form.components.ImageManager.actions.update")}
                    </Button>
                    <Button
                      type="button"
                      variant="destructive"
                      className="w-full"
                      disabled={isSubmitting}
                      onClick={deleteImage}
                    >
                      {t("recipes.components.form.components.ImageManager.actions.delete")}
                    </Button>
                  </div>
                </div>
              ) : (
                <FileDrop
                  targetClassName={`flex flex-col items-center justify-center w-full h-64 bg-background p-4 border-2 ${errors.image ? "!border-error" : ""} border-dashed rounded-md cursor-pointer text-muted-foreground`}
                  draggingOverTargetClassName="bg-primary/20 border-primary text-primary"
                  onTargetClick={pickImage}
                  onDrop={async (files, e) => {
                    e.preventDefault()

                    if(!isSubmitting && files && files.length > 0) {
                      const imageStr = URL.createObjectURL(files[0])
                      if(await validateImage(imageStr)) {
                        setValue('image', imageStr)
                      }
                    }
                  }}
                >
                    <LuImage size={40} className="mb-2" />

                    <p className="text-base font-medium">
                      {t("recipes.components.form.components.ImageManager.dropzoneText")}
                    </p>
                </FileDrop>
              )}

              <Input
                id="image"
                type="text"
                className="hidden"
                hidden
                disabled={isSubmitting}
                {...field}
              />
            </>
          </FormControl>

          <FormDescription>{t("recipes.components.form.components.ImageManager.description")}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}