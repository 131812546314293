import React from "react";
import { useTranslation } from "react-i18next";

import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "@/firebase/app.ts";

import Item from "@/routes/lists/[id]/components/items/Item.tsx";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

import { List } from "@/firebase/types.ts";
import { useToast } from "@/components/ui/use-toast.ts";
import { Button } from "@/components/ui/button.tsx";

type Props = {
  list: List
  handleItemClick: (item: List["items"][0]) => void
  handleItemCheck: (itemID: List["items"][0]["id"]) => void
}
export default function CheckedItems({ list, handleItemClick, handleItemCheck }: Props) {
  const { t } = useTranslation()
  const { toast } = useToast()

  // Sort items alphabetically
  const items = [...list.items]
  items.sort((a, b) => a.name.localeCompare(b.name))
  const unCheckedItems = items.filter(item => !item.checked)
  const checkedItems = items.filter(item => item.checked)

  async function handleDeleteItems() {
    try {
      await updateDoc(doc(db, "lists", list.id), {
        _updated: serverTimestamp(),
        items: unCheckedItems
      })
    } catch(e) {
      console.error(e)

      toast({
        title: t("lists.[id].components.items.CheckedItems.handleDeleteItems.error.generic.title"),
        description: t("lists.[id].components.items.CheckedItems.handleDeleteItems.error.generic.description"),
        variant: "destructive"
      })
    }
  }

  return (
    <>
      {checkedItems && checkedItems.length > 0 && (
        <Card>
          <CardHeader className="flex flex-row justify-between gap-4 space-y-0 pt-4 pb-2">
            <CardTitle className="text-base text-muted-foreground">{t("lists.[id].components.items.CheckedItems.title")}</CardTitle>
            <Button
              type="button"
              size="sm"
              variant="accent"
              onClick={handleDeleteItems}
            >
              {t("lists.[id].components.items.CheckedItems.actions.deleteCheckedItems")}
            </Button>
          </CardHeader>
          <CardContent className="px-2 pb-4">
            <ul className="flex flex-col gap-2">
              {checkedItems.map((item) => (
                <li key={item.id}>
                  <Item item={item} handleItemCheck={handleItemCheck} handleItemClick={handleItemClick} />
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
    </>
  )
}