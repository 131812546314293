import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";

import { ChevronRight } from "lucide-react";

import Facebook from "@/assets/images/logos/facebook.png"
import Instagram from "@/assets/images/logos/instagram.svg"
import Tiktok from "@/assets/images/logos/tiktok.svg"
import TiktokDark from "@/assets/images/logos/tiktok-dark.svg"
import Youtube from "@/assets/images/logos/youtube.png"
import LinkedIn from "@/assets/images/logos/linkedin.png"

export default function FollowUs() {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("account.components.sections.followUs.title")}
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col px-2">
        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://www.facebook.com/flavorishai/"
            target="_blank"
          >
            <span className="flex gap-2">
              <div className="flex justify-center items-center w-5 h-5">
                <img src={Facebook} alt="Facebook" className="max-w-full max-h-full" />
              </div>
              {t("account.components.sections.followUs.facebook.label")}
            </span>
            <ChevronRight size={20} />
          </Link>
        </Button>

        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://www.instagram.com/flavorish.ai/"
            target="_blank"
          >
            <span className="flex gap-2">
              <div className="flex justify-center items-center w-5 h-5">
                <img src={Instagram} alt="Instagram" className="max-w-full max-h-full" />
              </div>
              {t("account.components.sections.followUs.instagram.label")}
            </span>
            <ChevronRight size={20} />
          </Link>
        </Button>

        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://www.tiktok.com/@flavorish.ai"
            target="_blank"
          >
            <span className="flex gap-2">
              <div className="flex justify-center items-center w-5 h-5">
                <img src={Tiktok} alt="Tiktok" className="dark:hidden max-w-full max-h-full" />
                <img src={TiktokDark} alt="Tiktok" className="hidden dark:block max-w-full max-h-full" />
              </div>
              {t("account.components.sections.followUs.tiktok.label")}
            </span>
            <ChevronRight size={20} />
          </Link>
        </Button>

        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://www.youtube.com/channel/UCvkRHTa1Eetd1GbuLDxnxqw"
            target="_blank"
          >
            <span className="flex gap-2">
              <div className="flex justify-center items-center w-5 h-5">
                <img src={Youtube} alt="Youtube" className="max-w-full max-h-full" />
              </div>
              {t("account.components.sections.followUs.youtube.label")}
            </span>
            <ChevronRight size={20} />
          </Link>
        </Button>

        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://ca.linkedin.com/company/flavorish"
            target="_blank"
          >
            <span className="flex gap-2">
              <div className="flex justify-center items-center w-5 h-5">
                <img src={LinkedIn} alt="LinkedIn" className="max-w-full max-h-full" />
              </div>
              {t("account.components.sections.followUs.linkedIn.label")}
            </span>
            <ChevronRight size={20} />
          </Link>
        </Button>
      </CardContent>
    </Card>
  )
}