import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

import { auth } from "@/firebase/app.ts"
import { ProviderId } from "firebase/auth"
import { signOut, User } from "firebase/auth"

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { ChevronRight, LogOut } from "lucide-react";

type Props = {
  user: User | null | undefined
}
export default function AccountInformation({ user }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [passwordProviderExists, setPasswordProviderExists] = useState(false)

  // Hide change email & password options if user doesn't have password provider
  useEffect(() => {
    if(user && user.providerData) {
      user.providerData.forEach(provider => {
        if(provider.providerId === ProviderId.PASSWORD) {
          setPasswordProviderExists(true)
        }
      })
    }
  }, [user])

  function signOutUser() {
    signOut(auth).then(() => {
      // If native app, logout of the native layer too
      if(Capacitor.isNativePlatform()) {
        FirebaseAuthentication.signOut()
          .finally(() => {
            // Redirect user to login page no matter what
            navigate("/login")
          })
      }
    }).catch((e) => {
      console.error(e)

      toast({
        title: t("account.components.sections.account.logout.onClick.error.generic.title"),
        description: t("account.components.sections.account.logout.onClick.error.generic.description"),
        variant: "destructive"
      })
    })
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("account.components.sections.account.title")}
        </CardTitle>

        {user?.email && (
          <CardDescription className="break-all">
            {user.email}
          </CardDescription>
        )}
      </CardHeader>

      <CardContent className="flex flex-col px-2">
        {passwordProviderExists && (
          <>
            <Button variant="ghost" asChild className="justify-between">
              <Link
                to="/account/change-email"
              >
                {t("account.components.sections.account.changeEmail.label")}
                <ChevronRight size={20} />
              </Link>
            </Button>

            <Button variant="ghost" asChild className="justify-between">
              <Link
                to="/account/change-password"
              >
                {t("account.components.sections.account.changePassword.label")}
                <ChevronRight size={20} />
              </Link>
            </Button>
          </>
        )}

        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="/account/delete-account"
          >
            {t("account.components.sections.account.deleteAccount.label")}
            <ChevronRight size={20} />
          </Link>
        </Button>

        <div className="px-4 py-2">
          <Separator />
        </div>

        <Button variant="ghost" className="justify-between" onClick={signOutUser}>
          {t("account.components.sections.account.logout.button")}
          <LogOut size={20} />
        </Button>
      </CardContent>
    </Card>
  )
}