import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";

import Page from "@/components/page/Page.tsx";

import App from "./components/App.tsx"
import Web from "./components/Web.tsx"

export default function Upgrade() {
  const { t } = useTranslation()

  return (
    // Hide the bottom nav on this page
    <Page className="relative z-50 !pb-0">
      <Helmet>
        <title>{t("subscription.upgrade.page.head.title")}</title>
      </Helmet>

      {Capacitor.isNativePlatform() ? (
        <App />
      ) : (
        <Web />
      )}
    </Page>
  )
}