import { Helmet } from "react-helmet-async"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

import { auth, analytics } from "@/firebase/app.ts"
import { AuthErrorCodes, createUserWithEmailAndPassword } from "firebase/auth"
import { logEvent } from "firebase/analytics"

import Page from "@/components/page/Page.tsx";

import LogoImg from "@/assets/icon.svg"

import SocialLogins from "@/routes/(auth)/components/SocialLogins.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { Loader2 } from "lucide-react";

import { FIELD_LIMITS } from "@/globals.js";

import { UserAccount } from "@/firebase/types"

export default function SignUp() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const formSchema = z.object({
    email: UserAccount.shape.email
      .min(1, {
        message: t("(auth).signup.page.card.form.fields.email.validation.required")
      })
      .email({
        message: t("(auth).signup.page.card.form.fields.email.validation.pattern")
      }),

    password: UserAccount.shape.password
      .min(FIELD_LIMITS.USER_ACCOUNT.PASSWORD.MIN_LENGTH, {
        message: t("(auth).signup.page.card.form.fields.password.validation.minLength")
      })
      .max(FIELD_LIMITS.USER_ACCOUNT.PASSWORD.MAX_LENGTH, {
        message: t("(auth).signup.page.card.form.fields.password.validation.maxLength")
      })
      .refine((val) => /\d/.test(val) && /[A-Z]/.test(val), {
        message: t("(auth).signup.page.card.form.fields.password.validation.passwordRules")
      }),

    confirmPassword: UserAccount.shape.password
      .min(1, {
        message: t("(auth).signup.page.card.form.fields.confirmPassword.validation.required")
      }),

    agreeToTerms: z.boolean()
      .refine((val) => val, {
        message: t("(auth).signup.page.card.form.fields.agreeToTerms.validation.required")
      })
  }).refine((data) => data.password === data.confirmPassword, {
    message: t("(auth).signup.page.card.form.fields.confirmPassword.validation.match"),
    path: ["confirmPassword"]
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      agreeToTerms: false
    }
  })

  function goToNextPage() {
    const redirectTo = searchParams.get('redirectTo')

    if(redirectTo) {
      navigate(redirectTo)
    } else {
      navigate("/")
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { email, password } = values

    try {
      logEvent(analytics, 'sign_up', {
        method: 'email',
      })

      await createUserWithEmailAndPassword(auth, email, password)

      // Log the user in on the native layer
      if(Capacitor.isNativePlatform()) {
        await FirebaseAuthentication.signInWithEmailAndPassword({ email, password })
      }

      goToNextPage()
    } catch(e: any) {
      console.error(e)

      // Auth error codes: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if(e.code === AuthErrorCodes.EMAIL_EXISTS) {
        form.setError("email", {
          type: e.code,
          message: `${t("(auth).signup.page.card.form.onSubmit.error.emailExists")}`
        })
      } else if(e.code === AuthErrorCodes.INVALID_EMAIL) {
        form.setError("email", {
          type: e.code,
          message: `${t("(auth).signup.page.card.form.onSubmit.error.invalidEmail")}`
        })
      } else if(e.code === AuthErrorCodes.WEAK_PASSWORD) {
        form.setError("password", {
          type: e.code,
          message: `${t("(auth).signup.page.card.form.onSubmit.error.weakPassword")}`
        })
      } else {
        form.setError("root", {
          type: e.code,
          message: `${t("(auth).signup.page.card.form.onSubmit.error.generic")}`
        })
      }
    }
  }

  return (
    <Page className="justify-center py-4">
      <Helmet>
        <title>{t("(auth).signup.page.head.title")}</title>
      </Helmet>

      <div className="m-4">
        <div className="flex justify-center items-center my-4">
          <img src={LogoImg} alt="Flavorish" className="w-12 mr-4" />
          <p className="font-poppins text-4xl text-base-content dark:text-white">Flavorish</p>
        </div>

        <Card className="max-w-lg mx-auto">
          <CardHeader className="space-y-1">
            <CardTitle className="text-2xl">{t("(auth).signup.page.card.title")}</CardTitle>
          </CardHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <CardContent className="flex flex-col gap-4 pb-2">
                <SocialLogins callback={() => goToNextPage()} />

                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      {t("(auth).signup.page.card.separator")}
                    </span>
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("(auth).signup.page.card.form.fields.email.label")}</FormLabel>
                      <FormControl>
                        <Input type="email" placeholder={t("(auth).signup.page.card.form.fields.email.placeholder")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("(auth).signup.page.card.form.fields.password.label")}</FormLabel>
                      <FormControl>
                        <Input type="password" placeholder={t("(auth).signup.page.card.form.fields.password.placeholder")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("(auth).signup.page.card.form.fields.confirmPassword.label")}</FormLabel>
                      <FormControl>
                        <Input type="password" placeholder={t("(auth).signup.page.card.form.fields.confirmPassword.placeholder")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="agreeToTerms"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex flex-row items-start space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel>
                          <Trans i18nKey="(auth).signup.page.card.form.fields.agreeToTerms.label">
                            By signing up you agree to the
                            <Link to="https://www.flavorish.ai/terms-of-use" target="_blank" className="link">
                              terms of use
                            </Link>
                            and
                            <Link to="https://www.flavorish.ai/privacy-policy" target="_blank" className="link">
                              privacy policy
                            </Link>
                            .
                          </Trans>
                        </FormLabel>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>

              <CardFooter className="flex-col gap-4">
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full"
                  disabled={form.formState.isSubmitting}
                >
                  {form.formState.isSubmitting ? (
                    <Loader2 size={24} className="animate-spin" />
                  ) : t("(auth).signup.page.card.form.actions.submit")}
                </Button>

                <FormRootErrorMessage />

                <p>
                  <Trans i18nKey="(auth).signup.page.card.form.actions.alreadyHaveAnAccount">
                    Already have an account? <Link to="/login" className="link">Sign in</Link>
                  </Trans>
                </p>

              </CardFooter>
            </form>
          </Form>
        </Card>
      </div>
    </Page>
  )
}