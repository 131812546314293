import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"

import { auth, db } from "@/firebase/app"
import { getDocs, collection, limit, orderBy, query, where, startAfter } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Loading from "@/components/page/Loading.tsx";
import ErrorLoading from "@/components/page/ErrorLoading.tsx";

import CollectionsHeader from "@/routes/collections/components/general/CollectionsHeader.tsx";
import NoCollections from "@/routes/collections/components/general/NoCollections.tsx";
import AddCollection from "@/routes/collections/components/AddCollection.tsx";

import InfiniteScroll from "@/components/ui/expansions/infinite-scroll.tsx";
import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";

import { Image, Loader2 } from "lucide-react";

import { Collection } from "@/firebase/types"

const PAGE_LIMIT = 10

export default function CollectionsPage() {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [collectionsData, loading, error] = useCollectionDataOnce(
    query(
      collection(db, "collections"),
      where("userId", "==", user?.uid),
      orderBy("_created", "desc"),
      limit(PAGE_LIMIT)
    )
  )

  const [collections, setCollections] = useState<Collection[] | null>(null)

  const [canFetchMore, setCanFetchMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    if(collectionsData && !loading && !error) {
      setCollections(collectionsData as Collection[])

      if(collectionsData.length >= PAGE_LIMIT) {
        setCanFetchMore(true)
      }
    }
  }, [collectionsData])

  async function next() {
    if(collections) {
      setLoadingMore(true)

      const querySnapshot = await getDocs(query(
        collection(db, "collections"),
        where("userId", "==", user?.uid),
        orderBy("_created", "desc"),
        startAfter(collections[collections.length - 1]._created),
        limit(PAGE_LIMIT)
      ))

      const newCollections: Collection[] = []
      querySnapshot.forEach(doc => {
        newCollections.push(doc.data() as Collection)
      })

      setCollections([...collections, ...newCollections])

      if(newCollections.length < PAGE_LIMIT) {
        setCanFetchMore(false)
      }

      setLoadingMore(false)
    }
  }

  // Second param in this condition fixes an issue where on initial load, there's a moment after the collectionsData has finished loading (loading = false)
  // but the useEffect hasn't set the collections state variable yet, leaving the page to render the NoCollections component
  if(loading || (!collections && !loading && collectionsData)) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <div className="flex flex-col flex-grow">
      <Helmet>
        <title>{t("collections.page.head.title")}</title>
      </Helmet>

      <CollectionsHeader />

      {collections && collections?.length > 0 ? (
        <div className="w-full max-w-4xl mx-auto mb-20 px-4">
          <div className="grid lg:grid-cols-2 gap-4">
            {collections?.map((collection) => {
              const recipeImages = [...collection.recipeImages].reverse()

              return (
                <Link
                  key={collection.id}
                  to={`/collections/${collection.id}`}
                  className="group"
                >
                  <h3 className="text-lg font-semibold group-hover:underline">{collection.title}</h3>

                  <p className="text-sm text-muted-foreground mb-2">
                    {t("collections.page.numRecipes", { count: collection.recipeIds.length })}
                  </p>

                  <div className="grid grid-cols-2 gap-2">
                    <AspectRatio ratio={1} className="bg-muted rounded-md overflow-hidden">
                      {recipeImages[0] ? (
                        <img src={recipeImages[0]} alt="" className="w-full h-full object-cover transition-all group-hover:scale-105" />
                      ) : (
                        <div className="flex justify-center items-center w-full h-full">
                          <Image size={36} className="text-muted-foreground" />
                        </div>
                      )}
                    </AspectRatio>

                    <div className="grid gap-2">
                      <AspectRatio ratio={2} className="bg-muted rounded-md overflow-hidden">
                        {recipeImages[1] ? (
                          <img src={recipeImages[1]} alt="" className="w-full h-full object-cover transition-all group-hover:scale-105" />
                        ) : (
                          <div className="flex justify-center items-center w-full h-full">
                            <Image size={36} className="text-muted-foreground" />
                          </div>
                        )}
                      </AspectRatio>
                      <AspectRatio ratio={2} className="bg-muted rounded-md overflow-hidden">
                        {recipeImages[2] ? (
                          <img src={recipeImages[2]} alt="" className="w-full h-full object-cover transition-all group-hover:scale-105" />
                        ) : (
                          <div className="flex justify-center items-center w-full h-full">
                            <Image size={36} className="text-muted-foreground" />
                          </div>
                        )}
                      </AspectRatio>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>

          <InfiniteScroll hasMore={canFetchMore} isLoading={loadingMore} next={next} threshold={1}>
            {canFetchMore && (
              <div className="flex justify-center items-center my-16">
                <Loader2 size={48} className="animate-spin" />
              </div>
            )}
          </InfiniteScroll>
        </div>
      ) : (
        <NoCollections />
      )}

      {isMobile && (
        <AddCollection />
      )}
    </div>
  )
}