import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SearchResponseHit } from "typesense/src/Typesense/Documents.ts";

import EditItemAutocomplete from "@/routes/lists/[id]/components/EditItemAutocomplete.tsx";

import { Button } from "@/components/ui/button.tsx";
import { DrawerClose, DrawerFooter } from "@/components/ui/drawer.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";

import { Loader2 } from "lucide-react";

import { Ingredient } from "@/firebase/types.ts";

type Props = {
  form: UseFormReturn<{ itemName: string, notes: string }, any, undefined>
  onSubmit: (values: { itemName: string, notes: string }) => Promise<void>
  hits: SearchResponseHit<Ingredient>[] | undefined
  setHits: (hits: SearchResponseHit<Ingredient>[] | undefined) => void
  showAutocomplete: boolean
  setShowAutocomplete: (showAutocomplete: boolean) => void
}
export default function EditItemForm({ form, onSubmit, hits, setHits, showAutocomplete, setShowAutocomplete }: Props) {
  const { t } = useTranslation()

  const watchItemName = form.watch("itemName")

  // Called when user clicks on autocomplete result
  function updateItemName(name: string) {
    form.setValue('itemName', name)
    setShowAutocomplete(false)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="itemName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("lists.[id].components.EditItem.modal.form.fields.name.label")}</FormLabel>
              <FormControl>
                <div className="relative">
                  <Input
                    placeholder={t("lists.[id].components.EditItem.modal.form.fields.name.placeholder")}
                    {...field}
                    // When the input is focused and dropdown is shown, if user presses escape, close the dropdown instead of closing the modal
                    onChangeCapture={(e) => {
                      // @ts-ignore it does exist actually
                      if(e.target.value === '') {
                        setShowAutocomplete(false)
                      } else {
                        setShowAutocomplete(true)
                      }
                    }}
                    onBlur={() => setShowAutocomplete(false)}
                  />

                  {showAutocomplete && (
                    <EditItemAutocomplete
                      hits={hits}
                      setHits={setHits}
                      query={watchItemName}
                      updateItemName={updateItemName}
                    />
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("lists.[id].components.EditItem.modal.form.fields.notes.label")}</FormLabel>
              <FormControl>
                <Textarea placeholder={t("lists.[id].components.EditItem.modal.form.fields.notes.placeholder")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <DrawerFooter>
          <Button
            type="submit"
            variant="primary"
            className="w-full"
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? (
              <Loader2 size={24} className="animate-spin" />
            ) : t("lists.[id].components.EditItem.modal.form.actions.update")}
          </Button>

          <DrawerClose asChild>
            <Button variant="outline">{t("lists.[id].components.EditItem.modal.form.actions.cancel")}</Button>
          </DrawerClose>
        </DrawerFooter>

        <FormRootErrorMessage />
      </form>
    </Form>
  )
}