import { useTranslation } from "react-i18next"
import AddToCartImage from "@/assets/images/Add to Cart 4.svg";

export default function EmptyList() {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col flex-grow justify-center items-center mb-20">
      <img src={AddToCartImage} alt={t("lists.[id].components.general.EmptyList.image.alt")} className="w-72 lg:w-auto lg:max-w-xs" />
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-2">
          {t("lists.[id].components.general.EmptyList.title")}
        </h2>
      </div>
    </div>
  )
}