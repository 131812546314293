// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, indexedDBLocalPersistence, initializeAuth, connectAuthEmulator } from "firebase/auth";
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager, connectFirestoreEmulator } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import { Capacitor } from '@capacitor/core';

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
// Make sure we only initialize 1 app
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()

// Initialize Firebase Authentication and get a reference to the service
const getFirebaseAuth = () => {
  if(Capacitor.isNativePlatform()) {
    return initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    return getAuth(app);
  }
};

export const auth = getFirebaseAuth()

// Use the Firebase Authentication Emulator
// connectAuthEmulator(auth, 'http://localhost:9099');

// Initialize Cloud Firestore and get a reference to the service
export const db = initializeFirestore(app,
  {
    localCache:
      persistentLocalCache({ tabManager: persistentMultipleTabManager() })
  })

// Currently disabling App Check on Android - it's just too damn difficult to get it working. Maybe Apple will work better
if(!Capacitor.isNativePlatform()) {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_FIREBASE_APP_CHECK_RECAPTCHA_ENTERPRISE_SITE_KEY),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });
}

// Use the Firestore Emulator
// connectFirestoreEmulator(db, 'localhost', 8080);

// Have to set region manually, this means a different functions variable is needed if functions are created in a different region
export const functions = getFunctions(app, 'us-east1')
// Firebase Functions Emulator
// connectFunctionsEmulator(functions, 'localhost', 5001);

// Get a reference to the storage service, which is used to create references in your storage bucket
export const storage = getStorage(app)

export const analytics = getAnalytics(app)