import { db } from "@/firebase/app.ts";
import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import RecipeCard from "@/routes/recipes/components/RecipeCard.tsx";

import { Recipe } from "@/firebase/types";

type Props = {
  id: Recipe["id"]
  selectedRecipes: Recipe[],
  setSelectedRecipes: (selectedRecipes: Recipe[]) => void,
}
export default function AddRecipeHit({ id, selectedRecipes, setSelectedRecipes }: Props) {
  const [recipeData, loading, error, snapshot] = useDocumentDataOnce(
    doc(db, "recipes", id)
  )
  const recipe = recipeData as Recipe | undefined

  const isSelected = selectedRecipes.find(recipe => recipe.id === id)

  return (
    <button
      type="button"
      className="grid text-left"
      onClick={(e) => {
        e.preventDefault()

        if(recipe) {
          if(isSelected) {
            let selectedRecipesCopy = [...selectedRecipes]
            selectedRecipesCopy = selectedRecipesCopy.filter(recipe => recipe.id !== id)
            setSelectedRecipes(selectedRecipesCopy)
          } else {
            setSelectedRecipes([...selectedRecipes, recipe])
          }
        }
      }}
    >
      <RecipeCard recipe={recipe} isLoading={loading} disableLink={true} isSelected={!!(isSelected)} />
    </button>
  )
}