import { useEffect } from "react";

import Typesense from "typesense";
import { SearchResponseHit } from "typesense/src/Typesense/Documents.ts";
import { SearchResponse } from "typesense/lib/Typesense/Documents";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";

import { Ingredient } from "@/firebase/types.ts";

let client = new Typesense.Client({
  "nodes": [{
    host: import.meta.env.VITE_TYPESENSE_HOST,
    port: import.meta.env.VITE_TYPESENSE_PORT,
    protocol: import.meta.env.VITE_TYPESENSE_PROTOCOL
  }],
  "apiKey": import.meta.env.VITE_TYPESENSE_SEARCH_INGREDIENTS_API_KEY,
  "connectionTimeoutSeconds": 2
})

type Props = {
  hits: SearchResponseHit<Ingredient>[] | undefined
  setHits: (hits: SearchResponseHit<Ingredient>[] | undefined) => void
  query: string
  updateItemName: (name: string) => void
}

{/* TODO: Nice to have the up/down keys focus the dropdown items */}
export default function EditItemAutocomplete({ hits, setHits, query, updateItemName }: Props) {
  useEffect(() => {
    searchIngredients(query)
  }, [query])

  function searchIngredients(query: string) {
    const searchParameters = {
      "q": query,
      "query_by": "name",
      "per_page": 6,
      "prioritize_token_position": true
    }

    client.collections("ingredients")
      .documents()
      .search(searchParameters)
      // @ts-ignore
      .then(function(searchResults: SearchResponse<Ingredient>) {
        setHits(searchResults.hits)
      })
  }

  return (
    <>
      {hits && hits.length > 0 && (
        <Card className="absolute top-full left-0 right-0 w-full max-h-64 overflow-auto">
          <CardContent className="p-2">
            <ul className="flex flex-col gap-2">
              {hits.map((hit) => (
                <li key={hit.document.id}>
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    className="w-full justify-between gap-2"
                    // Using onMouseDown instead of onClick here because the onBlur of the text input runs
                    // before the onClick here and causes the onClick to never run
                    onMouseDown={() => updateItemName(hit.document.name)}
                  >
                    {hit.highlight.name?.snippet ? (
                      <span dangerouslySetInnerHTML={{ __html: `${hit.highlight.name.snippet}` }} className="truncate"></span>
                    ) : (
                      <span className="truncate">{hit.document.name}</span>
                    )}
                  </Button>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
    </>
  )
}