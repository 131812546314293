import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { db } from "@/firebase/app.ts";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { ListSortNames } from "@/firebase/constants.ts";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { Button, ButtonProps } from "@/components/ui/button.tsx";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import {
  DropdownMenu,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { BookMarked, ChevronDown, ChevronsUpDown, ShoppingBasket } from "lucide-react";

import { List } from "@/firebase/types.ts";

type Props = {
  list: List
}
export default function SortOptions({ list }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { toast } = useToast()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const TriggerButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ ...props }, ref) => (
    <Button
      type="button"
      variant="ghost"
      size="sm"
      className="gap-2"
      ref={ref}
      {...props}
    >
      {list.sort === ListSortNames.Aisle && t('lists.[id].components.general.SortOptions.options.aisle')}
      {list.sort === ListSortNames.Recipe && t('lists.[id].components.general.SortOptions.options.recipe')}
      {list.sort === ListSortNames.Custom && t('lists.[id].components.general.SortOptions.options.custom')}
      <ChevronDown size={16} />
    </Button>
  ))

  async function updateListSortMethod(sortMethod: ListSortNames) {
    try {
      if(list) {
        await updateDoc(doc(db, "lists", list.id), {
          _updated: serverTimestamp(),
          sort: sortMethod
        })
      }
    } catch(e) {
      console.error(e)

      toast({
        title: t('lists.[id].components.general.SortOptions.updateListSortMethod.error.generic.title'),
        description: t('lists.[id].components.general.SortOptions.updateListSortMethod.error.generic.description'),
        variant: "destructive"
      })
    }

    setDrawerOpen(false)
  }

  return (
    <>
      {isMobile ? (
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          <DrawerTrigger asChild>
            <TriggerButton />
          </DrawerTrigger>

          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>
                {t('lists.[id].components.general.SortOptions.menuTitle')}
              </DrawerTitle>
            </DrawerHeader>

            <RadioGroup value={list.sort} onValueChange={updateListSortMethod} className="flex flex-col gap-4 mx-auto w-full py-4">
              <div className="flex items-center gap-4 w-full">
                <RadioGroupItem value={ListSortNames.Aisle} id={ListSortNames.Aisle} />
                <Label htmlFor={ListSortNames.Aisle} className="flex items-center gap-2 w-full text-base py-2">
                  <ShoppingBasket size={20} />
                  {t('lists.[id].components.general.SortOptions.options.aisle')}
                </Label>
              </div>

              <div className="flex items-center gap-4 w-full">
                <RadioGroupItem value={ListSortNames.Recipe} id={ListSortNames.Recipe} />
                <Label htmlFor={ListSortNames.Recipe} className="flex items-center gap-2 w-full text-base py-2">
                  <BookMarked size={20} />
                  {t('lists.[id].components.general.SortOptions.options.recipe')}
                </Label>
              </div>

              <div className="flex items-center gap-4 w-full">
                <RadioGroupItem value={ListSortNames.Custom} id={ListSortNames.Custom} />
                <Label htmlFor={ListSortNames.Custom} className="flex items-center gap-2 w-full text-base py-2">
                  <ChevronsUpDown size={20} />
                  {t('lists.[id].components.general.SortOptions.options.custom')}
                </Label>
              </div>
            </RadioGroup>
          </DrawerContent>
        </Drawer>
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <TriggerButton />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              {t('lists.[id].components.general.SortOptions.menuTitle')}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />

            {/* @ts-ignore it's the right type, trust me */}
            <DropdownMenuRadioGroup value={list.sort} onValueChange={updateListSortMethod}>
              <DropdownMenuRadioItem value={ListSortNames.Aisle} className="gap-2">
                <ShoppingBasket size={16} />
                {t('lists.[id].components.general.SortOptions.options.aisle')}
              </DropdownMenuRadioItem>

              <DropdownMenuRadioItem value={ListSortNames.Recipe} className="gap-2">
                <BookMarked size={16} />
                {t('lists.[id].components.general.SortOptions.options.recipe')}
              </DropdownMenuRadioItem>

              <DropdownMenuRadioItem value={ListSortNames.Custom} className="gap-2">
                <ChevronsUpDown size={16} />
                {t('lists.[id].components.general.SortOptions.options.custom')}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}