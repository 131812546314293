import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { App } from '@capacitor/app'
import { Capacitor } from "@capacitor/core";

export default function CapacitorHandler() {
  const navigate = useNavigate()
  const location = useLocation()
  const locationKey = useRef(location.key)

  // Event listeners don't have access to the latest state (location), so keep a ref updated here so we can reference it in the event listener
  useEffect(() => {
    if(location.key !== locationKey.current) {
      locationKey.current = location.key
    }
  }, [location])

  useEffect(() => {
    (async () => {
      if(Capacitor.isNativePlatform()) {
        const backButtonListener = await App.addListener('backButton', async () => {
          // If there isn't another page to go back to, close the app instead
          if(locationKey.current === 'default') {
            await App.exitApp()
          } else {
            navigate(-1)
          }
        })

        return async () => {
          await backButtonListener.remove()
        }
      }
    })()
  }, [])

  return null
}