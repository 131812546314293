import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { Button, ButtonProps } from "@/components/ui/button.tsx";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu.tsx";

import { Ellipsis, Pencil, Trash2 } from "lucide-react";

import { List } from "@/firebase/types.ts";

type Props = {
  list: List
  setShowRenameListModal: Dispatch<SetStateAction<boolean>>
  setCurrentRenamingList?: (list: List) => void
  setShowDeleteListModal: Dispatch<SetStateAction<boolean>>
  setCurrentDeletingList?: (list: List) => void
  buttonClass?: string
}
export default function ListOptions({ list, setShowRenameListModal, setCurrentRenamingList, setShowDeleteListModal, setCurrentDeletingList, buttonClass }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

  const TriggerButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ ...props }, ref) => (
    <Button
      type="button"
      variant="ghost"
      size="icon"
      className={buttonClass}
      ref={ref}
      {...props}
    >
      <Ellipsis size={20} />
    </Button>
  ))

  const RenameListButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={e => {
        e.preventDefault()
        if(setCurrentRenamingList) {
          setCurrentRenamingList(list)
        }
        setShowRenameListModal(true)

        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <Pencil size={isMobile ? 20 : 16} />
      {t("lists.components.ListOptions.actions.rename")}
    </Button>
  )

  const DeleteListButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={e => {
        e.preventDefault()
        if(setCurrentDeletingList) {
          setCurrentDeletingList(list)
        }
        setShowDeleteListModal(true)

        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <Trash2 size={isMobile ? 20 : 16} />
      {t("lists.components.ListOptions.actions.delete")}
    </Button>
  )

  return (
    <>
      {isMobile ? (
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          <DrawerTrigger asChild>
            <TriggerButton />
          </DrawerTrigger>

          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>
                {t("lists.components.ListOptions.menuTitle")}
              </DrawerTitle>
            </DrawerHeader>

            <div className="flex flex-col gap-2 mx-auto w-full py-4">
              <RenameListButton />
              <DeleteListButton />
            </div>
          </DrawerContent>
        </Drawer>
      ) : (
        <DropdownMenu open={dropdownMenuOpen} onOpenChange={setDropdownMenuOpen}>
          <DropdownMenuTrigger asChild>
            <TriggerButton />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              {t("lists.components.ListOptions.menuTitle")}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              <DropdownMenuItem className="p-0">
                <RenameListButton />
              </DropdownMenuItem>

              <DropdownMenuItem className="p-0">
                <DeleteListButton />
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}