import { Trans, useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import Instagram from "@/assets/images/logos/instagram.svg";
import androidIG1 from "@/assets/images/import-social-media-guides/android/IG 1.gif"
import androidIG2 from "@/assets/images/import-social-media-guides/android/IG 2.gif"
import androidIG3 from "@/assets/images/import-social-media-guides/android/IG 3.gif"
import iosIG1 from "@/assets/images/import-social-media-guides/ios/IG 1.gif"
import iosIG2 from "@/assets/images/import-social-media-guides/ios/IG 2.gif"
import iosIG3 from "@/assets/images/import-social-media-guides/ios/IG 3.gif"

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

export default function ImportInstagramGuide({ isOpen = false, setIsOpen }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-h-dvh overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <img src={Instagram} alt="Instagram" className="max-w-5 max-h-5" />
            {t("recipes.importSocialMedia.components.ImportInstagramGuide.title")}
          </DialogTitle>
        </DialogHeader>

        <div className="mt-4 space-y-8">
          {Capacitor.getPlatform() === 'android' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.android.step1.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.android.step1.text")}</p>
                <img src={androidIG1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.android.step2.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.android.step2.text")}</p>
                <img src={androidIG2} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.android.step3.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.android.step3.text")}</p>
                <img src={androidIG3} alt="" className="my-4" />
              </div>

              <p className="text-sm">
                <Trans i18nKey="recipes.importSocialMedia.components.ImportInstagramGuide.content.android.note">
                  <strong>Note:</strong> We can only create exact recipe matches from public Instagram posts that include the full recipe in the description. If the description doesn't have a complete recipe, our AI will generate a recipe based on available information such as dish name and hashtags.
                </Trans>
              </p>
            </>
          )}

          {Capacitor.getPlatform() === 'ios' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.ios.step1.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.ios.step1.text")}</p>
                <img src={iosIG1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.ios.step2.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.ios.step2.text")}</p>
                <img src={iosIG2} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.ios.step3.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportInstagramGuide.content.ios.step3.text")}</p>
                <img src={iosIG3} alt="" className="my-4" />
              </div>

              <p className="text-sm">
                <Trans i18nKey="recipes.importSocialMedia.components.ImportInstagramGuide.content.ios.note">
                  <strong>Note:</strong> We can only create exact recipe matches from public Instagram posts that include the full recipe in the description. If the description doesn't have a complete recipe, our AI will generate a recipe based on available information such as dish name and hashtags.
                </Trans>
              </p>
            </>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="primary">
              {t("recipes.importSocialMedia.components.ImportInstagramGuide.actions.close")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}