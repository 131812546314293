import React, { useEffect, useState } from "react"

import { doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/app"

import { DragDropContext, Droppable, DropResult, ResponderProvided } from "@hello-pangea/dnd"

import DraggableItem from "./DraggableItem"

import { List } from "@/firebase/types"

type Props = {
  list: List
  handleItemClick: (item: List["items"][0]) => void
  handleItemCheck: (itemID: List["items"][0]["id"]) => void
}
export default function CustomSortItems({ list, handleItemClick, handleItemCheck }: Props) {
  // Separate items from the firebase ones. When clicking and dragging items to resort them, we want to update items locally immediately so there's no delay;
  // Otherwise we have to wait for the firebase write to complete and update the snapshot listener which takes time and messes up the animation
  const [localItems, setLocalItems] = useState<List["items"] | null>(null)
  const unCheckedItems = localItems?.filter(item => !item.checked)
  const checkedItems = localItems?.filter(item => item.checked)

  // Synchronize localItems with firebase items
  useEffect(() => {
    if(list && list.items) {
      setLocalItems(list.items)
    }
  }, [list])

  async function onDragEnd(result: DropResult, provided: ResponderProvided) {
    const { destination, source, draggableId } = result

    if(!destination) {
      return
    }

    if(destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    if(localItems && unCheckedItems && checkedItems && result.destination) {
      const newItems = [...unCheckedItems]

      // Remove item from old index
      const movingItem = newItems.splice(source.index, 1)

      // Insert item in new index
      newItems.splice(destination.index, 0, movingItem[0])

      // Update items locally
      setLocalItems([...newItems, ...checkedItems])

      // Update items on server - takes time
      await updateDoc(doc(db, "lists", list.id), {
        _updated: serverTimestamp(),
        items: [...newItems, ...checkedItems]
      })
    }
  }

  return (
    <>
      {unCheckedItems && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="unchecked-items">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-col gap-2 mb-4"
              >
                {unCheckedItems.map((item, index) => (
                  <DraggableItem
                    key={item.id}
                    item={item}
                    index={index}
                    handleItemClick={handleItemClick}
                    handleItemCheck={handleItemCheck}
                  />
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  )
}