import { cn } from "@/lib/utils.ts";

import { Button, buttonVariants } from "@/components/ui/button.tsx";

import { Check } from "lucide-react";

import { Recipe } from "@/firebase/types"

type Props = {
  instruction: Recipe["instructions"][0],
  step: number
  checkedInstructions: string[]
  setCheckedInstructions: (checkedInstructions: string[]) => void
}
export default function Instruction({ instruction, step, checkedInstructions, setCheckedInstructions }: Props) {
  const checked = checkedInstructions.includes(instruction.id)

  return (
    <Button
      type="button"
      variant="ghost"
      className="flex flex-nowrap justify-start items-start gap-2 w-full h-auto text-base font-normal text-left"
      onClick={() => {
        let newCheckedInstructions = [...checkedInstructions]
        if(checked) {
          newCheckedInstructions = newCheckedInstructions.filter(id => id !== instruction.id)
        } else {
          newCheckedInstructions.push(instruction.id)
        }

        setCheckedInstructions(newCheckedInstructions)
      }}
    >
      {!instruction.isGroupHeader && (
        <div
          className={cn(
            buttonVariants({ variant: "accent", size: "icon" }),
            "flex-shrink-0",
          )}
        >
          {checked ? <Check size={20} /> : step}
        </div>
      )}

      {/* This div aligns single line instructions to the center, matching the button */}
      <div className="flex items-center min-h-10">
        <p className={`block text-base whitespace-normal ${checked ? "!text-muted-foreground line-through" : ""} ${instruction.isGroupHeader ? "font-semibold" : ""}`}>
          {instruction.text}
        </p>
      </div>
    </Button>
  )
}