import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";

import { Image as LuImage } from "lucide-react"

import { Recipe } from "@/firebase/types"

type Props = {
  image?: Recipe["image"]
  alt?: string
}
// TODO: Click to open in lightbox
export default function Image({ image, alt }: Props) {
  return (
    <div className="flex justify-center items-center max-h-[500px] truncate bg-muted lg:rounded-md">
      {image ? (
        <AspectRatio ratio={1} className="h-full">
          <img
            src={image?.url}
            alt={alt}
            className="w-full h-full object-cover"
          />
        </AspectRatio>
      ) : (
        <div className="relative flex justify-center items-center w-full h-96 max-h-96">
          <Skeleton className="absolute top-0 left-0 right-0 bottom-0 w-full h-full" />
          <LuImage size={48} className="text-muted-foreground" />
        </div>
      )}
    </div>
  )
}