import { useState } from "react"

import Header from "@/components/navigation/Header"
import RenameListModal from "@/routes/lists/components/modals/RenameListModal.tsx"
import DeleteListModal from "@/routes/lists/components/modals/DeleteListModal.tsx"
import ListOptions from "@/routes/lists/components/ListOptions.tsx";

import { List } from "@/firebase/types"

type Props = {
  list?: List,
  uncheckedItems?: List["items"]
}

export default function ListHeader({ list, uncheckedItems }: Props) {
  const [showBuyIngredientsModal, setShowBuyIngredientsModal] = useState(false)
  const [showRenameListModal, setShowRenameListModal] = useState(false)
  const [showDeleteListModal, setShowDeleteListModal] = useState(false)

  return (
    <>
      <Header
        goBackURL="/lists"
        options={
          <>
            {/* Removed - we should get paid by Amazon/Instacart for this */}
            {/*<Button*/}
            {/*  type="button"*/}
            {/*  variant="ghost"*/}
            {/*  size="icon"*/}
            {/*  onClick={() => {*/}
            {/*    setShowBuyIngredientsModal(true)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <ShoppingCart size={20} />*/}
            {/*</Button>*/}

            {list && (
              <ListOptions
                list={list}
                setShowRenameListModal={setShowRenameListModal}
                setShowDeleteListModal={setShowDeleteListModal}
              />
            )}
          </>
        }
      />

      {/* Removed - we should get paid by Amazon/Instacart for this */}
      {/*{uncheckedItems && <BuyIngredientsModal isOpen={showBuyIngredientsModal} setIsOpen={setShowBuyIngredientsModal} ingredients={uncheckedItems} />}*/}

      {list && <RenameListModal isOpen={showRenameListModal} setIsOpen={setShowRenameListModal} list={list} />}
      {list && <DeleteListModal isOpen={showDeleteListModal} setIsOpen={setShowDeleteListModal} list={list} />}
    </>
  )
}