import { cn } from "@/lib/utils.ts";

import IngredientText from "@/components/ingredients/IngredientText.tsx"

import { buttonVariants } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";

import { GripVertical } from "lucide-react";

import { List } from "@/firebase/types"

type Props = {
  item: List["items"][0]
  handleItemCheck: (itemID: List["items"][0]["id"]) => void
  handleItemClick: (item: List["items"][0]) => void
  isDraggable?: boolean
  isDragging?: boolean
}

export default function Item({ item, handleItemCheck, handleItemClick, isDraggable = false, isDragging = false }: Props) {
  return (
    <div
      className={cn(`${isDragging ? buttonVariants({ variant: "outline" }) : buttonVariants({ variant: "ghost" })}`,
        `h-auto flex flex-nowrap justify-between items-center gap-2 transition-all cursor-pointer
        ${isDraggable && "pl-0"}
        ${isDragging && "scale-105"}
       `)}
      onClick={() => {
        if(!item.checked) {
          handleItemClick(item)
        }
      }}
    >
      <div className="flex items-center truncate">
        {isDraggable && (
          <div className="mr-2">
            <GripVertical size={20} />
          </div>
        )}

        <div className="truncate">
          <p
            className={`text-base ${item.checked && "line-through"} truncate`}
            onClick={(e) => e.preventDefault()}
          >
            <IngredientText ingredient={item} />
          </p>
          <p className={`text-sm text-muted-foreground ${item.checked && "line-through"} truncate`}>{item.notes}</p>
        </div>
      </div>

      <Checkbox
        id={item.id}
        className="w-5 h-5"
        checked={item.checked}
        onCheckedChange={() => handleItemCheck(item.id)}
        onClick={e => e.stopPropagation()}
      />
    </div>
  )
}