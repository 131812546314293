import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet-async";

import { auth, db } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { uuidv4 } from "@firebase/util";

import { parseIngredient } from "parse-ingredient";

import useUserSubscriptionStatus from "@/hooks/useUserSubscriptionStatus.tsx";
import useStreamGeneratedRecipeFromSocialMedia from "@/hooks/useStreamGeneratedRecipeFromSocialMedia.tsx";

import { GetFavicon } from "@/lib/helpers/favicon.ts";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx";
import ErrorLoading from "@/components/page/ErrorLoading.tsx";

import RecipeLoading from "@/components/recipes/RecipeLoading.tsx";

import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { Recipe } from "@/firebase/types";

// Display skeleton loading while AI recipe is generating & streaming in
// Works very similarly to the ai recipe generation loading page
export default function ImportSocialMediaLoadingPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { toast } = useToast()

  const [user, loading, error] = useAuthState(auth)

  const { isUserSubscribed } = useUserSubscriptionStatus()
  const { streamedRecipe, isComplete, recipeError, cannotUsePost } = useStreamGeneratedRecipeFromSocialMedia(state?.fields, isUserSubscribed)

  useEffect(() => {
    if(isComplete) {
      if(cannotUsePost) {
        toast({
          variant: "destructive",
          title: t("recipes.importSocialMedia.loading.page.cannotUsePostError.title"),
          description: t("recipes.importSocialMedia.loading.page.cannotUsePostError.description"),
        })

        navigate('/recipes')
      } else if(recipeError) {
        toast({
          variant: "destructive",
          duration: 3600000, // 1 hour
          title: t("recipes.importSocialMedia.loading.page.recipeImportedError.title"),
          description: t("recipes.importSocialMedia.loading.page.recipeImportedError.description"),
          action: (
            <Button
              type="button"
              onClick={() => window.location.reload()}
            >
              {t("recipes.importSocialMedia.loading.page.recipeImportedError.action")}
            </Button>
          )
        })
      } else {
        saveRecipe()
      }
    }
  }, [isComplete])

  async function saveRecipe() {
    if(user && streamedRecipe && !recipeError) {
      try {
        const newRecipe: Recipe = {
          _created: serverTimestamp(),
          _updated: serverTimestamp(),
          userId: user.uid,
          ...streamedRecipe,
          source: {
            url: state.fields.url,
          },
          imported: false,
          aiGenerated: false,
          imageImported: false,
          socialMediaImported: true
        }

        const favicon = GetFavicon(state.fields.url)
        if(favicon) {
          newRecipe.source!.favicon = favicon
        }

        newRecipe.ingredients.map((ingredient, k) => {
          const parsedIngredient = parseIngredient(ingredient.name)
          newRecipe.ingredients[k] = {
            id: uuidv4(),
            name: ingredient.name,
            ...parsedIngredient[0]
          }
        })

        newRecipe.instructions.map((instruction, k) => {
          newRecipe.instructions[k] = {
            id: uuidv4(),
            isGroupHeader: false,
            text: instruction.text
          }
        })

        await setDoc(doc(db, "recipes", newRecipe.id), newRecipe)

        toast({
          variant: "success",
          title: t("recipes.importSocialMedia.loading.page.recipeSaved.title")
        })

        // Empty state so user can't come back from the next page and have a recipe start generating again
        window.history.replaceState({}, '')

        navigate(`/recipes/${newRecipe.id}`)
      } catch(e) {
        console.error(e)

        toast({
          variant: "destructive",
          duration: 3600000, // 1 hour
          title: t("recipes.importSocialMedia.loading.page.recipeSavedError.title"),
          description: t("recipes.importSocialMedia.loading.page.recipeSavedError.description"),
          action: (
            <Button
              type="button"
              onClick={() => window.location.reload()}
            >
              {t("recipes.importSocialMedia.loading.page.recipeSavedError.action")}
            </Button>
          )
        })
      }
    }
  }

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  if(!state?.fields) {
    return <Navigate to="/recipes/import-social-media" />
  }

  return (
    <Page className="lg:mt-4">
      <Helmet>
        <title>{t("recipes.importSocialMedia.loading.page.head.title")}</title>
      </Helmet>

      <RecipeLoading recipe={streamedRecipe} />
    </Page>
  )
}