import React from "react"
import { useTranslation } from "react-i18next"

import Item from "./Item"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";

import { List, ListItem } from "@/firebase/types"

type Props = {
  list: List
  handleItemCheck: (itemID: List["items"][0]["id"]) => void
  handleItemClick: (item: List["items"][0]) => void
}
export default function RecipeSortItems({ list, handleItemCheck, handleItemClick }: Props) {
  const { t } = useTranslation()

  const recipes: {
    [ListItem: string]: ListItem["recipe"] & {
      items: List["items"]
    }
  } = {}

  // Sort items alphabetically
  const items = [...list.items]
  items.sort((a, b) => a.name.localeCompare(b.name))

  // Add items to aisles by category
  items.map(item => {
    if(!item.checked) {
      if(item.recipe) {
        if(recipes[item.recipe.id]) {
          recipes[item.recipe.id].items.push({ ...item })
        } else {
          recipes[item.recipe.id] = {
            id: item.recipe.id,
            title: item.recipe.title,
            items: [
              { ...item }
            ]
          }
        }
      } else {
        if(recipes['other']) {
          recipes['other'].items.push({ ...item })
        } else {
          recipes['other'] = {
            id: 'other',
            title: `${t('lists.[id].components.items.RecipeSortItems.defaultCategory')}`,
            items: [
              { ...item }
            ]
          }
        }
      }
    }
  })

  return (
    <>
      {Object.keys(recipes).map((recipeID) => {
        if(recipes[recipeID].items.length > 0) {
          return (
            <Card key={recipeID} className="mb-2">
              <CardHeader className="pt-4 pb-2">
                <CardTitle className="text-base text-muted-foreground">{recipes[recipeID].title}</CardTitle>
              </CardHeader>
              <CardContent className="px-2 pb-4">
                <ul className="flex flex-col gap-2">
                  {recipes[recipeID].items.map((item) => (
                    <li key={item.id}>
                      <Item item={item} handleItemCheck={handleItemCheck} handleItemClick={handleItemClick} />
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          )
        }
      })}
    </>
  )
}