import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { auth, db } from "@/firebase/app"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  collection,
  getDocs,
  orderBy,
  query,
  where
} from "firebase/firestore"

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import { Loader2 } from "lucide-react";

import { Recipe, Collection } from "@/firebase/types"
import SaveToCollectionsModalForm from "@/routes/recipes/[id]/components/modals/SaveToCollectionsModalForm.tsx";

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  recipe: Recipe
}
export default function SaveToCollectionsModal({ isOpen = false, setIsOpen, recipe }: Props) {
  const { t } = useTranslation()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [collections, setCollections] = useState<Collection[] | null>(null)
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // Fetch collections after the modal opens, doesn't use firebase reads if this feature isn't used.
  useEffect(() => {
    if(isOpen) {
      (async function() {
        setFetchingCollections(true)

        const querySnapshot = await getDocs(query(
          collection(db, "collections"),
          where("userId", "==", user?.uid),
          orderBy("_created", "desc")
        ))

        const fetchedCollections: Collection[] = []
        querySnapshot.forEach(doc => {
          fetchedCollections.push(doc.data() as Collection)
        })

        setCollections(fetchedCollections)

        setFetchingCollections(false)
      })()
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>{t("recipes.[id].components.modals.SaveToCollectionsModal.modal.title")}</DialogTitle>
        </DialogHeader>

        {collections && !fetchingCollections ? (
          // Separate component for the form so we can set collections as a default value properly without dealing with react hook form not updating without watch
          <SaveToCollectionsModalForm collections={collections} recipe={recipe} setIsOpen={setIsOpen} />
        ) : (
          <div className="flex justify-center items-center my-8">
            <Loader2 size={48} className="animate-spin" />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}