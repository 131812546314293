import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { db } from "@/firebase/app"
import { doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { ListSortNames } from "@/firebase/constants"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import ListHeader from "@/routes/lists/[id]/components/general/ListHeader"
import EmptyList from "@/routes/lists/[id]/components/general/EmptyList"
import SortOptions from "@/routes/lists/[id]/components/general/SortOptions.tsx";
import AddItem from "@/routes/lists/[id]/components/AddItem.tsx"
import EditItem from "@/routes/lists/[id]/components/EditItem.tsx"
import AisleSortItems from "@/routes/lists/[id]/components/items/AisleSortItems"
import RecipeSortItems from "@/routes/lists/[id]/components/items/RecipeSortItems"
import CustomSortItems from "@/routes/lists/[id]/components/items/CustomSortItems"
import CheckedItems from "@/routes/lists/[id]/components/items/CheckedItems.tsx";

import { useToast } from "@/components/ui/use-toast.ts";

import { List } from "@/firebase/types"

export default function ListPage() {
  const { t } = useTranslation()
  const params = useParams()
  const isMobile = useIsMobile()
  const { toast } = useToast()

  const listID = params.id as string
  const [listData, loading, error, snapshot] = useDocumentData(
    doc(db, "lists", listID)
  )
  const list = listData as List | undefined
  const unCheckedItems = list?.items.filter(item => !item.checked)

  // Stores 1 list item and passes it to the edit item modal to handle
  const [showEditItemModal, setShowEditItemModal] = useState(false)
  const [currentEditingItem, setCurrentEditingItem] = useState<List["items"][0] | null>(null)

  function handleItemClick(item: List["items"][0]) {
    if(setShowEditItemModal && setCurrentEditingItem) {
      setShowEditItemModal(true)
      setCurrentEditingItem(item)
    }
  }

  async function handleItemCheck(itemID: List["items"][0]["id"]) {
    try {
      if(list) {
        const newItems: List["items"] = list.items.map(item => {
          if(item.id === itemID) {
            return {
              ...item,
              checked: !item.checked
            }
          }
          return item
        })

        await updateDoc(doc(db, "lists", listID), {
          _updated: serverTimestamp(),
          items: newItems
        })
      }
    } catch(e) {
      console.error(e)

      toast({
        title: t("lists.[id].page.handleItemCheck.error.generic.title"),
        description: t("lists.[id].page.handleItemCheck.error.generic.description"),
        variant: "destructive"
      })
    }
  }

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{list?.title}</title>
      </Helmet>

      <ListHeader list={list} uncheckedItems={unCheckedItems} />

      <div className="flex flex-col flex-grow w-full max-w-4xl mx-auto px-4">
        <div className="mb-2">
          <div className="flex justify-between gap-4 lg:mb-4">
            <h2 className="text-xl font-semibold">{list?.title}</h2>

            {list && !isMobile && (
              <AddItem list={list} />
            )}
          </div>

          {list && unCheckedItems && unCheckedItems.length > 0 ? (
            <div className="flex justify-between items-center gap-4">
              <p className="text-sm">{t("lists.[id].page.numItems", { count: unCheckedItems.length })}</p>

              <SortOptions list={list} />
            </div>
          ) : (
            // Adds some space between the title and checked items when no unchecked items in the list exist
            <div className="mb-4" />
          )}
        </div>

        {list && list.items && list.items.length > 0 ? (
          <div className="mb-20">
            {list.sort === ListSortNames.Aisle && <AisleSortItems list={list} handleItemClick={handleItemClick} handleItemCheck={handleItemCheck} />}
            {list.sort === ListSortNames.Recipe && <RecipeSortItems list={list} handleItemClick={handleItemClick} handleItemCheck={handleItemCheck} />}
            {list.sort === ListSortNames.Custom && <CustomSortItems list={list} handleItemClick={handleItemClick} handleItemCheck={handleItemCheck} />}

            <CheckedItems list={list} handleItemClick={handleItemClick} handleItemCheck={handleItemCheck} />

            {/* Render 1 edit modal instead of one for each item */}
            <EditItem
              isOpen={showEditItemModal}
              setIsOpen={setShowEditItemModal}
              item={currentEditingItem}
              list={list}
            />
          </div>
        ) : (
          <EmptyList />
        )}
      </div>

      {list && isMobile && (
        <AddItem list={list} />
      )}
    </Page>
  )
}
