import { z } from "zod";

// NOTE: Keep this up to date with "ingredientCategories" in translation files
export const IngredientCategories = {
  Alcohol: "alcohol",
  Baking: "baking",
  Beverages: "beverages",
  BreadAndBakery: "bread-and-bakery",
  CannedGoods: "canned-goods",
  CerealAndBreakfastFoods: "cereal-and-breakfast-foods",
  CoffeeAndTea: "coffee-and-tea",
  CondimentsAndSauces: "condiments-and-sauces",
  DairyAndEggs: "dairy-and-eggs",
  FishAndSeafood: "fish-and-seafood",
  FrozenFoods: "frozen-foods",
  FruitsAndVegetables: "fruits-and-vegetables",
  HerbsAndSpices: "herbs-and-spices",
  MeatAndPoultry: "meat-and-poultry",
  PastaAndNoodles: "pasta-and-noodles",
  RiceAndGrains: "rice-and-grains",
  Snacks: "snacks",
  Other: "other",
} as const;

export const IngredientCategoriesSchema = z.enum([
  IngredientCategories.Alcohol,
  IngredientCategories.Baking,
  IngredientCategories.Beverages,
  IngredientCategories.BreadAndBakery,
  IngredientCategories.CannedGoods,
  IngredientCategories.CerealAndBreakfastFoods,
  IngredientCategories.CoffeeAndTea,
  IngredientCategories.CondimentsAndSauces,
  IngredientCategories.DairyAndEggs,
  IngredientCategories.FishAndSeafood,
  IngredientCategories.FrozenFoods,
  IngredientCategories.FruitsAndVegetables,
  IngredientCategories.HerbsAndSpices,
  IngredientCategories.MeatAndPoultry,
  IngredientCategories.PastaAndNoodles,
  IngredientCategories.RiceAndGrains,
  IngredientCategories.Snacks,
  IngredientCategories.Other,
]);