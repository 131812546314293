import { useTranslation } from "react-i18next";

import { Theme, useTheme } from "@/components/theme/ThemeProvider.tsx"

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { Moon, Sun } from "lucide-react";

export function ThemeToggleSelect() {
  const { t } = useTranslation()
  const { theme, setTheme } = useTheme()

  return (
    <>
      <Select defaultValue={theme} onValueChange={(value: Theme) => setTheme(value)}>
        <SelectTrigger>
          <div className="flex gap-2">
            <Sun size={20} className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon size={20} className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <SelectValue />
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="light">{t('components.theme.ThemeToggleSelect.options.light')}</SelectItem>
            <SelectItem value="dark">{t('components.theme.ThemeToggleSelect.options.dark')}</SelectItem>
            <SelectItem value="system">{t('components.theme.ThemeToggleSelect.options.system')}</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}
