import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod"

import { ProviderId } from "firebase/auth"
import { analytics, auth } from "@/firebase/app"
import {
  AuthErrorCodes,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail
} from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"
import { logEvent } from "firebase/analytics";

import Header from "@/components/navigation/Header"
import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage, FormRootErrorMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast.ts";

import { Loader2, UserCircle } from "lucide-react";

import { UserAccount } from "@/firebase/types"

export default function ChangeEmail() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)
  const { toast } = useToast()

  // Redirect the user back to the account page if they logged in with a provider instead of email/password (no email to change)
  let shouldRedirect = true
  if(user && user.providerData) {
    user.providerData.forEach(provider => {
      if(provider.providerId === ProviderId.PASSWORD) {
        shouldRedirect = false
      }
    })

    if(shouldRedirect) {
      navigate("/account")
    }
  }

  const formSchema = z.object({
    newEmail: UserAccount.shape.email
      .min(1, {
        message: t("account.changeEmail.page.form.fields.newEmail.validation.required")
      })
      .email({
        message: t("account.changeEmail.page.form.fields.newEmail.validation.pattern")
      }),

    password: UserAccount.shape.password
      .min(1, {
        message: t("account.changeEmail.page.form.fields.password.validation.required")
      })
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      newEmail: "",
      password: ""
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { newEmail, password } = values

    if(user && user.email) {
      const credential = EmailAuthProvider.credential(
        user.email,
        password
      )

      return reauthenticateWithCredential(user, credential).then(() => {
        return updateEmail(user, newEmail).then(() => {
          logEvent(analytics, 'change_email', { status: 'success' })

          toast({
            title: t("account.changeEmail.page.form.onSubmit.success.title"),
            description: t("account.changeEmail.page.form.onSubmit.success.description"),
            variant: "success"
          })

          navigate('/account')
        }).catch((error) => {
          console.error(error)

          if(error.code === AuthErrorCodes.INVALID_EMAIL) {
            form.setError("newEmail", {
              type: error.code,
              message: `${t("account.changeEmail.page.form.onSubmit.error.invalidEmail")}`
            })
          } else if(error.code === AuthErrorCodes.EMAIL_EXISTS) {
            form.setError("newEmail", {
              type: error.code,
              message: `${t("account.changeEmail.page.form.onSubmit.error.emailExists")}`
            })
          } else {
            form.setError("root", {
              type: error.code,
              message: `${t("account.changeEmail.page.form.onSubmit.error.generic")}`
            })
          }
        })
      }).catch((error) => {
        console.error(error)

        if(error.code === AuthErrorCodes.INVALID_PASSWORD) {
          form.setError("password", {
            type: error.code,
            message: `${t("account.changeEmail.page.form.onSubmit.error.currentPasswordMismatch")}`
          })
        } else {
          form.setError("root", {
            type: error.code,
            message: `${t("account.changeEmail.page.form.onSubmit.error.generic")}`
          })
        }
      })
    }
  }

  if(loading || shouldRedirect) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <Page>
      <Helmet>
        <title>{t("account.changeEmail.page.head.title")}</title>
      </Helmet>

      <Header title={t("account.changeEmail.components.Header.title")} goBackURL="/account" />

      <div className="w-full max-w-4xl mx-auto px-4 pb-4">
        {user && user.email && (
          <div className="flex items-center gap-2 mb-8">
            <Avatar className="h-12 w-12 bg-transparent">
              {user && user.photoURL && user.displayName && (
                <AvatarImage src={user.photoURL} alt={user.displayName} />
              )}
              <AvatarFallback>
                <UserCircle size={80} />
              </AvatarFallback>
            </Avatar>

            <div>
              <p className="font-semibold">{t("account.changeEmail.page.currentEmail")}</p>
              <p>{user.email}</p>
            </div>
          </div>
        )}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="newEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("account.changeEmail.page.form.fields.newEmail.label")}</FormLabel>
                  <FormControl>
                    <Input type="email" placeholder={t("account.changeEmail.page.form.fields.newEmail.placeholder")} {...field} />
                  </FormControl>
                  <FormDescription>
                    {t("account.changeEmail.page.form.fields.newEmail.description")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("account.changeEmail.page.form.fields.password.label")}</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder={t("account.changeEmail.page.form.fields.password.placeholder")} {...field} />
                  </FormControl>
                  <FormDescription>
                    {t("account.changeEmail.page.form.fields.password.description")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="max-lg:w-full"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? (
                <Loader2 size={24} className="animate-spin" />
              ) : t("account.changeEmail.page.form.actions.submit")}
            </Button>

            <FormRootErrorMessage />
          </form>
        </Form>
      </div>
    </Page>
  )
}