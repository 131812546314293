import { Helmet } from "react-helmet-async"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { analytics, auth } from "@/firebase/app.ts"
import {
  AuthErrorCodes,
  verifyPasswordResetCode,
  confirmPasswordReset
} from "firebase/auth"
import { logEvent } from "firebase/analytics";

import Page from "@/components/page/Page.tsx";
import Loading from "@/components/page/Loading.tsx"

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { ArrowLeft, Loader2 } from "lucide-react";

import LogoImg from "@/assets/icon.svg"

import { FIELD_LIMITS } from "@/globals.js";

import { UserAccount } from "@/firebase/types"

export default function ResetPassword() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { toast } = useToast()
  // const mode = searchParams.get('mode')
  const oobCode = searchParams.get("oobCode")
  // const apiKey = searchParams.get('apiKey')

  const [validCode, setValidCode] = useState<boolean | null>(null)

  useEffect(() => {
    if(oobCode && typeof oobCode === "string") {
      verifyPasswordResetCode(auth, oobCode)
        .then((value) => {
          setValidCode(true)
        })
        .catch((error) => {
          console.error(error)

          setValidCode(false)
        })
    } else {
      setValidCode(false)
    }
  }, [oobCode])

  const formSchema = z.object({
    password: UserAccount.shape.password
      .min(FIELD_LIMITS.USER_ACCOUNT.PASSWORD.MIN_LENGTH, {
        message: t("(auth).resetPassword.page.card.form.fields.password.validation.minLength")
      })
      .max(FIELD_LIMITS.USER_ACCOUNT.PASSWORD.MAX_LENGTH, {
        message: t("(auth).resetPassword.page.card.form.fields.password.validation.maxLength")
      })
      .refine((val) => /\d/.test(val) && /[A-Z]/.test(val), {
        message: t("(auth).resetPassword.page.card.form.fields.password.validation.passwordRules")
      }),

    confirmPassword: UserAccount.shape.password
      .min(1, {
        message: t("(auth).resetPassword.page.card.form.fields.confirmPassword.validation.required")
      })
  }).refine((data) => data.password === data.confirmPassword, {
    message: t("(auth).resetPassword.page.card.form.fields.confirmPassword.validation.match"),
    path: ["confirmPassword"]
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    }
  })

  // TODO: Log the user out of all devices when they reset password
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { password } = values

    return confirmPasswordReset(auth, oobCode as string, password)
      .then(() => {
        logEvent(analytics, 'reset_password', { status: 'success' })

        toast({
          title: t("(auth).resetPassword.page.card.form.onSubmit.success.title"),
          description: t("(auth).resetPassword.page.card.form.onSubmit.success.description"),
          variant: "success"
        })

        navigate('/login')
      })
      .catch((error) => {
        console.error(error)

        // Auth error codes: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth

        if(error.code === AuthErrorCodes.EXPIRED_OOB_CODE) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).resetPassword.page.card.form.onSubmit.error.expiredPasswordResetCode")}`
          })
        } else if(error.code === AuthErrorCodes.INVALID_OOB_CODE) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).resetPassword.page.card.form.onSubmit.error.invalidPasswordResetCode")}`
          })
        } else if(error.code === AuthErrorCodes.USER_DELETED) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).resetPassword.page.card.form.onSubmit.error.userNotFound")}`
          })
        } else if(error.code === AuthErrorCodes.USER_DISABLED) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).resetPassword.page.card.form.onSubmit.error.userDisabled")}`
          })
        } else if(error.code === AuthErrorCodes.WEAK_PASSWORD) {
          form.setError("password", {
            type: error.code,
            message: `${t("(auth).resetPassword.page.card.form.onSubmit.error.weakPassword")}`
          })
        } else {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).resetPassword.page.card.form.onSubmit.error.generic")}`
          })
        }
      })
  }

  // Keep loading while we verify the password reset code
  if(validCode === null) {
    return <Loading />
  }

  return (
    <Page className="justify-center py-4">
      <Helmet>
        <title>{t("(auth).resetPassword.page.head.title")}</title>
      </Helmet>

      <div className="m-4">
        <div className="flex justify-center items-center my-4">
          <img src={LogoImg} alt="Flavorish" className="w-12 mr-4" />
          <p className="font-poppins text-4xl text-base-content dark:text-white">Flavorish</p>
        </div>


        {validCode ? (
          <>
            <Card className="max-w-lg mx-auto">
              <CardHeader className="space-y-1">
                <CardTitle className="text-2xl">{t("(auth).resetPassword.page.card.title")}</CardTitle>
              </CardHeader>

              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                  <CardContent className="flex flex-col gap-4 pb-2">
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("(auth).resetPassword.page.card.form.fields.password.label")}</FormLabel>
                          <FormControl>
                            <Input type="password" placeholder={t("(auth).resetPassword.page.card.form.fields.password.placeholder")} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="confirmPassword"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("(auth).resetPassword.page.card.form.fields.confirmPassword.label")}</FormLabel>
                          <FormControl>
                            <Input type="password" placeholder={t("(auth).resetPassword.page.card.form.fields.confirmPassword.placeholder")} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>

                  <CardFooter className="flex-col gap-4">
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-full"
                      disabled={form.formState.isSubmitting}
                    >
                      {form.formState.isSubmitting ? (
                        <Loader2 size={24} className="animate-spin" />
                      ) : t("(auth).resetPassword.page.card.form.actions.submit")}
                    </Button>

                    <FormRootErrorMessage />
                  </CardFooter>
                </form>
              </Form>
            </Card>

            <Link to="/login" className="link mt-4 flex justify-center items-center gap-2 w-full">
              <ArrowLeft size={20} />
              {t("(auth).resetPassword.page.backToLogin")}
            </Link>
          </>
        ) : (
          <Card className="max-w-lg mx-auto">
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">{t("(auth).resetPassword.page.card.invalidCode.title")}</CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription className="text-base">
                <Trans i18nKey="(auth).resetPassword.page.card.invalidCode.text">
                  The URL you followed is not valid. You can return to the
                  <Link to="/forgot-password" className="link">forgot password page</Link>
                  to request a new one, or go back to
                  <Link to="/login" className="link">login</Link>.
                </Trans>
              </CardDescription>
            </CardContent>
          </Card>
        )}
      </div>
    </Page>
  )
}