import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { analytics, auth, db } from "@/firebase/app"
import { setDoc, serverTimestamp, doc } from "firebase/firestore"
import { logEvent } from "firebase/analytics";
import { useAuthState } from "react-firebase-hooks/auth"
import { uuidv4 } from "@firebase/util"

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { FIELD_LIMITS } from "@/globals.js";

import { Loader2, Plus } from "lucide-react";

import { Collection } from "@/firebase/types"

export default function AddCollection() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const [user, loading, error] = useAuthState(auth)

  const formSchema = z.object({
    title: Collection.shape.title
      .min(1, {
        message: t("collections.components.AddCollection.modal.form.fields.title.validation.required")
      })
      .max(FIELD_LIMITS.COLLECTION.TITLE.MAX_LENGTH, {
        message: t('collections.components.AddCollection.modal.form.fields.title.validation.maxLength', { maxLength: FIELD_LIMITS.COLLECTION.TITLE.MAX_LENGTH })
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: ""
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { title } = values

    try {
      // Just in case the uid is missing
      if(!user?.uid) {
        throw new Error('User is missing uid!')
      }

      const id = uuidv4()

      const newCollectionObj: Collection = {
        _created: serverTimestamp(),
        _updated: serverTimestamp(),
        id: id,
        userId: user.uid,
        title: title,
        recipeIds: [],
        recipeImages: []
      }

      await setDoc(doc(db, "collections", id), newCollectionObj)

      logEvent(analytics, 'create_collection')

      form.reset()

      navigate(`/collections/${id}`)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "AddCollection.onSubmit.generic",
        message: `${t("collections.components.AddCollection.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        {isMobile ? (
          <Button
            variant="primary"
            size="icon"
            className="floating-action-btn w-12 h-12"
          >
            <Plus size={28} />
          </Button>
        ) : (
          <Button
            variant="primary"
            className="gap-2"
          >
            <Plus size={20} />
            {t("collections.components.AddCollection.button")}
          </Button>
        )}
      </DialogTrigger>

      <DialogContent className="sm:max-w-sm">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <DialogHeader>
              <DialogTitle>{t("collections.components.AddCollection.modal.title")}</DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("collections.components.AddCollection.modal.form.fields.title.label")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("collections.components.AddCollection.modal.form.fields.title.placeholder")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="submit"
                variant="primary"
                className="w-full"
                disabled={form.formState.isSubmitting}
              >
                {form.formState.isSubmitting ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t("collections.components.AddCollection.modal.form.actions.create")}
              </Button>
            </DialogFooter>

            <FormRootErrorMessage />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
