import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { auth, db } from "@/firebase/app"
import { getDocs, collection as FBCollection, limit, query, where, documentId, doc, serverTimestamp, updateDoc } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"

import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import RecipeOptions from "@/routes/collections/[id]/components/RecipeOptions.tsx";
import RecipeCard from "@/routes/recipes/components/RecipeCard.tsx";

import InfiniteScroll from "@/components/ui/expansions/infinite-scroll.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { Loader2 } from "lucide-react";

import { Collection, Recipe } from "@/firebase/types"

const PAGE_LIMIT = 10

type Props = {
  collection: Collection
}
export default function ListRecipes({ collection }: Props) {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  // Reverse because we have to order them client-side and we want to display recipes by the most recently added first
  const recipeIds = [...collection.recipeIds].reverse()

  // Fetch the mostly added recipes first (last 10 in the array)
  const [recipesData, loading, error] = useCollectionDataOnce(
    query(
      FBCollection(db, "recipes"),
      where(documentId(), "in", recipeIds.slice(0, PAGE_LIMIT)),
      where("userId", "==", user?.uid),
      limit(PAGE_LIMIT)
    )
  )

  const [recipes, setRecipes] = useState<Recipe[] | null>(null)

  // Keeps track of which recipe ids are to be loaded next
  const [recipeIdsToLoad, setRecipeIdsToLoad] = useState<string[]>([])

  const [canFetchMore, setCanFetchMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    if(recipesData && recipesData.length > 0 && !loading && !error) {
      // Sort the current batch of recipes by the recipeIds array, in other words, the same order they were added to the collection
      const newRecipes = recipesData as Recipe[]
      const sortedRecipesData = recipeIds.slice(0, PAGE_LIMIT).map(id => newRecipes.find(recipe => recipe.id === id)).filter(recipe => recipe !== undefined) as Recipe[]
      setRecipes(sortedRecipesData)

      // Set the remaining recipe ids to be loaded to all recipe ids except the ones we just fetched
      const newRecipeIdsToLoad = [...recipeIds.slice(PAGE_LIMIT, recipeIds.length)]
      setRecipeIdsToLoad(newRecipeIdsToLoad)

      if(newRecipeIdsToLoad.length > 0) {
        setCanFetchMore(true)
      }
    }
  }, [recipesData])

  async function next() {
    if(recipes && recipes.length > 0) {
      setLoadingMore(true)

      const querySnapshot = await getDocs(query(
        FBCollection(db, "recipes"),
        where(documentId(), "in", recipeIdsToLoad.slice(0, PAGE_LIMIT)), // Get the last 10 in the array
        where("userId", "==", user?.uid),
        limit(PAGE_LIMIT)
      ))

      const newRecipes: Recipe[] = []
      querySnapshot.forEach(doc => {
        newRecipes.push(doc.data() as Recipe)
      })

      // Sort the current batch of recipes by the recipeIds array, in other words, the same order they were added to the collection
      const sortedNewRecipes = recipeIdsToLoad.slice(0, PAGE_LIMIT).map(id => newRecipes.find(recipe => recipe.id === id)).filter(recipe => recipe !== undefined) as Recipe[]

      setRecipes([...recipes, ...sortedNewRecipes])

      // Set the remaining recipe ids to be loaded to all recipe ids except the ones we just fetched
      const newRecipeIdsToLoad = recipeIdsToLoad.slice(PAGE_LIMIT, recipeIds.length)
      setRecipeIdsToLoad(newRecipeIdsToLoad)

      if(newRecipeIdsToLoad.length <= 0) {
        setCanFetchMore(false)
      }

      setLoadingMore(false)
    }
  }

  async function handleDeleteRecipe(recipe: Recipe) {
    try {
      await updateDoc(doc(db, "collections", collection.id), {
        _updated: serverTimestamp(),
        recipeIds: collection.recipeIds.filter(id => id !== recipe.id),
        recipeImages: collection.recipeImages.filter(image => image !== recipe.image?.url)
      })
    } catch(e) {
      console.error(e)

      toast({
        title: t("collections.[id].components.ListRecipes.handleDeleteRecipe.error.generic.title"),
        description: t("collections.[id].components.ListRecipes.handleDeleteRecipe.error.generic.description"),
        variant: "destructive"
      })
    }
  }

  // Second param in this condition fixes an issue where on initial load, there's a moment after the recipesData has finished loading (loading = false)
  // but the useEffect hasn't set the recipes state variable yet, leaving the page to render the NoRecipes component
  if(loading || (!recipes && !loading && recipesData)) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-2 gap-y-6">
        {recipes && recipes.length > 0 && recipes.map((recipe) => (
          <div key={recipe.id} className="relative">
            <RecipeOptions recipe={recipe} handleDeleteRecipe={handleDeleteRecipe} />

            <RecipeCard recipe={recipe} />
          </div>
        ))}
      </div>

      <InfiniteScroll hasMore={canFetchMore} isLoading={loadingMore} next={next} threshold={1}>
        {canFetchMore && (
          <div className="flex justify-center items-center my-16">
            <Loader2 size={48} className="animate-spin" />
          </div>
        )}
      </InfiniteScroll>
    </>
  )
}
