import React from "react"
import { useTranslation } from "react-i18next";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table"

import { Recipe } from "@/firebase/types"

type Props = {
  nutrition: Recipe["nutrition"]
}
export default function Nutrition({ nutrition }: Props) {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader className="flex flex-row justify-between items-start pt-4 pb-4">
        <CardTitle className="text-lg font-semibold">{t("recipes.[id].components.recipe.Nutrition.title")}</CardTitle>
      </CardHeader>
      <CardContent className="px-2 pb-2">
        <Table>
          <TableBody>
            {nutrition?.servingSize && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.servingSize')}</TableCell>
                <TableCell>{nutrition?.servingSize}</TableCell>
              </TableRow>
            )}
            {nutrition?.calories && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.calories')}</TableCell>
                <TableCell>{nutrition?.calories}</TableCell>
              </TableRow>
            )}
            {nutrition?.carbohydrateContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.carbohydrateContent')}</TableCell>
                <TableCell>{nutrition?.carbohydrateContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.cholesterolContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.cholesterolContent')}</TableCell>
                <TableCell>{nutrition?.cholesterolContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.fatContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.fatContent')}</TableCell>
                <TableCell>{nutrition?.fatContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.fiberContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.fiberContent')}</TableCell>
                <TableCell>{nutrition?.fiberContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.proteinContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.proteinContent')}</TableCell>
                <TableCell>{nutrition?.proteinContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.saturatedFatContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.saturatedFatContent')}</TableCell>
                <TableCell>{nutrition?.saturatedFatContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.sodiumContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.sodiumContent')}</TableCell>
                <TableCell>{nutrition?.sodiumContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.sugarContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.sugarContent')}</TableCell>
                <TableCell>{nutrition?.sugarContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.transFatContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.transFatContent')}</TableCell>
                <TableCell>{nutrition?.transFatContent}</TableCell>
              </TableRow>
            )}
            {nutrition?.unsaturatedFatContent && (
              <TableRow>
                <TableCell className="font-medium">{t('recipes.[id].components.recipe.Nutrition.labels.unsaturatedFatContent')}</TableCell>
                <TableCell>{nutrition?.unsaturatedFatContent}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}