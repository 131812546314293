import { useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import { Link as LuLink } from "lucide-react"

import androidURL1 from "@/assets/images/import-recipe-guide/android/Url 1.gif"
import androidURL2 from "@/assets/images/import-recipe-guide/android/Url 2.gif"
import iosURL1 from "@/assets/images/import-recipe-guide/ios/Url 1.gif"
import iosURL2 from "@/assets/images/import-recipe-guide/ios/Url 2.gif"

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

export default function ImportRecipeGuide({ isOpen = false, setIsOpen }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-h-dvh overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <LuLink size={20} />
            {t("recipes.components.modals.ImportRecipeGuide.modal.title")}
          </DialogTitle>
        </DialogHeader>

        <div className="mt-4 space-y-8">
          {Capacitor.getPlatform() === 'android' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.components.modals.ImportRecipeGuide.modal.content.android.step1.title")}</h3>
                <p>{t("recipes.components.modals.ImportRecipeGuide.modal.content.android.step1.text")}</p>
                <img src={androidURL1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.components.modals.ImportRecipeGuide.modal.content.android.step2.title")}</h3>
                <p>{t("recipes.components.modals.ImportRecipeGuide.modal.content.android.step2.text")}</p>
                <img src={androidURL2} alt="" className="my-4" />
              </div>
            </>
          )}

          {Capacitor.getPlatform() === 'ios' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.components.modals.ImportRecipeGuide.modal.content.ios.step1.title")}</h3>
                <p>{t("recipes.components.modals.ImportRecipeGuide.modal.content.ios.step1.text")}</p>
                <img src={iosURL1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.components.modals.ImportRecipeGuide.modal.content.ios.step2.title")}</h3>
                <p>{t("recipes.components.modals.ImportRecipeGuide.modal.content.ios.step2.text")}</p>
                <img src={iosURL2} alt="" className="my-4" />
              </div>
            </>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="primary">
              {t("recipes.components.modals.ImportRecipeGuide.modal.actions.close")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}