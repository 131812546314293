import { Link } from "react-router-dom";

import { GetFavicon } from "@/lib/helpers/favicon.ts";

import AIStars from "@/components/icons/ai-stars/AIStars.tsx";

import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";

import { Camera, CircleCheckBig, Image, Grid2x2Check } from "lucide-react"

import { Recipe } from "@/firebase/types";

type Props = {
  recipe?: Recipe
  isLoading?: boolean
  disableLink?: boolean
  isSelected?: boolean
}
export default function RecipeCard({ recipe, isLoading = false, disableLink = false, isSelected = false }: Props) {
  let sourceName = recipe?.source?.name

  if(recipe && recipe.source && !recipe.source.name && recipe.source.url) {
    const url = new URL(recipe.source.url)
    sourceName = url.host.replace("www.", "")
  }

  return (
    <>
      {recipe && (
        <Link
          to={`/recipes/${recipe.id}`}
          className="group flex flex-col"
          onClick={(e) => {
            if(disableLink) {
              e.preventDefault()
            }
          }}
        >
          <div className="relative overflow-hidden rounded-md">
            <AspectRatio ratio={1} className="bg-muted">
              {recipe.image ? (
                <img
                  src={recipe.image.url}
                  alt={recipe.title}
                  className="w-full h-full object-cover transition-all group-hover:scale-105"
                />
              ) : (
                <div className="flex justify-center items-center w-full h-full">
                  <Image size={48} className="text-muted-foreground" />
                </div>
              )}
            </AspectRatio>

            {isSelected && (
              <div className="absolute w-full h-full top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-black/60">
                <CircleCheckBig size={40} strokeWidth={3} className="text-primary" />
              </div>
            )}
          </div>

          <div className="mt-2 mb-auto">
            <h3 className="text-base font-semibold group-hover:underline">{recipe.title}</h3>
          </div>

          <div className="flex justify-between items-center gap-4 mt-2">
            {(recipe.totalTime?.hours > 0 || recipe.totalTime?.minutes > 0) ? (
              <Badge variant="accent">
                {recipe.totalTime?.hours > 0 ? `${recipe.totalTime?.hours}h` : ''}
                {recipe.totalTime?.minutes > 0 ? `${recipe.totalTime?.minutes}m` : ''}
              </Badge>
            ) : <div />}

            {/* There shouldn't ever be a case where more than 1 of these shows up at the same time, but just in case */}
            <div className="flex items-center gap-2">
              {recipe.source && recipe.source.url && (
                <img
                  src={recipe.source.favicon || GetFavicon(recipe.source.url)}
                  alt={`${sourceName} favicon`}
                  className="w-5 h-5"
                />
              )}

              {recipe.aiGenerated && (
                <AIStars size={20} />
              )}

              {recipe.imageImported && (
                <Camera size={20} style={{ stroke: "url(#ai-gradient)" }} />
              )}

              {recipe.socialMediaImported && !recipe.source?.url && (
                <Grid2x2Check size={20} style={{ stroke: "url(#ai-gradient)" }} />
              )}
            </div>
          </div>
        </Link>
      )}

      {isLoading && (
        <div>
          <div className="overflow-hidden rounded-md">
            <AspectRatio ratio={3 / 4}>
              <Skeleton className="w-full h-full" />
            </AspectRatio>
          </div>

          <div className="mt-2">
            <h3 className="flex flex-col gap-2">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
            </h3>
          </div>
        </div>
      )}
    </>
  )
}