import { formatQuantity } from "format-quantity"

import { Ingredient as IngredientType } from "@/firebase/types"

type Props = {
  ingredient: IngredientType
  scaleRatio?: number
}
export default function IngredientText({ ingredient, scaleRatio = 1 }: Props) {
  return (
    <>
      {ingredient.description ? (
        <>
          <strong>
            {ingredient.quantity ? formatQuantity(parseFloat(String(ingredient.quantity * scaleRatio)).toFixed(2), false) : ''}
            {ingredient.quantity2 ? "-" + formatQuantity(parseFloat(String(ingredient.quantity2 * scaleRatio)).toFixed(2), false) : ''}
            {" "}
            {ingredient.unitOfMeasure}
          </strong>
          {" "}
          {ingredient.description}
        </>
      ) : (
        ingredient.name
      )}
    </>
  )
}