import * as React from "react";
import { cn } from "@/lib/utils.ts";

const Page = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex flex-col flex-grow bg-background",
      className
    )}
    {...props}
  />
))

export default Page