import { Draggable } from "@hello-pangea/dnd"

import Item from "./Item"

import { List } from "@/firebase/types"

type Props = {
  item: List["items"][0]
  index: number
  handleItemCheck: (itemID: List["items"][0]["id"]) => void
  handleItemClick: (item: List["items"][0]) => void
}

export default function DraggableItem({ item, index, handleItemCheck, handleItemClick }: Props) {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Item
            item={item}
            handleItemCheck={handleItemCheck}
            handleItemClick={handleItemClick}
            isDraggable={true}
            isDragging={snapshot.isDragging}
          />
        </div>
      )}
    </Draggable>
  )
}