import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { db } from "@/firebase/app.ts";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { FIELD_LIMITS } from "@/globals.js";

import { Loader2 } from "lucide-react";

import { Collection } from "@/firebase/types.ts";

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  collection: Collection | null
}

export default function RenameCollectionModal({ isOpen = false, setIsOpen, collection }: Props) {
  const { t } = useTranslation()

  const formSchema = z.object({
    newTitle: Collection.shape.title
      .min(1, {
        message: t("collections.[id].components.modals.RenameCollectionModal.modal.form.fields.newTitle.validation.required")
      })
      .max(FIELD_LIMITS.COLLECTION.TITLE.MAX_LENGTH, {
        message: t('collections.[id].components.modals.RenameCollectionModal.modal.form.fields.newTitle.validation.maxLength', { maxLength: FIELD_LIMITS.COLLECTION.TITLE.MAX_LENGTH })
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      newTitle: "",
    }
  })

  // Update the value when a new collection is being edited
  useEffect(() => {
    if(collection) {
      form.reset()
      form.setValue('newTitle', collection.title)
    }
  }, [collection])

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { newTitle } = values

    try {
      if(collection?.id) {
        await updateDoc(doc(db, "collections", collection.id), {
          _updated: serverTimestamp(),
          title: newTitle,
        });

        setIsOpen(false)
      } else {
        throw new Error(`${t('collections.[id].components.modals.RenameCollectionModal.modal.form.onSubmit.error.missingParams')}`)
      }
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("collections.[id].components.modals.RenameCollectionModal.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-sm">
        <DialogHeader>
          <DialogTitle>{t("collections.[id].components.modals.RenameCollectionModal.modal.title")}</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="newTitle"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("collections.[id].components.modals.RenameCollectionModal.modal.form.fields.newTitle.label")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("collections.[id].components.modals.RenameCollectionModal.modal.form.fields.newTitle.placeholder")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="submit"
                variant="primary"
                className="w-full"
                disabled={form.formState.isSubmitting}
              >
                {form.formState.isSubmitting ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t("collections.[id].components.modals.RenameCollectionModal.modal.form.actions.rename")}
              </Button>
            </DialogFooter>

            <FormRootErrorMessage />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}