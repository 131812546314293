import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { analytics, db } from "@/firebase/app.ts"
import { doc, serverTimestamp, setDoc } from "firebase/firestore"
import { logEvent } from "firebase/analytics";
import { uuidv4 } from "@firebase/util";

import { uploadRecipeImage } from "@/lib/images.ts"

import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { Loader2 } from "lucide-react";

import { Recipe, User } from "@/firebase/types.ts"

type Props = {
  recipe: Recipe
  userId: User["id"]
}

export default function SaveRecipeToast({ recipe, userId }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    toast({
      title: t('recipes.[id].components.general.SaveRecipeToast.toast.title'),
      duration: 3600000, // 1 hour
      action: (
        <Button
          type="button"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Loader2 size={24} className="animate-spin" />
          ) : t('recipes.[id].components.general.SaveRecipeToast.toast.button')}
        </Button>
      )
    })
  }, [isSubmitting])

  async function handleSubmit() {
    setIsSubmitting(true)

    const id = uuidv4()

    const duplicatedRecipe: Recipe = {
      ...recipe,
      _created: serverTimestamp(),
      _updated: serverTimestamp(),
      id: id,
      userId: userId
    }
    delete duplicatedRecipe.image

    if(recipe.image) {
      await fetch(recipe.image.url)
        .then((res) => res.blob())
        .then(async (imageBlob) => {
          const imageFile = new File([imageBlob], recipe.image!.name, { type: imageBlob.type })

          try {
            const uploadedImage = await uploadRecipeImage(id, imageFile)

            if(uploadedImage) {
              duplicatedRecipe.image = uploadedImage
            }
          } catch(e) {
            console.error(e)
            // Ignore image failures
          }
        })
    }

    try {
      await setDoc(doc(db, "recipes", id), duplicatedRecipe)

      setIsSubmitting(false)

      logEvent(analytics, 'save_shared_recipe')

      toast({
        variant: "success",
        title: t('recipes.[id].components.general.SaveRecipeToast.handleSubmit.success.title'),
      })

      navigate(`/recipes/${id}`)
    } catch(e) {
      console.error(e)

      setIsSubmitting(false)

      toast({
        variant: "destructive",
        title: t('recipes.[id].components.general.SaveRecipeToast.handleSubmit.error.generic.title'),
        description: t('recipes.[id].components.general.SaveRecipeToast.handleSubmit.error.generic.description'),
      })
    }
  }

  return null
}