import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod";
import { parseIngredient } from "parse-ingredient"

import { analytics, auth, db } from "@/firebase/app.ts"
import { useAuthState } from "react-firebase-hooks/auth"
import { logEvent } from "firebase/analytics";
import { uuidv4 } from "@firebase/util"
import { doc, serverTimestamp, setDoc, updateDoc, deleteField } from "firebase/firestore"

import { FIELD_LIMITS } from "@/globals.js";

import { deleteRecipeImage, uploadRecipeImage } from "@/lib/images.ts"

import Loading from "@/components/page/Loading.tsx"
import ErrorLoading from "@/components/page/ErrorLoading.tsx"

import ImageManager from "@/routes/recipes/components/form/components/ImageManager.tsx"

import { Button } from "@/components/ui/button.tsx";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";

import { Loader2, X } from "lucide-react";

import { Recipe } from "@/firebase/types.ts"

export const formSchema = z.object({
  title: Recipe.shape.title,
  image: z.string(),
  description: Recipe.shape.description,
  ingredients: Recipe.shape.ingredients,
  instructions: Recipe.shape.instructions,
  servings: z.string(), // Really a number, just need to allow this to be empty by default
  prepTimeHours: z.string(), // Really a number, just need to allow this to be empty by default
  prepTimeMinutes: z.string(), // Really a number, just need to allow this to be empty by default
  cookTimeHours: z.string(), // Really a number, just need to allow this to be empty by default
  cookTimeMinutes: z.string(), // Really a number, just need to allow this to be empty by default
  notes: Recipe.shape.notes
})

const defaultIngredient: Omit<Recipe["ingredients"][0], "id"> = {
  // Generate the id where this is used
  name: "",
  quantity: null,
  quantity2: null,
  unitOfMeasure: null,
  unitOfMeasureID: null,
  description: "",
  isGroupHeader: false
}

const defaultInstruction: Omit<Recipe["instructions"][0], "id"> = {
  text: "",
  isGroupHeader: false
}

type Props = {
  recipe?: Recipe
  isEditing?: boolean
}
export default function AddEditRecipeForm({ recipe, isEditing = false }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)

  const form = useForm<z.infer<typeof formSchema>>({
    // Using the normal form register methods instead of this
    // resolver: zodResolver(formSchema),
    defaultValues: {
      title: recipe?.title || "",
      image: "",
      description: recipe?.description || "",
      ingredients: recipe?.ingredients ? [
        ...recipe.ingredients,
        { ...defaultIngredient, id: uuidv4() }
      ] : [
        { ...defaultIngredient, id: uuidv4() }
      ],
      instructions: recipe?.instructions ? [
        ...recipe.instructions,
        { ...defaultInstruction, id: uuidv4() }
      ] : [
        { ...defaultInstruction, id: uuidv4() }
      ],
      // @ts-ignore I know this is weird but it works to keep the default state empty
      servings: recipe?.servings || "",
      // @ts-ignore I know this is weird but it works to keep the default state empty
      prepTimeHours: recipe?.prepTime.hours || "",
      // @ts-ignore I know this is weird but it works to keep the default state empty
      prepTimeMinutes: recipe?.prepTime.minutes || "",
      // @ts-ignore I know this is weird but it works to keep the default state empty
      cookTimeHours: recipe?.cookTime.hours || "",
      // @ts-ignore I know this is weird but it works to keep the default state empty
      cookTimeMinutes: recipe?.cookTime.minutes || "",
      notes: recipe?.notes || "",
    }
  })

  form.register("title", {
    required: `${t("recipes.components.form.AddEditRecipeForm.form.fields.title.validation.required")}`,
    maxLength: {
      value: FIELD_LIMITS.RECIPE.TITLE.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.title.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.TITLE.MAX_LENGTH })}`
    }
  })

  form.register("description", {
    maxLength: {
      value: FIELD_LIMITS.RECIPE.DESCRIPTION.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.description.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.DESCRIPTION.MAX_LENGTH })}`
    }
  })

  // TODO: Make this an autocomplete
  const {
    fields: ingredientsFields,
    append: ingredientsAppend,
    insert: ingredientsInsert,
    remove: ingredientsRemove
  } = useFieldArray({
    control: form.control,
    name: "ingredients",
    rules: {
      required: `${t("recipes.components.form.AddEditRecipeForm.form.fields.ingredients.validation.required")}`,
      minLength: {
        value: 2, // Ensures 1 field is filled out. The other will be there automatically
        message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.ingredients.validation.required")}`
      },
      maxLength: {
        value: FIELD_LIMITS.RECIPE.INGREDIENTS.MAX_NUM_ITEMS,
        message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.ingredients.validation.maxItems", { maxItems: FIELD_LIMITS.RECIPE.INGREDIENTS.MAX_NUM_ITEMS })}`
      }
    }
  })

  // TODO: Step images
  const {
    fields: instructionsFields,
    append: instructionsAppend,
    insert: instructionsInsert,
    remove: instructionsRemove
  } = useFieldArray({
    control: form.control,
    name: "instructions",
    rules: {
      required: `${t("recipes.components.form.AddEditRecipeForm.form.fields.instructions.validation.required")}`,
      minLength: {
        value: 2, // Ensures 1 field is filled out. The other will be there automatically
        message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.instructions.validation.required")}`
      },
      maxLength: {
        value: FIELD_LIMITS.RECIPE.INSTRUCTIONS.MAX_NUM_ITEMS,
        message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.instructions.validation.maxItems", { maxItems: FIELD_LIMITS.RECIPE.INSTRUCTIONS.MAX_NUM_ITEMS })}`
      }
    }
  })

  form.register("servings", {
    maxLength: {
      value: FIELD_LIMITS.RECIPE.SERVINGS.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.servings.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.SERVINGS.MAX_LENGTH })}`
    }
  })

  form.register("prepTimeHours", {
    maxLength: {
      value: FIELD_LIMITS.RECIPE.PREP_TIME.HOURS.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.prepTime.fields.hours.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.PREP_TIME.HOURS.MAX_LENGTH })}`
    }
  })

  form.register("prepTimeMinutes", {
    maxLength: {
      value: FIELD_LIMITS.RECIPE.PREP_TIME.MINUTES.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.prepTime.fields.minutes.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.PREP_TIME.MINUTES.MAX_LENGTH })}`
    }
  })

  form.register("cookTimeHours", {
    maxLength: {
      value: FIELD_LIMITS.RECIPE.COOK_TIME.HOURS.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.cookTime.fields.hours.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.COOK_TIME.HOURS.MAX_LENGTH })}`
    }
  })

  form.register("cookTimeMinutes", {
    maxLength: {
      value: FIELD_LIMITS.RECIPE.COOK_TIME.MINUTES.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.cookTime.fields.minutes.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.COOK_TIME.MINUTES.MAX_LENGTH })}`
    }
  })

  form.register("notes", {
    maxLength: {
      value: FIELD_LIMITS.RECIPE.NOTES.MAX_LENGTH,
      message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.notes.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.NOTES.MAX_LENGTH })}`
    }
  })

  const watchIngredients = form.watch("ingredients")
  const watchInstructions = form.watch("instructions")

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const {
      title,
      image,
      description,
      ingredients,
      instructions,
      servings,
      prepTimeHours,
      prepTimeMinutes,
      cookTimeHours,
      cookTimeMinutes,
      notes
    } = values

    try {
      // Just in case the uid is missing
      if(!user?.uid) {
        throw new Error("User is missing uid!")
      }

      const id = isEditing && recipe ? recipe.id : uuidv4()

      // @ts-ignore typescript doesn't realize these params come back as strings
      let totalHours = parseInt(prepTimeHours || 0) + parseInt(cookTimeHours || 0)
      // @ts-ignore typescript doesn't realize these params come back as strings
      let totalMinutes = parseInt(prepTimeMinutes || 0) + parseInt(cookTimeMinutes || 0)

      // Check if total minutes are more than 60
      if(totalMinutes >= 60) {
        // Add the extra hours to the totalHours variable
        totalHours += Math.floor(totalMinutes / 60)
        // Get the remaining minutes
        totalMinutes = totalMinutes % 60
      }

      const recipeObj: Recipe = {
        _created: isEditing && recipe ? recipe._created : serverTimestamp(),
        _updated: serverTimestamp(),
        userId: user.uid,
        id: id,
        title: title,
        description: description,
        // Ingredients are already parsed for us
        ingredients: ingredients.slice(0, -1), // Always skip the last element since it's always going to be empty
        instructions: instructions.slice(0, -1), // Always skip the last element since it's always going to be empty
        // @ts-ignore
        servings: parseInt(servings),
        prepTime: {
          // @ts-ignore
          hours: parseInt(prepTimeHours || 0),
          // @ts-ignore
          minutes: parseInt(prepTimeMinutes || 0)
        },
        cookTime: {
          // @ts-ignore
          hours: parseInt(cookTimeHours || 0),
          // @ts-ignore
          minutes: parseInt(cookTimeMinutes || 0)
        },
        totalTime: {
          hours: totalHours,
          minutes: totalMinutes
        },
        notes: notes,
        imported: isEditing && recipe ? recipe.imported : false,
        aiGenerated: isEditing && recipe ? recipe.aiGenerated : false,
        imageImported: isEditing && recipe ? recipe.imageImported : false,
        socialMediaImported: isEditing && recipe ? recipe.socialMediaImported : false
      }

      if(isEditing && recipe) {
        // If recipe has image, and image field matches url, do nothing
        // If recipe has image, but image field is empty now, delete
        // If recipe has image, but image field doesn't match, delete recipe image and upload the new one
        // If recipe has no image, and image field isn't empty, upload image
        if(recipe.image && (!image || image !== recipe?.image?.url)) {
          // @ts-ignore
          recipeObj.image = deleteField()

          try {
            await deleteRecipeImage(recipe.id, recipe.image.name)
          } catch(e) {
            console.error(e)
            // Don't do anything on fail, continue deleting the recipe
            // TODO: If something goes wrong, log this somewhere?
          }
        }
      }

      // Upload image
      if(image && image !== "" && image !== recipe?.image?.url) {
        try {
          // Image input is set to a string, but must be uploaded as a file
          const res = await fetch(image)
          const imageBlob = await res.blob()
          const imageId = uuidv4()
          const imageFile = new File([imageBlob], `${imageId}.${imageBlob.type.replace('image/', '')}`, { type: imageBlob.type })

          const uploadedImage = await uploadRecipeImage(id, imageFile)

          if(uploadedImage) {
            recipeObj.image = uploadedImage
          }
        } catch(e) {
          console.error(e)

          // @ts-ignore
          if(e && e.message) {
            form.setError("image", {
              // @ts-ignore
              message: t(e.message)
            })
          }
        }
      }

      if(isEditing) {
        await updateDoc(doc(db, "recipes", id), recipeObj)

        logEvent(analytics, 'edit_recipe', { isImported: recipeObj.imported, isAIGenerated: recipeObj.aiGenerated, isImageImported: recipeObj.imageImported, isSocialMediaImported: recipeObj.socialMediaImported })
      } else {
        await setDoc(doc(db, "recipes", id), recipeObj)

        logEvent(analytics, 'create_recipe')
      }

      navigate(`/recipes/${id}`)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "recipes.components.form.AddEditRecipeForm.form.onSubmit.generic",
        message: `${t("recipes.components.form.AddEditRecipeForm.form.onSubmit.error.generic")}`
      })
    }
  }

  if(loading) {
    return <Loading />
  }

  if(error) {
    return <ErrorLoading />
  }

  return (
    <div className="w-full max-w-xl mx-auto px-4 mb-8">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("recipes.components.form.AddEditRecipeForm.form.fields.title.label")}</FormLabel>
                <FormControl>
                  <Input placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.title.placeholder")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <ImageManager form={form} recipeImage={recipe?.image} />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("recipes.components.form.AddEditRecipeForm.form.fields.description.label")}</FormLabel>
                <FormControl>
                  <Textarea placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.description.placeholder")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="space-y-2">
            {ingredientsFields.map((ingredient, index) => (
              <FormField
                key={ingredient.id}
                control={form.control}
                name={`ingredients.${index}.name`}
                render={({ field }) => (
                  <FormItem>
                    {index === 0 && (
                      <>
                        <FormLabel className={form.formState.errors.ingredients?.root && 'text-destructive'}>{t("recipes.components.form.AddEditRecipeForm.form.fields.ingredients.label")}</FormLabel>

                        <FormDescription className="!mt-0">
                          {t("recipes.components.form.AddEditRecipeForm.form.fields.ingredients.description")}
                        </FormDescription>
                      </>
                    )}

                    <FormControl>
                      <div className="relative">
                        <Input
                          id={ingredient.id}
                          className={`pr-10 ${watchIngredients[index].isGroupHeader ? "font-semibold" : ""}`}
                          placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.ingredients.placeholder")}
                          {...form.register(`ingredients.${index}.name`, {
                            onChange: (e) => {
                              // Add a new field when user enters something in an empty field
                              if(index + 1 === ingredientsFields.length && ingredient.name === "") {
                                ingredientsAppend({ ...defaultIngredient, id: uuidv4() }, { shouldFocus: false })
                              }

                              // Parse ingredient and update its values
                              const parsedIngredient = parseIngredient(e.target.value)
                              // More performant to do it this way than to pass the whole object: https://react-hook-form.com/docs/useform/setvalue
                              form.setValue(`ingredients.${index}.quantity`, parsedIngredient[0].quantity)
                              form.setValue(`ingredients.${index}.quantity2`, parsedIngredient[0].quantity2)
                              form.setValue(`ingredients.${index}.unitOfMeasure`, parsedIngredient[0].unitOfMeasure)
                              form.setValue(`ingredients.${index}.unitOfMeasureID`, parsedIngredient[0].unitOfMeasureID)
                              form.setValue(`ingredients.${index}.description`, parsedIngredient[0].description)
                              form.setValue(`ingredients.${index}.isGroupHeader`, parsedIngredient[0].isGroupHeader)
                            }
                          })}

                          // TODO: Add some indication to the user that they can do this?
                          // Handle pasting multiple ingredients at once
                          onPaste={(e) => {
                            const clipboardData = e.clipboardData
                            const pastedData = clipboardData.getData("Text")

                            let ingredientsArr = pastedData.replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/)
                            ingredientsArr = ingredientsArr.filter(item => item !== "") // Filter out empty items

                            // If there are multiple items separated by a line break, insert them into their own field
                            if(ingredientsArr.length > 1) {
                              // Insert ingredients at the correct spot
                              ingredientsArr.map((ingredient, i) => {
                                const parsedIngredient = parseIngredient(ingredient)
                                ingredientsInsert(index + 1 + i, {
                                  ...parsedIngredient[0],
                                  id: uuidv4(),
                                  name: ingredient
                                }, { shouldFocus: false })
                              })

                              // Remove the field pasted into which contains the whole line separated text that we just moved to separate fields
                              ingredientsRemove(index)

                              // If ingredients were pasted in the last field, make sure we add another empty field to the end
                              if(index + 1 === ingredientsFields.length) {
                                ingredientsAppend({ ...defaultIngredient, id: uuidv4() }, { shouldFocus: false })
                              }
                            }
                          }}

                          // Focus next ingredient field on enter
                          onKeyDown={(e) => {
                            if(e.key === "Enter") {
                              e.preventDefault()
                              form.setFocus(`ingredients.${index + 1}.name`)
                            }
                          }}
                        />

                        {index + 1 < ingredientsFields.length && (
                          <Button
                            type="button"
                            size="icon"
                            variant="ghost"
                            className="absolute right-0 top-0 bottom-0"
                            onClick={() => ingredientsRemove(index)}
                          >
                            <X size={20} className="text-muted-foreground" />
                          </Button>
                        )}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}

            {form.formState.errors.ingredients?.root && (
              <p className="text-sm font-medium text-destructive">
                {form.formState.errors.ingredients?.root.message}
              </p>
            )}
          </div>

          <div className="space-y-2">
            {instructionsFields.map((instruction, index) => (
              <FormField
                key={instruction.id}
                control={form.control}
                name={`instructions.${index}.text`}
                render={({ field }) => (
                  <FormItem>
                    {index === 0 && (
                      <>
                        <FormLabel className={form.formState.errors.instructions?.root && 'text-destructive'}>{t("recipes.components.form.AddEditRecipeForm.form.fields.instructions.label")}</FormLabel>

                        <FormDescription className="!mt-0">
                          {t("recipes.components.form.AddEditRecipeForm.form.fields.instructions.description")}
                        </FormDescription>
                      </>
                    )}

                    <FormControl>
                      <div className="relative">
                        <Input
                          id={instruction.id}
                          className={`pr-10 ${watchInstructions[index].isGroupHeader ? "font-semibold" : ""}`}
                          placeholder={`${t("recipes.components.form.AddEditRecipeForm.form.fields.instructions.placeholder", { stepNumber: index + 1 })}`}
                          {...form.register(`instructions.${index}.text`, {
                            maxLength: {
                              value: FIELD_LIMITS.RECIPE.INSTRUCTIONS.TEXT.MAX_LENGTH,
                              message: `${t("recipes.components.form.AddEditRecipeForm.form.fields.instructions.validation.maxLength", { maxLength: FIELD_LIMITS.RECIPE.INSTRUCTIONS.TEXT.MAX_LENGTH })}`
                            },
                            onChange: (e) => {
                              // Add a new field when user enters something in an empty field
                              if(index + 1 === instructionsFields.length && instruction.text === "") {
                                instructionsAppend({ ...defaultInstruction, id: uuidv4() }, { shouldFocus: false })
                              }

                              // Set to group header according to the same rules from the parser (Starts with /for ./ or ends with /:/)
                              const str = e.target.value.trim().toLowerCase()

                              if(str.match(/(^for .)|(:$)/g)) {
                                form.setValue(`instructions.${index}.isGroupHeader`, true)
                              } else {
                                form.setValue(`instructions.${index}.isGroupHeader`, false)
                              }
                            }
                          })}

                          // TODO: Add some indication to the user that they can do this?
                          // Handle pasting multiple instructions at once
                          onPaste={(e) => {
                            const clipboardData = e.clipboardData
                            const pastedData = clipboardData.getData("Text")

                            let instructionsArr = pastedData.replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/)
                            instructionsArr = instructionsArr.filter(item => item !== "") // Filter out empty items

                            // If there are multiple items separated by a line break, insert them into their own field
                            if(instructionsArr.length > 1) {
                              // Insert instructions at the correct spot
                              instructionsArr.map((instruction, i) => {
                                instructionsInsert(index + 1 + i, {
                                  ...defaultInstruction,
                                  id: uuidv4(),
                                  text: instruction
                                }, { shouldFocus: false })
                              })

                              // Remove the field pasted into which contains the whole line separated text that we just moved to separate fields
                              instructionsRemove(index)

                              // If instructions were pasted in the last field, make sure we add another empty field to the end
                              if(index + 1 === instructionsFields.length) {
                                instructionsAppend({ ...defaultInstruction, id: uuidv4() }, { shouldFocus: false })
                              }
                            }
                          }}

                          // Focus next instruction field on enter
                          onKeyDown={(e) => {
                            if(e.key === "Enter") {
                              e.preventDefault()
                              form.setFocus(`instructions.${index + 1}.text`)
                            }
                          }}
                        />

                        {index + 1 < instructionsFields.length && (
                          <Button
                            type="button"
                            size="icon"
                            variant="ghost"
                            className="absolute right-0 top-0 bottom-0"
                            onClick={() => instructionsRemove(index)}
                          >
                            <X size={20} className="text-muted-foreground" />
                          </Button>
                        )}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}

            {form.formState.errors.instructions?.root && (
              <p className="text-sm font-medium text-destructive">
                {form.formState.errors.instructions?.root.message}
              </p>
            )}
          </div>

          <FormField
            control={form.control}
            name="servings"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("recipes.components.form.AddEditRecipeForm.form.fields.servings.label")}</FormLabel>
                <FormControl>
                  <Input type="number" min={0} placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.servings.placeholder")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="space-y-2">
            <FormLabel>{t("recipes.components.form.AddEditRecipeForm.form.fields.prepTime.label")}</FormLabel>

            <div className="flex items-start gap-2">
              <FormField
                control={form.control}
                name="prepTimeHours"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="number" min={0} placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.prepTime.fields.hours.placeholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="prepTimeMinutes"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="number" min={0} placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.prepTime.fields.minutes.placeholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="space-y-2">
            <FormLabel>{t("recipes.components.form.AddEditRecipeForm.form.fields.cookTime.label")}</FormLabel>

            <div className="flex items-start gap-2">
              <FormField
                control={form.control}
                name="cookTimeHours"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="number" min={0} placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.cookTime.fields.hours.placeholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="cookTimeMinutes"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input type="number" min={0} placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.cookTime.fields.minutes.placeholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("recipes.components.form.AddEditRecipeForm.form.fields.notes.label")}</FormLabel>
                <FormControl>
                  <Textarea placeholder={t("recipes.components.form.AddEditRecipeForm.form.fields.notes.placeholder")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            variant="primary"
            className="w-full"
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? (
              <Loader2 size={24} className="animate-spin" />
            ) : (
              <>
                {isEditing ? t("recipes.components.form.AddEditRecipeForm.form.actions.submit.edit")
                  : t("recipes.components.form.AddEditRecipeForm.form.actions.submit.add")}
              </>
            )}
          </Button>

          <FormRootErrorMessage />
        </form>
      </Form>
    </div>
  )
}