import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Slider } from "@/components/ui/slider.tsx";

import { GenerateRecipeFormInputs } from "@/routes/recipes/generate/types";

type Props = {
  form: UseFormReturn<GenerateRecipeFormInputs>
}
export default function Options({ form }: Props) {
  const { t } = useTranslation()

  form.register("cuisine", {
    maxLength: {
      value: 500,
      message: `${t("recipes.generate.components.steps.Options.fields.cuisine.validation.maxLength", { maxLength: 500 })}`
    }
  })

  form.register("mealType")

  form.register("skillLevel", { valueAsNumber: true })
  const watchSkillLevel = form.watch('skillLevel')

  form.register("time", { valueAsNumber: true })
  const watchTime = form.watch('time')

  form.register("spiceLevel", { valueAsNumber: true })
  const watchSpiceLevel = form.watch('spiceLevel')

  let spiceLevelRangeColor = 'bg-primary'
  let spiceLevelThumbColor = 'border-primary'
  if(watchSpiceLevel == 0) {
    spiceLevelRangeColor = 'bg-blue-500'
    spiceLevelThumbColor = 'border-blue-500'
  } else if(watchSpiceLevel == 1) {
    spiceLevelRangeColor = 'bg-yellow-500'
    spiceLevelThumbColor = 'border-yellow-500'
  } else if(watchSpiceLevel == 2) {
    spiceLevelRangeColor = 'bg-amber-500'
    spiceLevelThumbColor = 'border-amber-500'
  } else if(watchSpiceLevel == 3) {
    spiceLevelRangeColor = 'bg-orange-500'
    spiceLevelThumbColor = 'border-orange-500'
  } else if(watchSpiceLevel == 4) {
    spiceLevelRangeColor = 'bg-red-500'
    spiceLevelThumbColor = 'border-red-500'
  }

  return (
    <>
      <FormField
        control={form.control}
        name="cuisine"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("recipes.generate.components.steps.Options.fields.cuisine.label")}</FormLabel>
            <FormControl>
              <Input placeholder={t("recipes.generate.components.steps.Options.fields.cuisine.placeholder")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="mealType"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("recipes.generate.components.steps.Options.fields.mealType.label")}</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder={t("recipes.generate.components.steps.Options.fields.mealType.placeholder")} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value={t('recipes.generate.components.steps.Options.fields.mealType.options.any.value')}>
                  {t('recipes.generate.components.steps.Options.fields.mealType.options.any.label')}
                </SelectItem>
                <SelectItem value={t('recipes.generate.components.steps.Options.fields.mealType.options.breakfast.value')}>
                  {t('recipes.generate.components.steps.Options.fields.mealType.options.breakfast.label')}
                </SelectItem>
                <SelectItem value={t('recipes.generate.components.steps.Options.fields.mealType.options.lunch.value')}>
                  {t('recipes.generate.components.steps.Options.fields.mealType.options.lunch.label')}
                </SelectItem>
                <SelectItem value={t('recipes.generate.components.steps.Options.fields.mealType.options.dinner.value')}>
                  {t('recipes.generate.components.steps.Options.fields.mealType.options.dinner.label')}
                </SelectItem>
                <SelectItem value={t('recipes.generate.components.steps.Options.fields.mealType.options.snack.value')}>
                  {t('recipes.generate.components.steps.Options.fields.mealType.options.snack.label')}
                </SelectItem>
                <SelectItem value={t('recipes.generate.components.steps.Options.fields.mealType.options.drink.value')}>
                  {t('recipes.generate.components.steps.Options.fields.mealType.options.drink.label')}
                </SelectItem>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="skillLevel"
        render={({ field }) => (
          <FormItem>
            <div className="flex items-center justify-between">
              <FormLabel>{t("recipes.generate.components.steps.Options.fields.skillLevel.label")}</FormLabel>

              <span className="text-right">
                {watchSkillLevel == 1 && t("recipes.generate.components.steps.Options.fields.skillLevel.options.1")}
                {watchSkillLevel == 2 && t("recipes.generate.components.steps.Options.fields.skillLevel.options.2")}
                {watchSkillLevel == 3 && t("recipes.generate.components.steps.Options.fields.skillLevel.options.3")}
                {watchSkillLevel == 4 && t("recipes.generate.components.steps.Options.fields.skillLevel.options.4")}
              </span>
            </div>
            <FormControl>
              <Slider
                defaultValue={[field.value]}
                min={1}
                max={4}
                step={1}
                onChange={field.onChange}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="time"
        render={({ field }) => (
          <FormItem>
            <div className="flex items-center justify-between">
              <FormLabel>{t("recipes.generate.components.steps.Options.fields.time.label")}</FormLabel>

              <span className="text-right">
                {watchTime == 15 && t("recipes.generate.components.steps.Options.fields.time.options.15")}
                {watchTime == 30 && t("recipes.generate.components.steps.Options.fields.time.options.30")}
                {watchTime == 45 && t("recipes.generate.components.steps.Options.fields.time.options.45")}
                {watchTime == 60 && t("recipes.generate.components.steps.Options.fields.time.options.60")}
                {watchTime == 75 && t("recipes.generate.components.steps.Options.fields.time.options.75")}
              </span>
            </div>
            <FormControl>
              <Slider
                defaultValue={[field.value]}
                min={15}
                max={75}
                step={15}
                onChange={field.onChange}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="spiceLevel"
        render={({ field }) => (
          <FormItem>
            <div className="flex items-center justify-between">
              <FormLabel>{t("recipes.generate.components.steps.Options.fields.spiceLevel.label")}</FormLabel>

              <span className="text-right">
                {watchSpiceLevel == 0 && t("recipes.generate.components.steps.Options.fields.spiceLevel.options.0")}
                {watchSpiceLevel == 1 && t("recipes.generate.components.steps.Options.fields.spiceLevel.options.1")}
                {watchSpiceLevel == 2 && t("recipes.generate.components.steps.Options.fields.spiceLevel.options.2")}
                {watchSpiceLevel == 3 && t("recipes.generate.components.steps.Options.fields.spiceLevel.options.3")}
                {watchSpiceLevel == 4 && t("recipes.generate.components.steps.Options.fields.spiceLevel.options.4")}
                {watchSpiceLevel == 5 && t("recipes.generate.components.steps.Options.fields.spiceLevel.options.5")}
              </span>
            </div>

            <FormControl>
              <Slider
                defaultValue={[field.value]}
                min={0}
                max={5}
                step={1}
                onChange={field.onChange}
                rangeColor={spiceLevelRangeColor}
                thumbColor={spiceLevelThumbColor}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}