import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { db } from "@/firebase/app.ts"
import { serverTimestamp, doc, updateDoc, arrayUnion } from "firebase/firestore"
import { uuidv4 } from "@firebase/util"

import { SearchResponseHit } from "typesense/src/Typesense/Documents.ts";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";

import AddItemForm from "@/routes/lists/[id]/components/AddItemForm.tsx";
import AddItemAutocomplete from "@/routes/lists/[id]/components/AddItemAutocomplete.tsx";

import { FIELD_LIMITS } from "@/globals.js";

import { Plus } from "lucide-react";

import { Ingredient, List, ListItem } from "@/firebase/types.ts"

type Props = {
  list: List
}
export default function AddItem({ list }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [hits, setHits] = useState<SearchResponseHit<Ingredient>[] | undefined>(undefined)
  const [lastItemAdded, setLastItemAdded] = useState<string | null>(null)

  const formSchema = z.object({
    itemName: ListItem.shape.name
      .max(FIELD_LIMITS.INGREDIENT.NAME.MAX_LENGTH, {
        message: t("lists.[id].components.AddItem.modal.form.fields.itemName.validation.maxLength", { maxLength: FIELD_LIMITS.INGREDIENT.NAME.MAX_LENGTH })
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      itemName: ""
    }
  })

  const watchItemName = form.watch("itemName")

  async function addItemToList(newItem: Partial<List["items"][0]>) {
    try {
      // TODO: Better ingredient parsing
      // Add the fields we need and let newItem override them
      const listItemObj: List["items"][0] = {
        id: uuidv4(),
        name: "",
        checked: false,
        quantity: null,
        quantity2: null,
        unitOfMeasure: null,
        unitOfMeasureID: null,
        description: "",
        isGroupHeader: false,
        notes: ""
      }
      const finalItem: List["items"][0] = { ...listItemObj, ...newItem }

      if(hits) {
        const matchedHit = hits.find(hit => hit.document.name.toLowerCase() === finalItem.name.toLowerCase())
        if(matchedHit) {
          finalItem.name = matchedHit.document.name
          finalItem.category_id = matchedHit.document.category_id
        }
      }

      await updateDoc(doc(db, "lists", list.id), {
        _updated: serverTimestamp(),
        items: arrayUnion(finalItem)
      })

      setLastItemAdded(finalItem.name)

      form.reset()

      form.setFocus("itemName")
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "AddItem.onSubmit.generic",
        message: t('lists.[id].components.AddItem.modal.form.onSubmit.error.generic')
      })
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { itemName } = values

    if(itemName !== '') {
      const newItem = {
        name: itemName.trim(),
      }

      await addItemToList(newItem)
    }
  }

  return (
    <Dialog
      onOpenChange={(open) => {
        if(!open) {
          setLastItemAdded(null)
          form.reset()
        }
      }}
    >
      <DialogTrigger asChild>
        {isMobile ? (
          <Button
            variant="primary"
            size="icon"
            className="floating-action-btn w-12 h-12"
          >
            <Plus size={28} />
          </Button>
        ) : (
          <Button
            variant="primary"
            className="gap-2"
          >
            <Plus size={20} />
            {t("lists.[id].components.AddItem.button")}
          </Button>
        )}
      </DialogTrigger>

      <DialogContent className="flex flex-col w-full max-lg:h-dvh lg:top-1/4 lg:translate-y-0">
        <DialogHeader>
          <DialogTitle>{t("lists.[id].components.AddItem.modal.title")}</DialogTitle>
        </DialogHeader>

        <div className="py-4 space-y-2">
          <AddItemForm form={form} onSubmit={onSubmit} lastItemAdded={lastItemAdded} />

          <AddItemAutocomplete
            hits={hits}
            setHits={setHits}
            query={watchItemName}
            addItemToList={addItemToList}
            listItems={list.items}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}