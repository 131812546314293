/****************************************************************

 NOTE: This file must be kept in sync with Firestore rules!

 Currently, the MAX_NUM_ITEMS are the only variables used in Firebase rules.
 It is not necessary to keep the field limits in Firebase rules.

 ****************************************************************/

export const RECIPE_GENERATIONS = {
  FREE: 5,
  PREMIUM: Infinity
}

export const RECIPE_IMAGE_IMPORTS = {
  FREE: 5,
  PREMIUM: Infinity
}

export const RECIPE_SOCIAL_MEDIA_IMPORTS = {
  FREE: 5,
  PREMIUM: Infinity
}

export const FIELD_LIMITS = {
  USER_ACCOUNT: {
    PASSWORD: {
      MIN_LENGTH: 8,
      MAX_LENGTH: 50
    }
  },

  RECIPE: {
    // Fields
    TITLE: {
      MAX_LENGTH: 500
    },

    DESCRIPTION: {
      MAX_LENGTH: 5000
    },

    INGREDIENTS: {
      MAX_NUM_ITEMS: 100,

      // Fields added after object initialization
    },

    INSTRUCTIONS: {
      // Max number of steps allowed in a single recipe
      MAX_NUM_ITEMS: 100,

      // Fields
      TEXT: {
        MAX_LENGTH: 2000
      }
    },

    PREP_TIME: {
      HOURS: {
        MAX_LENGTH: 3
      },
      MINUTES: {
        MAX_LENGTH: 5
      }
    },

    COOK_TIME: {
      HOURS: {
        MAX_LENGTH: 3
      },
      MINUTES: {
        MAX_LENGTH: 5
      }
    },

    TOTAL_TIME: {
      HOURS: {
        MAX_LENGTH: 3
      },
      MINUTES: {
        MAX_LENGTH: 5
      }
    },

    SERVINGS: {
      MAX_LENGTH: 5
    },

    NOTES: {
      MAX_LENGTH: 5000
    }
  },

  COLLECTION: {
    TITLE: {
      MAX_LENGTH: 500
    }
  },

  INGREDIENT: {
    NAME: {
      MAX_LENGTH: 2000
    }
  },

  LIST: {
    TITLE: {
      MAX_LENGTH: 500
    },

    ITEMS: {
      // Fields
      NOTES: {
        MAX_LENGTH: 5000
      }
    }
  }
}

FIELD_LIMITS.RECIPE.INGREDIENTS = { ...FIELD_LIMITS.RECIPE.INGREDIENTS, ...FIELD_LIMITS.INGREDIENT }
FIELD_LIMITS.LIST.ITEMS = { ...FIELD_LIMITS.LIST.ITEMS, ...FIELD_LIMITS.INGREDIENT }