import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"

import { AlertCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useUserData from "@/hooks/useUserData.tsx";
import { Timestamp } from "firebase/firestore"

export default function PricingChangeNotice() {
  // const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [alertDismissed, setAlertDismissed] = useState(false)

  const { userData } = useUserData()
  const userAccountCreated = userData?._created as Timestamp
  const pricingChangeDate = new Date("August 4, 2024")

  useEffect(() => {
    setAlertDismissed(!!(localStorage.getItem('aug-3-2024-pricing-change-notice-dismissed')))
  }, [])

  return (
    <>
      {/* Only show this notice to users created on or before August 3rd. Don't show it if they've dismissed it either */}
      {userData && userAccountCreated && userAccountCreated.toDate() < pricingChangeDate && !alertDismissed && (
        <div className="p-4 w-full max-w-7xl mx-auto">
          <Alert>
            <AlertCircle size={20} />
            <AlertTitle>Free tier pricing change</AlertTitle>
            <AlertDescription className="flex flex-col lg:flex-row lg:justify-between items-start gap-4">
              We're adjusting our free tier pricing.

              <div className="flex gap-2">
                <Button
                  type="button"
                  size="sm"
                  // onClick={() => setIsDialogOpen(true)}
                  asChild
                >
                  <Link
                    to="https://www.flavorish.ai/blog/august-3-2024-free-tier-pricing-change"
                    target="_blank"
                  >
                    Learn more
                  </Link>
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    localStorage.setItem('aug-3-2024-pricing-change-notice-dismissed', "true")
                    setAlertDismissed(true)
                  }}
                >
                  Don't show this again
                </Button>
              </div>
            </AlertDescription>
          </Alert>

          {/*<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>*/}
          {/*  <DialogContent className="sm:max-w-[425px]">*/}
          {/*    <DialogHeader>*/}
          {/*      <DialogTitle>August 3, 2024 Free Tier Pricing Change</DialogTitle>*/}
          {/*    </DialogHeader>*/}

          {/*    <div className="space-y-4 mb-4">*/}
          {/*      <p>*/}
          {/*        Starting on August 3, 2024 the free plan will no longer have 5 free AI recipe generations per month. Instead, all free accounts will now have 5 free AI recipe generations per account.*/}
          {/*      </p>*/}

          {/*      <p>*/}
          {/*        Any accounts that have already used some or all of their free monthly AI recipe generations will still get 5 more free generations credited to their account. For unlimited AI recipe generations, you can still upgrade to Flavorish Premium.*/}
          {/*      </p>*/}

          {/*      <p>*/}
          {/*        If you have any questions, please contact us at <Link to="mailto:hello@flavorish.ai" target="_blank" className="link">hello@flavorish.ai</Link>.*/}
          {/*      </p>*/}
          {/*    </div>*/}

          {/*    <DialogFooter>*/}
          {/*      <Button*/}
          {/*        type="button"*/}
          {/*        onClick={() => {*/}
          {/*          setIsDialogOpen(false)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        Dismiss*/}
          {/*      </Button>*/}
          {/*    </DialogFooter>*/}
          {/*  </DialogContent>*/}
          {/*</Dialog>*/}
        </div>
      )}
    </>
  )
}