import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/button.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";

import { Recipe } from "@/firebase/types"

type Props = {
  description?: Recipe["description"]
}
export default function Description({ description }: Props) {
  const { t } = useTranslation()

  const descriptionRef = useRef(null)
  const [showAll, setShowAll] = useState(false)
  const [isClampable, setIsClampable] = useState(false)

  useEffect(() => {
    if(descriptionRef && descriptionRef.current) {
      // @ts-ignore
      setIsClampable(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight)
    }
  }, [])

  return (
    <>
      {description ? (
        <div>
          <p ref={descriptionRef} className={`whitespace-pre-wrap break-words truncate ${showAll ? '' : 'line-clamp-3'}`}>
            {description}
          </p>

          {isClampable && (
            <Button
              type="button"
              variant="link"
              size="sm"
              className="p-0"
              onClick={() => setShowAll((prevState) => !prevState)}
            >
              {showAll ? t('recipes.[id].components.recipe.Description.showLess') : t('recipes.[id].components.recipe.Description.showAll')}
            </Button>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <Skeleton className="w-full h-4" />
          <Skeleton className="w-full h-4" />
          <Skeleton className="w-3/4 h-4" />
        </div>
      )}
    </>
  )
}