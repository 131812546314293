import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Capacitor } from "@capacitor/core";

import useUserData from "@/hooks/useUserData.tsx";
import useUserSubscriptionStatus from "@/hooks/useUserSubscriptionStatus.tsx";

import IsSupportedSocialMediaUrl from "@/lib/helpers/IsSupportedSocialMediaUrl.ts";

import { RECIPE_SOCIAL_MEDIA_IMPORTS } from "@/globals.js";

import Page from "@/components/page/Page.tsx";
import Header from "@/components/navigation/Header.tsx";

import FreeRecipeSocialMediaImportsUsed from "@/routes/recipes/components/general/FreeRecipeSocialMediaImportsUsed.tsx";
import GuidesListing from "@/routes/recipes/import-social-media/components/GuidesListing.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { DialogFooter } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Separator } from "@/components/ui/separator.tsx";

import { Loader2, Grid2x2Check } from "lucide-react";

import Instagram from "@/assets/images/logos/instagram.svg"
import Tiktok from "@/assets/images/logos/tiktok.svg"
import TiktokDark from "@/assets/images/logos/tiktok-dark.svg"
import Youtube from "@/assets/images/logos/youtube.png"

export default function ImportSocialMedia() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { isUserSubscribed, loading: loadingUserSubscriptionStatus } = useUserSubscriptionStatus()
  const { userData } = useUserData()

  const freeRecipeSocialMediaImportsUsed = userData?.freeRecipeSocialMediaImportsUsed || 0
  const freeRecipeSocialMediaImportsLimitReached = !loadingUserSubscriptionStatus && !isUserSubscribed && freeRecipeSocialMediaImportsUsed >= RECIPE_SOCIAL_MEDIA_IMPORTS.FREE

  const formSchema = z.object({
    url: z.string().url()
      .min(1, {
        message: t("recipes.importSocialMedia.page.form.fields.url.validation.required")
      })
      .refine((url) => {
        return IsSupportedSocialMediaUrl(url)
      }, {
        message: t("recipes.importSocialMedia.page.form.fields.url.validation.supportedDomains")
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: "",
    }
  })

  // Autofill url if `link` search param exists
  useEffect(() => {
    const sharedLink = searchParams.get('link')

    if(sharedLink) {
      form.setValue('url', sharedLink)

      // Delete link from search params so it doesn't run again
      searchParams.delete('link')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { url } = values

    try {
      navigate(`/recipes/import-social-media/loading`, {
        state: {
          fields: {
            url: url
          }
        }
      })
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("recipes.importSocialMedia.page.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <Page>
      <Helmet>
        <title>{t("recipes.importSocialMedia.page.head.title")}</title>
      </Helmet>

      <Header goBackURL="/recipes" />

      <div className="flex flex-col flex-grow w-full max-w-4xl mx-auto">
        {!loadingUserSubscriptionStatus && !isUserSubscribed && (
          <FreeRecipeSocialMediaImportsUsed freeRecipeSocialMediaImportsUsed={freeRecipeSocialMediaImportsUsed} />
        )}

        <div className="p-4">
          <div className="max-w-2xl mx-auto mb-8">
            <h2 className="flex flex-wrap justify-center items-center gap-2 font-semibold text-2xl text-center mb-2">
              <Grid2x2Check size={28} style={{ stroke: "url(#ai-gradient)" }} className="flex-shrink-0" />
              {t("recipes.importSocialMedia.page.title")}
            </h2>
            <p className="text-center">{t("recipes.importSocialMedia.page.description")}</p>
            <p className="text-center">{t("recipes.importSocialMedia.page.description2")}</p>

            <div className="flex justify-center items-center gap-3 my-2">
              <img src={Instagram} alt="Instagram" className="max-w-6 max-h-6" />
              <img src={Tiktok} alt="Tiktok" className="dark:hidden max-w-6 max-h-6" />
              <img src={TiktokDark} alt="Tiktok" className="hidden dark:block max-w-6 max-h-6" />
              <img src={Youtube} alt="Youtube" className="max-w-10 max-h-6" />
            </div>
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("recipes.importSocialMedia.page.form.fields.url.label")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("recipes.importSocialMedia.page.form.fields.url.placeholder")}
                        disabled={form.formState.isSubmitting || freeRecipeSocialMediaImportsLimitReached}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full"
                  disabled={form.formState.isSubmitting || freeRecipeSocialMediaImportsLimitReached}
                >
                  {form.formState.isSubmitting ? (
                    <Loader2 size={24} className="animate-spin" />
                  ) : t("recipes.importSocialMedia.page.form.actions.save")}
                </Button>
              </DialogFooter>

              <FormRootErrorMessage />
            </form>
          </Form>

          {Capacitor.isNativePlatform() && (
            <>
              <Separator className="my-8" />

              <GuidesListing />
            </>
          )}
        </div>
      </div>
    </Page>
  )
}