import { useTranslation } from "react-i18next";

import BookLoverImage from "@/assets/images/Book Lover.svg";

export default function NoCollections() {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col flex-grow justify-center items-center mb-20">
      <img src={BookLoverImage} alt={t("collections.components.general.NoCollections.image.alt")} className="w-72 lg:w-auto lg:max-w-xs" />
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-2">
          {t("collections.components.general.NoCollections.title")}
        </h2>
      </div>
    </div>
  )
}