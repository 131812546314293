import { Trans, useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import Tiktok from "@/assets/images/logos/tiktok.svg";
import TiktokDark from "@/assets/images/logos/tiktok-dark.svg";
import androidTT1 from "@/assets/images/import-social-media-guides/android/TT 1.gif"
import androidTT2 from "@/assets/images/import-social-media-guides/android/TT 2.gif"
import androidTT3 from "@/assets/images/import-social-media-guides/android/TT 3.gif"
import iosTT1 from "@/assets/images/import-social-media-guides/ios/TT 1.gif"
import iosTT2 from "@/assets/images/import-social-media-guides/ios/TT 2.gif"
import iosTT3 from "@/assets/images/import-social-media-guides/ios/TT 3.gif"

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

export default function ImportTiktokGuide({ isOpen = false, setIsOpen }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-h-dvh overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <img src={Tiktok} alt="Tiktok" className="dark:hidden max-w-5 max-h-5" />
            <img src={TiktokDark} alt="Tiktok" className="hidden dark:block max-w-5 max-h-5" />
            {t("recipes.importSocialMedia.components.ImportTiktokGuide.title")}
          </DialogTitle>
        </DialogHeader>

        <div className="mt-4 space-y-8">
          {Capacitor.getPlatform() === 'android' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.android.step1.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.android.step1.text")}</p>
                <img src={androidTT1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.android.step2.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.android.step2.text")}</p>
                <img src={androidTT2} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.android.step3.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.android.step3.text")}</p>
                <img src={androidTT3} alt="" className="my-4" />
              </div>

              <p className="text-sm">
                <Trans i18nKey="recipes.importSocialMedia.components.ImportTiktokGuide.content.android.note">
                  <strong>Note:</strong> We can only create exact recipe matches from TikTok videos that include the full recipe in the description. If the description doesn't provide a complete recipe, our AI will generate one based on available information such as dish name and hashtags.
                </Trans>
              </p>
            </>
          )}

          {Capacitor.getPlatform() === 'ios' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.ios.step1.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.ios.step1.text")}</p>
                <img src={iosTT1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.ios.step2.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.ios.step2.text")}</p>
                <img src={iosTT2} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.ios.step3.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportTiktokGuide.content.ios.step3.text")}</p>
                <img src={iosTT3} alt="" className="my-4" />
              </div>

              <p className="text-sm">
                <Trans i18nKey="recipes.importSocialMedia.components.ImportTiktokGuide.content.ios.note">
                  <strong>Note:</strong> We can only create exact recipe matches from TikTok videos that include the full recipe in the description. If the description doesn't provide a complete recipe, our AI will generate one based on available information such as dish name and hashtags.
                </Trans>
              </p>
            </>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="primary">
              {t("recipes.importSocialMedia.components.ImportTiktokGuide.actions.close")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}