import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import ImportRecipe from "@/routes/recipes/components/modals/ImportRecipe.tsx";

import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu.tsx";

import AIStars from "@/components/icons/ai-stars/AIStars.tsx";

import { Camera, CirclePlus, Plus, Link as LuLink, Grid2x2Check } from "lucide-react";

export default function AddRecipe() {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

  const [showImportRecipeModal, setShowImportRecipeModal] = useState(false)

  const GenerateAIRecipeButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      asChild
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
    >
      <Link to="/recipes/generate">
        <AIStars size={isMobile ? 24 : 20} />
        {t("recipes.components.AddRecipe.content.options.generateAIRecipe")}
      </Link>
    </Button>
  )

  const ImportRecipeFromImageButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      asChild
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
    >
      <Link to="/recipes/import-image">
        <Camera size={isMobile ? 24 : 20} style={{ stroke: "url(#ai-gradient)" }} />
        {t("recipes.components.AddRecipe.content.options.importRecipeFromImage")}
        <Badge className="bg-red-500 hover:bg-red-500/80">New</Badge>
      </Link>
    </Button>
  )

  const ImportRecipeFromSocialMediaButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      asChild
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
    >
      <Link to="/recipes/import-social-media">
        <Grid2x2Check size={isMobile ? 24 : 20} style={{ stroke: "url(#ai-gradient)" }} />
        {t("recipes.components.AddRecipe.content.options.importRecipeFromSocialMedia")}
        <Badge className="bg-red-500 hover:bg-red-500/80">New</Badge>
      </Link>
    </Button>
  )

  const ImportRecipeButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={() => {
        setShowImportRecipeModal(true)
        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <LuLink size={isMobile ? 24 : 20} />
      {t("recipes.components.AddRecipe.content.options.importRecipe")}
    </Button>
  )

  const AddRecipeButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      asChild
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
    >
      <Link to="/recipes/add">
        <CirclePlus size={isMobile ? 24 : 20} />
        {t("recipes.components.AddRecipe.content.options.addRecipe")}
      </Link>
    </Button>
  )

  return (
    <>
      {isMobile ? (
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          <DrawerTrigger asChild>
            <Button
              variant="primary"
              size="icon"
              className="floating-action-btn w-12 h-12"
            >
              <Plus size={28} />
            </Button>
          </DrawerTrigger>

          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>
                {t("recipes.components.AddRecipe.content.title")}
              </DrawerTitle>
            </DrawerHeader>

            <div className="flex flex-col gap-2 mx-auto w-full py-4">
              <GenerateAIRecipeButton />
              <ImportRecipeFromImageButton />
              <ImportRecipeFromSocialMediaButton />
              <ImportRecipeButton />
              <AddRecipeButton />
            </div>
          </DrawerContent>
        </Drawer>
      ) : (
        <DropdownMenu open={dropdownMenuOpen} onOpenChange={setDropdownMenuOpen}>
          <DropdownMenuTrigger asChild>
            <Button
              variant="primary"
              className="gap-2"
            >
              <Plus size={20} />
              {t("recipes.components.AddRecipe.triggerButton.label")}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              {t("recipes.components.AddRecipe.content.title")}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              <DropdownMenuItem>
                <GenerateAIRecipeButton />
              </DropdownMenuItem>

              <DropdownMenuItem>
                <ImportRecipeFromImageButton />
              </DropdownMenuItem>

              <DropdownMenuItem>
                <ImportRecipeFromSocialMediaButton />
              </DropdownMenuItem>

              <DropdownMenuItem>
                <ImportRecipeButton />
              </DropdownMenuItem>

              <DropdownMenuItem>
                <AddRecipeButton />
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}

      <ImportRecipe
        isOpen={showImportRecipeModal}
        setIsOpen={setShowImportRecipeModal}
      />
    </>
  )
}