import { NavLink, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { auth } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";

import { BookMarked, ClipboardList, CookingPot, UserCircle } from "lucide-react";

export default function BottomNav() {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const [user] = useAuthState(auth)

  const activeClass = 'border-t-2 border-t-foreground'
  const inactiveClass = 'border-t border-input'

  if(!user) {
    return null
  }

  return (
    <div className="btm-nav fixed left-0 right-0 bottom-0 w-full flex items-start bg-background z-40">
      <NavLink
        to="/recipes"
        className={({ isActive }) =>
          [
            isActive || pathname === "/" ? activeClass : inactiveClass,
            "flex flex-col gap-1 justify-center items-center flex-grow w-full text-sm font-normal p-2"
          ].join(" ")
        }
      >
        <CookingPot size={20} />
        {t("components.navigation.BottomNav.links.recipes")}
      </NavLink>

      <NavLink
        to="/collections"
        className={({ isActive }) =>
          [
            isActive ? activeClass : inactiveClass,
            "flex flex-col gap-1 justify-center items-center flex-grow w-full text-sm font-normal p-2"
          ].join(" ")
        }
      >
        <BookMarked size={20} />
        {t("components.navigation.BottomNav.links.collections")}
      </NavLink>

      <NavLink
        to="/lists"
        className={({ isActive }) =>
          [
            isActive ? activeClass : inactiveClass,
            "flex flex-col gap-1 justify-center items-center flex-grow w-full text-sm font-normal p-2"
          ].join(" ")
        }
      >
        <ClipboardList size={20} />
        {t("components.navigation.BottomNav.links.lists")}
      </NavLink>

      <NavLink
        to="/account"
        className={({ isActive }) =>
          [
            isActive ? activeClass : inactiveClass,
            "flex flex-col gap-1 justify-center items-center flex-grow w-full text-sm font-normal p-2"
          ].join(" ")
        }
      >
        <Avatar className="h-[20px] w-[20px] bg-transparent">
          {user && user.photoURL && user.displayName && (
            <AvatarImage src={user.photoURL} alt={user.displayName} />
          )}
          <AvatarFallback className="bg-transparent">
            <UserCircle size={20} />
          </AvatarFallback>
        </Avatar>
        {t("components.navigation.BottomNav.links.account")}
      </NavLink>
    </div>
  )
}