import { useTranslation } from "react-i18next";

import EmptyCart1Image from "@/assets/images/Empty Cart 1.svg";

export default function NoRecipes() {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col flex-grow justify-center items-center mb-20">
      <img src={EmptyCart1Image} alt={t("collections.[id].components.general.NoRecipes.image.alt")} className="w-72 lg:w-auto lg:max-w-xs" />
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-2">
          {t("collections.[id].components.general.NoRecipes.title")}
        </h2>
      </div>
    </div>
  )
}