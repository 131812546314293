import { parseIngredient } from "parse-ingredient"
import timestring from "timestring"

import { uuidv4 } from "@firebase/util"

import { GetFavicon } from "@/lib/helpers/favicon.ts";

import { Recipe } from "@/firebase/types"

export type RecipeData = Omit<Recipe, "_created" | "_updated" | "userId" | "id">

// TODO: Rate limiting
export default async function getRecipeData(url: string) {
  if(!isValidHttpUrl(url)) {
    return "invalidURL"
  }

  try {
    // Can't fetch the url client side due to CORS so fetch it from our cloudflare worker
    // If this ever gets blocked by the recipe sites, some options:
    // 1. Save recipes to the db as we fetch them and check if they exist there first, if it does, return that, else fetch the recipe
    // 2. Figure out how to put the worker behind a proxy - https://proxiesapi.com/ || https://webscraping.ai/
    // 3. Virtual browser tools like playwright and pupeteer, even selenium might be able to be used client side to get recipes
    //    Can be a heavy load on the client. Playwright is preferred now over pupeteer. https://playwright.dev/ || https://pptr.dev/
    const res = await fetch(import.meta.env.VITE_RECIPE_IMPORTER_WORKER, {
      method: "POST",
      body: JSON.stringify({ url: url })
    })
    const html = await res.text()

    // Create parser to use on the html
    const parser = new DOMParser()
    const virtualDoc = parser.parseFromString(html, "text/html")

    // Select all script tags with type 'application/ld+json'
    const schemaScripts = virtualDoc.querySelectorAll('script[type="application/ld+json"]');

    let recipeSchema: any = null

    schemaScripts.forEach(script => {
      try {
        // Turn the schema into JSON
        // @ts-ignore Failures will be caught intentionally
        let schema = JSON.parse(script.textContent)

        // We may get an object or an array
        // If we get an array back, there should only be 1 element so just use that
        if(Array.isArray(schema) && schema.length > 0) {
          schema = schema[0]
        }

        // If there's a graph element, dig into it to return the recipe schema
        if(schema.hasOwnProperty("@graph")) {
          for(const type of schema["@graph"]) {
            if(Array.isArray(type["@type"])) {
              if(type["@type"].includes("Recipe")) {
                schema = type
              }
            } else {
              if(type["@type"] === "Recipe") {
                schema = type
              }
            }
          }
        }

        if(Array.isArray(schema["@type"])) {
          if(schema["@type"].includes("Recipe")) {
            recipeSchema = schema
          }
        } else {
          if(schema["@type"] === "Recipe") {
            recipeSchema = schema
          }
        }
      } catch(e) {
        console.error(e)
        // Ignore errors here
      }
    })

    // In case we don't have the recipe schema, error out
    if(!recipeSchema) {
      throw new Error()
    }

    // Ensure the schema type says it's a Recipe, if not, error out
    if(Array.isArray(recipeSchema["@type"]) && recipeSchema["@type"].length > 0) {
      let foundTypeRecipe = false
      for(let type of recipeSchema["@type"]) {
        if(type === "Recipe") {
          foundTypeRecipe = true
          break
        }
      }
      if(!foundTypeRecipe) {
        throw new Error()
      }
    } else if(recipeSchema["@type"] !== "Recipe") {
      throw new Error()
    }

    // Build our recipe object from the schema
    let importedRecipe: RecipeData = {
      title: cleanString(recipeSchema.name),
      ingredients: [],
      instructions: [],
      prepTime: {
        hours: recipeSchema.prepTime ? parseISO8601Duration(recipeSchema?.prepTime).hours : 0,
        minutes: recipeSchema.prepTime ? parseISO8601Duration(recipeSchema?.prepTime).minutes : 0
      },
      cookTime: {
        hours: recipeSchema.cookTime ? parseISO8601Duration(recipeSchema?.cookTime).hours : 0,
        minutes: recipeSchema.cookTime ? parseISO8601Duration(recipeSchema?.cookTime).minutes : 0
      },
      totalTime: {
        hours: recipeSchema.totalTime ? parseISO8601Duration(recipeSchema?.totalTime).hours : 0,
        minutes: recipeSchema.totalTime ? parseISO8601Duration(recipeSchema?.totalTime).minutes : 0
      },
      servings: 0,
      source: {
        name: recipeSchema?.publisher?.name,
        url: url,
        favicon: GetFavicon(url)
      },
      datePublished: recipeSchema?.datePublished,
      dateModified: recipeSchema?.dateModified,
      keywords: recipeSchema?.keywords,
      category: recipeSchema?.recipeCategory,
      cuisine: recipeSchema?.recipeCuisine,
      aggregateRating: {
        ratingValue: recipeSchema?.aggregateRating?.ratingValue,
        ratingCount: recipeSchema?.aggregateRating?.ratingCount
      },
      video: {
        thumbnailUrl: recipeSchema?.video?.thumbnailUrl,
        contentUrl: recipeSchema?.video?.contentUrl,
        embedUrl: recipeSchema?.video?.embedUrl,
        uploadDate: recipeSchema?.video?.uploadDate,
        duration: {
          hours: recipeSchema.video && recipeSchema.video.duration ? parseISO8601Duration(recipeSchema?.video?.duration).hours : 0,
          minutes: recipeSchema.video && recipeSchema.video.duration ? parseISO8601Duration(recipeSchema?.video?.duration).minutes : 0
        }
      },
      nutrition: {
        servingSize: recipeSchema?.nutrition?.servingSize,
        calories: recipeSchema?.nutrition?.calories,
        carbohydrateContent: recipeSchema?.nutrition?.carbohydrateContent,
        cholesterolContent: recipeSchema?.nutrition?.cholesterolContent,
        fatContent: recipeSchema?.nutrition?.fatContent,
        fiberContent: recipeSchema?.nutrition?.fiberContent,
        proteinContent: recipeSchema?.nutrition?.proteinContent,
        saturatedFatContent: recipeSchema?.nutrition?.saturatedFatContent,
        sodiumContent: recipeSchema?.nutrition?.sodiumContent,
        sugarContent: recipeSchema?.nutrition?.sugarContent,
        transFatContent: recipeSchema?.nutrition?.transFatContent,
        unsaturatedFatContent: recipeSchema?.nutrition?.unsaturatedFatContent
      },
      imported: true,
      aiGenerated: false,
      imageImported: false,
      socialMediaImported: false
    }

    if(recipeSchema.description) {
      importedRecipe.description = cleanString(recipeSchema.description)
    }

    if(recipeSchema.image) {
      const imageId = uuidv4()

      if(typeof recipeSchema.image === "string") { // String
        importedRecipe.image = {
          name: imageId,
          url: recipeSchema.image
        }
      } else if(Array.isArray(recipeSchema.image) && recipeSchema.image.length > 0) { // Array
        if(typeof recipeSchema.image[0] === 'object' && recipeSchema.image[0].hasOwnProperty('url')) {
          importedRecipe.image = {
            name: imageId,
            url: recipeSchema.image[0].url
          }
        } else {
          importedRecipe.image = {
            name: imageId,
            url: recipeSchema.image[0]
          }
        }
      } else { // Object
        importedRecipe.image = {
          name: imageId,
          url: recipeSchema.image.url
        }
      }
    }

    if(recipeSchema.recipeIngredient) {
      for(const ingredient of recipeSchema.recipeIngredient) {
        const parsedIngredient = parseIngredient(cleanString(ingredient))
        importedRecipe.ingredients.push({
          id: uuidv4(),
          name: cleanString(ingredient),
          ...parsedIngredient[0]
        } as Recipe["ingredients"][0])
      }
    }

    // TODO: Parse for headers
    if(recipeSchema.recipeInstructions) {
      if(typeof recipeSchema.recipeInstructions === "string") {
        importedRecipe.instructions.push({
          id: uuidv4(),
          isGroupHeader: false,
          text: recipeSchema.recipeInstructions
        } as Recipe["instructions"][0])
      } else {
        for(const instruction of recipeSchema.recipeInstructions) {
          const newInstruction = {
            id: uuidv4(),
            isGroupHeader: false
          } as Recipe["instructions"][0]

          // Instructions can be strings or objects
          if(typeof instruction === "string") {
            newInstruction.text = instruction
          } else {
            if(instruction.text) {
              newInstruction.text = cleanString(instruction.text)
            } else {
              newInstruction.text = cleanString(instruction.name)
            }

            if(instruction.url) {
              newInstruction.url = instruction.url
            }

            // Instruction images can be strings, objects, or arrays
            if(instruction.image) {
              if(typeof instruction.image === "string") {
                newInstruction.image = instruction.image
              } else if(typeof instruction.image === "object") {
                newInstruction.image = instruction.image.url
              } else {
                newInstruction.image = []

                for(const instructionImage of instruction.image) {
                  if(typeof instructionImage === "string") {
                    newInstruction.image.push(instructionImage)
                  } else {
                    newInstruction.image.push(instructionImage.url)
                  }
                }
              }
            }
          }

          importedRecipe.instructions.push(newInstruction)
        }
      }
    }

    // Some sites don't include total time, but include prep and cook time
    // In that case, make sure we get total time by adding the times together
    if(!importedRecipe.totalTime?.hours && !importedRecipe.totalTime?.minutes) {
      importedRecipe.totalTime.hours = importedRecipe.prepTime.hours + importedRecipe.cookTime.hours
      importedRecipe.totalTime.minutes = importedRecipe.prepTime.minutes + importedRecipe.cookTime.minutes
    }

    if(recipeSchema.recipeYield) {
      if(Array.isArray(recipeSchema.recipeYield)) {
        importedRecipe.servings = parseInt(recipeSchema.recipeYield.join(" "))
      } else {
        importedRecipe.servings = parseInt(recipeSchema.recipeYield)
      }
    }

    if(recipeSchema.keywords && Array.isArray(recipeSchema.keywords)) {
      importedRecipe.keywords = recipeSchema.keywords.join(', ')
    }

    if(recipeSchema.author) {
      importedRecipe.authors = []
      if(Array.isArray(recipeSchema.author)) {
        for(const author of recipeSchema.author) {
          if(author.name || author.url) {
            importedRecipe.authors.push({
              name: author?.name,
              url: author?.url
            })
          }
        }
      } else {
        importedRecipe.authors.push({
          name: recipeSchema.author?.name,
          url: recipeSchema.author?.url
        })
      }
    }

    if(recipeSchema.video && recipeSchema.video.name) {
      // @ts-ignore
      importedRecipe.video.name = cleanString(recipeSchema.video.name)
    }

    if(recipeSchema.video && recipeSchema.video.description) {
      // @ts-ignore
      importedRecipe.video.description = cleanString(recipeSchema.video.description)
    }

    // Delete undefined values - firebase doesn't allow these
    importedRecipe = JSON.parse(JSON.stringify(importedRecipe))

    return importedRecipe
  } catch(e) {
    console.error(e)

    return "genericError"
  }
}

// https://stackoverflow.com/a/43467144/7355232
function isValidHttpUrl(string) {
  let url: URL

  try {
    url = new URL(string)
  } catch(e) {
    console.error(e)

    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

var iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?(?:T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?/

function parseISO8601Duration(iso8601Duration) {
  const matches = iso8601Duration.match(iso8601DurationRegex)

  if(matches) {
    const times = {
      sign: matches[1] === undefined ? "+" : "-",
      years: matches[2] === undefined ? 0 : parseInt(matches[2]),
      months: matches[3] === undefined ? 0 : parseInt(matches[3]),
      weeks: matches[4] === undefined ? 0 : parseInt(matches[4]),
      days: matches[5] === undefined ? 0 : parseInt(matches[5]),
      hours: matches[6] === undefined ? 0 : parseInt(matches[6]),
      minutes: matches[7] === undefined ? 0 : parseInt(matches[7]),
      seconds: matches[8] === undefined ? 0 : parseInt(matches[8])
    }

    const timestringTime = timestring(`${times.years} years ${times.months} months ${times.weeks} weeks ${times.days} days ${times.hours} hours ${times.minutes} minutes ${times.seconds} seconds`)

    return {
      hours: Math.floor(timestringTime / 3600),
      minutes: Math.floor(timestringTime % 3600) / 60
    }
  } else {
    try {
      const seconds = timestring(iso8601Duration)

      return {
        hours: Math.floor(seconds / 3600),
        minutes: Math.floor(seconds % 3600) / 60
      }
    } catch(e) {
      console.error(e)

      // If timestring failed (ex. "1 hour (plus dry-brining)" returns Error: The unit [hourplusdry] is not supported by timestring)

      return {
        hours: 0,
        minutes: 0
      }
    }
  }
}

function cleanString(str: string) {
  // Remove HTML entities like &#39; https://www.w3schools.com/html/html_entities.asp
  let cleanedStr = decodeEntities(str)

  // Remove HTML tags
  cleanedStr = removeTags(cleanedStr)

  return cleanedStr
}

function removeTags(str) {
  if ((str===null) || (str===''))
    return false;
  else
    str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}

function decodeEntities(encodedString) {
  const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    "nbsp":" ",
    "amp" : "&",
    "quot": "\"",
    "lt"  : "<",
    "gt"  : ">"
  };
  return encodedString.replace(translate_re, function(match, entity) {
    return translate[entity];
  }).replace(/&#(\d+);/gi, function(match, numStr) {
    const num = parseInt(numStr, 10);
    return String.fromCharCode(num);
  });
}

// Not used but saved here since it could be used to support domains that don't have a recipe schema
// Based on onlytherecipe.org https://github.com/jpbulman/OnlyTheRecipe/blob/main/lib/recipes.ts

// import { recipeSelectors, supportedDomains } from "@/lib/recipe-importer/selectors.ts"
//
// const getRecipeDataForDomain = (url: string, domain: string, html: string): RecipeData => {
//   const parser = new DOMParser()
//   const virtualDoc = parser.parseFromString(html, "text/html")
//
//   // return recipeSelectors[domain](virtualDoc, url)
// }
//
// const domainIsSupported = (domain: string): boolean => {
//   const domainKeys = Object.keys(supportedDomains)
//   return domainKeys.find((d) => d === domain) !== undefined
// }
//
// const getDomainFromURL = (url: string): string => {
//   const urlObj = new URL(url)
//   return urlObj.hostname.replace("www.", "")
// }