import { useEffect, useRef, useState } from "react";

import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase/app.ts";
import { uuidv4 } from "@firebase/util";

import base64ImageToFile from "@/lib/helpers/base64ImageToFile.ts";
import { uploadRecipeImage } from "@/lib/images.ts";

import { parse } from "best-effort-json-parser";

import { Recipe } from "@/firebase/types";

interface GeneratedRecipeType extends Pick<Recipe,
  "id" | "title" | "description" | "image" | "ingredients" | "instructions" | "prepTime" | "cookTime" | "totalTime" |
  "servings" | "keywords" | "category" | "cuisine" | "nutrition"
> {
  // Used to indicate if the recipe image is usable or not
  error?: boolean
}

// Streams in a recipe from worker and retrieves an image from another worker
// This one uploads the image provided by the user instead of generating an image with AI
export default function useStreamGeneratedRecipeFromImage(fields: { uploadedImage: string }, isSubscribed = false) {
  const [streamedRecipe, setStreamedRecipe] = useState<GeneratedRecipeType | null>(null)
  const [recipeImage, setRecipeImage] = useState<Recipe["image"] | null>(null)
  const [recipeError, setRecipeError] = useState<any | null>(null)
  const [imageError, setImageError] = useState<any | null>(null)

  // The image couldn't be used to make an edible recipe (is probably a picture of something inedible)
  const [cannotUseImage, setCannotUseImage] = useState<any | null>(false)

  const isStreaming = useRef(false)
  const isUploadingImage = useRef(false)
  const [isComplete, setIsComplete] = useState(false)

  const recipeId = useRef(uuidv4())

  const incrementFreeRecipeImageImportsFunc = httpsCallable(functions, 'incrementFreeRecipeImageImports')

  // Start streaming the recipe when this hook is called
  useEffect(() => {
    if(!isStreaming.current) {
      streamGeneratedRecipe()
    }
  }, [])

  // Once the recipe generation is complete, increment the number of free recipe image imports on the user - only if they're not a premium user and nothing went wrong
  useEffect(() => {
    if(!isSubscribed && isComplete && !recipeError && !cannotUseImage) {
      incrementFreeRecipeImageImportsFunc()
    }
  }, [isComplete])

  async function streamGeneratedRecipe() {
    if(fields?.uploadedImage) {
      try {
        isStreaming.current = true

        const res = await fetch(import.meta.env.VITE_GENERATE_RECIPE_FROM_IMAGE_WORKER, {
          method: "POST",
          body: JSON.stringify({ image: fields.uploadedImage })
        })

        if(!res.ok) {
          throw new Error("Something went wrong while fetching recipe from worker")
        }

        if(res.body) {
          let reader = res.body.getReader();
          let result;
          let decoder = new TextDecoder('utf8');

          let recipeStream = ""

          while(!result?.done) {
            result = await reader.read();
            let chunk = decoder.decode(result.value);

            recipeStream += chunk

            // Try to update the recipe JSON object
            try {
              const parsedRecipe = parse(recipeStream)
              setStreamedRecipe(parsedRecipe)
            } catch(e) {
              console.error(e)
              // If it fails, no worries, the next chunk will probably be successful, just don't error out
            }
          }

          if(result.done) {
            isStreaming.current = false

            try {
              const parsedRecipe = parse(recipeStream)
              if(parsedRecipe?.error) {
                setCannotUseImage(true)
                setIsComplete(true)
              } else {
                // Upload the image once we have confirmation the image is okay to use
                await uploadImage()
              }
            } catch(e) {
              console.error(e)
              // Ignore
            }
          }
        }
      } catch(e: any) {
        console.error(e)

        setRecipeError(e)
        isStreaming.current = false
        setIsComplete(true)
      }
    }
  }

  async function uploadImage() {
    try {
      isUploadingImage.current = true

      const imageFile = await base64ImageToFile(fields.uploadedImage, 'uploaded-image')

      const uploadedImage = await uploadRecipeImage(recipeId.current, imageFile)

      if(uploadedImage) {
        setRecipeImage(uploadedImage)
      }

      isUploadingImage.current = false
      setIsComplete(true)
    } catch(e: any) {
      console.error(e)

      setImageError(e)
      isUploadingImage.current = false
      setIsComplete(true)
    }
  }

  return {
    streamedRecipe: {
      id: recipeId.current,
      ...streamedRecipe,
      image: recipeImage
    } as GeneratedRecipeType,
    isComplete,
    recipeError,
    imageError,
    cannotUseImage
  }
}