import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";

import { ChevronRight } from "lucide-react";

export default function ContactUs() {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("account.components.sections.contactUs.title")}
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col px-2">
        <Button variant="ghost" asChild className="justify-between">
          <Link
            to="https://www.flavorish.ai/changelog"
            target="_blank"
          >
            {t("account.components.sections.contactUs.changelog.label")}
            <ChevronRight size={20} />
          </Link>
        </Button>

        <Button variant="ghost" size="lg" asChild className="justify-between px-4">
          <Link
            to="mailto:hello@flavorish.ai"
            target="_blank"
          >
            <div className="flex flex-col items-start text-left">
              <span>
                {t("account.components.sections.contactUs.general.label")}
              </span>
              <span className="text-xs text-muted-foreground">
                {t("account.components.sections.contactUs.general.email")}
              </span>
            </div>
            <ChevronRight size={20} />
          </Link>
        </Button>

        <Button variant="ghost" size="lg" asChild className="justify-between px-4">
          <Link
            to="mailto:support@flavorish.ai"
            target="_blank"
          >
            <div className="flex flex-col items-start text-left">
              <span>
                {t("account.components.sections.contactUs.support.label")}
              </span>
              <span className="text-xs text-muted-foreground">
                {t("account.components.sections.contactUs.support.email")}
              </span>
            </div>
            <ChevronRight size={20} />
          </Link>
        </Button>
      </CardContent>
    </Card>
  )
}