import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { auth, db } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { arrayUnion, doc, serverTimestamp, updateDoc } from "firebase/firestore";

import Typesense from "typesense";
import { SearchResponse, SearchResponseHit } from "typesense/lib/Typesense/Documents";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import AddRecipeHit from "@/routes/collections/[id]/components/AddRecipeHit.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import InfiniteScroll from "@/components/ui/expansions/infinite-scroll.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { Loader2, Plus, Search, X } from "lucide-react";
import { Collection, Recipe } from "@/firebase/types.ts";

let client = new Typesense.Client({
  "nodes": [{
    host: import.meta.env.VITE_TYPESENSE_HOST,
    port: import.meta.env.VITE_TYPESENSE_PORT,
    protocol: import.meta.env.VITE_TYPESENSE_PROTOCOL
  }],
  "apiKey": import.meta.env.VITE_TYPESENSE_SEARCH_RECIPES_API_KEY,
  "connectionTimeoutSeconds": 2
})

const hitsPerPage = 10

type Props = {
  collection: Collection
}
export default function AddRecipe({ collection }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { toast } = useToast()

  const [user, loadingUser, errorUser] = useAuthState(auth)

  const [isOpen, setIsOpen] = useState(false)

  const [hits, setHits] = useState<SearchResponseHit<Recipe>[] | undefined>(undefined)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const page = useRef(1)

  const [selectedRecipes, setSelectedRecipes] = useState<Recipe[]>([])

  const formSchema = z.object({
    query: z.string()
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      query: ""
    },
  })

  // Initial query
  useEffect(() => {
    if(isOpen) {
      searchQuery("")
    } else {
      setHits(undefined)
    }
  }, [isOpen])

  // Submit form onChange
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())
    return () => subscription.unsubscribe();
  }, [form.handleSubmit, form.watch]);

  function searchQuery(q: string) {
    const searchParameters = {
      "q": q,
      "query_by": "title,description,keywords",
      "filter_by": `userId: ${user?.uid} && id:!=[${collection.recipeIds.toString()}]`,
      "page": page.current,
      "per_page": hitsPerPage,
      "prioritize_token_position": true
    }

    client.collections(import.meta.env.VITE_TYPESENSE_RECIPES_COLLECTION)
      .documents()
      .search(searchParameters)
      // @ts-ignore
      .then(function(searchResults: SearchResponse<Recipe>) {
        // If loading more results from another page of hits, update the array instead of replacing it
        if(page.current > 1 && Array.isArray(hits) && Array.isArray(searchResults.hits)) {
          setHits([...hits, ...searchResults.hits])
        } else {
          setHits(searchResults.hits)
        }

        if((searchResults.hits && searchResults.hits.length >= hitsPerPage) || (hits && hits.length < searchResults.found)) {
          setCanFetchMore(true)
        } else {
          setCanFetchMore(false)
        }
      })
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { query } = values

    try {
      page.current = 1
      searchQuery(query)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        type: "SearchRecipes.onSubmit.generic",
        message: `${t("collections.[id].components.AddRecipe.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  async function next() {
    if(hits) {
      setLoadingMore(true)

      page.current++
      searchQuery(form.getValues("query"))

      setLoadingMore(false)
    }
  }

  async function addRecipesToCollection() {
    try {
      const updatedCollection: any = {
        _updated: serverTimestamp(),
        recipeIds: arrayUnion(...selectedRecipes.map(recipe => recipe.id)),
        recipeImages: arrayUnion(...selectedRecipes.map(recipe => recipe.image?.url || null))
      }

      await updateDoc(doc(db, "collections", collection.id), updatedCollection)

      form.reset()
      setSelectedRecipes([])
      setIsOpen(false)
    } catch(e) {
      console.error(e)

      toast({
        title: t("collections.[id].components.AddRecipe.modal.addRecipes.addRecipesToCollection.error.generic.title"),
        description: t("collections.[id].components.AddRecipe.modal.addRecipes.addRecipesToCollection.error.generic.description"),
        variant: "destructive"
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {isMobile ? (
          <Button
            variant="primary"
            size="icon"
            className="floating-action-btn w-12 h-12"
          >
            <Plus size={28} />
          </Button>
        ) : (
          <Button
            variant="primary"
            className="gap-2"
          >
            <Plus size={20} />
            {t("collections.[id].components.AddRecipe.button")}
          </Button>
        )}
      </DialogTrigger>

      <DialogContent className="flex flex-col w-full max-w-4xl max-lg:h-dvh lg:top-0 lg:translate-y-0 max-h-dvh overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("collections.[id].components.AddRecipe.modal.title")}</DialogTitle>
        </DialogHeader>

        <div className="relative py-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 mb-4">
              <FormField
                control={form.control}
                name="query"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="relative w-full">
                        <Input placeholder={t("collections.[id].components.AddRecipe.modal.form.fields.query.placeholder")} className="pl-10" {...field} />

                        <Button
                          type="button"
                          size="icon"
                          variant="ghost"
                          className="absolute right-0 top-0 left-0 !opacity-100"
                          disabled
                        >
                          <Search size={20} className="text-muted-foreground" />
                        </Button>

                        {field.value !== '' && (
                          <Button
                            type="button"
                            size="icon"
                            variant="ghost"
                            className="absolute right-0 top-0 bottom-0"
                            onClick={() => form.setValue('query', '')}
                          >
                            <X size={20} className="text-muted-foreground" />
                          </Button>
                        )}
                      </div>
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormRootErrorMessage />
            </form>
          </Form>

          {hits && hits.length > 0 ? (
            <>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-2 gap-y-6 mb-4">
                {hits.map((hit) => (
                  <AddRecipeHit
                    key={hit.document.id}
                    id={hit.document.id}
                    selectedRecipes={selectedRecipes}
                    setSelectedRecipes={setSelectedRecipes}
                  />
                ))}
              </div>

              <InfiniteScroll hasMore={canFetchMore} isLoading={loadingMore} next={next} threshold={1}>
                {canFetchMore && (
                  <div className="flex justify-center items-center my-16">
                    <Loader2 size={48} className="animate-spin" />
                  </div>
                )}
              </InfiniteScroll>

              <div className="sticky -bottom-[1.55rem] -left-6 -right-6 -mx-6 bg-background border-t z-10 px-6 py-4">
                <Button
                  type="button"
                  variant="primary"
                  className="w-full"
                  disabled={selectedRecipes.length <= 0}
                  onClick={addRecipesToCollection}
                >
                  {t("collections.[id].components.AddRecipe.modal.addRecipes.button", { count: selectedRecipes.length })}
                </Button>
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center my-16">
              {t("collections.[id].components.AddRecipe.modal.form.noResults")}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}