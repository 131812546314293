import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"

import { Capacitor } from "@capacitor/core";
import { Purchases } from "@revenuecat/purchases-capacitor";

import { analytics, app, auth } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { logEvent } from "firebase/analytics";

import useUserSubscriptionStatus from "@/hooks/useUserSubscriptionStatus.tsx";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import AIStars from "@/components/icons/ai-stars/AIStars.tsx";
import { ChevronRight, Loader2 } from "lucide-react";

export default function Subscription() {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [user, loadingUser, errorUser] = useAuthState(auth)
  const { isUserSubscribed, subscriptionPlatform, loading } = useUserSubscriptionStatus()

  const [loadingCustomerPortalLink, setLoadingCustomerPortalLink] = useState(false)

  // Only used when user subscribed with Google or Apple
  // Could still be null after fetch
  const [manageSubscriptionUrl, setManageSubscriptionUrl] = useState<string | null>(null)

  useEffect(() => {
    if(user) {
      (async function() {
        // RevenueCat SDK doesn't work on web, fetch from API in that case
        if(Capacitor.isNativePlatform()) {
          const { customerInfo } = await Purchases.getCustomerInfo()
          setManageSubscriptionUrl(customerInfo.managementURL)
        } else {
          const res = await fetch(`https://api.revenuecat.com/v1/subscribers/${user.uid}`, {
            method: "GET",
            headers: {
              // It doesn't seem to matter if we use the apple or google api key here
              Authorization: `Bearer ${import.meta.env.VITE_REVENUE_CAT_ANDROID_PUBLIC_API_KEY}`,
              "X-Is-Sandbox": `${import.meta.env.VITE_ENV !== "production"}`
            }
          })
          const data = await res.json()

          if(data && data.subscriber && data.subscriber.management_url) {
            setManageSubscriptionUrl(data.subscriber.management_url)
          }
        }
      })()
    }
  }, [user]);

  async function redirectToCustomerPortal() {
    setLoadingCustomerPortalLink(true)

    try {
      const functions = getFunctions(app, 'us-east1')
      const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')

      const portalLinkData: any = {
        customerId: user?.uid,
      }
      // Return URL doesn't work on mobile
      if(!Capacitor.isNativePlatform()) portalLinkData.returnUrl = window.location.href

      const { data } = await createPortalLink(portalLinkData)

      setLoadingCustomerPortalLink(false)

      logEvent(analytics, 'manage_subscription_button_click')

      // @ts-ignore
      window.location.assign(data.url)
    } catch(e) {
      console.error(e)

      toast({
        title: t("account.components.sections.subscription.redirectToCustomerPortal.error.generic.title"),
        description: t("account.components.sections.subscription.redirectToCustomerPortal.error.generic.description"),
        variant: "destructive"
      })
      setLoadingCustomerPortalLink(false)
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t("account.components.sections.subscription.title")}
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col px-2">
        {loading ? (
          <div className="flex justify-center items-center p-2">
            <Loader2 size={24} className="animate-spin" />
          </div>
        ) : (
          <>
            {isUserSubscribed ? (
              <>
                {subscriptionPlatform === "web" ? (
                  <Button
                    variant="ghost"
                    className="justify-between"
                    onClick={redirectToCustomerPortal}
                    disabled={loadingCustomerPortalLink}
                  >
                    <span className="flex gap-2">
                      {loadingCustomerPortalLink && (
                        <Loader2 size={24} className="animate-spin" />
                      )}
                      {t("account.components.sections.subscription.manageSubscription")}
                    </span>
                    <ChevronRight size={20} />
                  </Button>
                ) : (
                  <>
                    {manageSubscriptionUrl ? (
                      <Button variant="ghost" asChild className="justify-between">
                        <Link
                          to={manageSubscriptionUrl}
                          target="_blank"
                        >
                          {t('account.components.sections.subscription.manageSubscription')}
                          <ChevronRight size={20} />
                        </Link>
                      </Button>
                    ) : (
                      <div className="flex justify-center items-center p-2">
                        {/* Something is seriously wrong if it gets to this point */}
                        <Loader2 size={24} className="animate-spin" />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <Button variant="ghost" asChild className="justify-between">
                <Link
                  to="/subscription/upgrade"
                  onClick={() => logEvent(analytics, 'upgrade_subscription_button_click')}
                >
                  <span className="flex gap-2">
                    <AIStars size={24} />
                    {t("account.components.sections.subscription.upgradeToPremium")}
                  </span>
                  <ChevronRight size={20} />
                </Link>
              </Button>
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}