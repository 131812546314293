import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RECIPE_GENERATIONS } from "@/globals.js";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Progress } from "@/components/ui/progress.tsx";

type Props = {
  freeAIRecipeGenerationsUsed: number
}
export default function FreeAIRecipeGenerationsUsed({ freeAIRecipeGenerationsUsed }: Props) {
  const { t } = useTranslation()
  const recipesUsedPercentage = (100 * freeAIRecipeGenerationsUsed) / RECIPE_GENERATIONS.FREE

  return (
    <>
      {freeAIRecipeGenerationsUsed > 0 && (
        <Card className="mx-4 mb-4">
          <CardHeader className="flex flex-row justify-between items-center pb-2">
            <CardTitle className="text-lg">
              {t("recipes.generate.components.FreeAIRecipeGenerationsUsed.title")}
            </CardTitle>

            <Button
              type="button"
              variant="primary"
              size="sm"
              asChild
              className="h-6 !m-0"
            >
              <Link to="/subscription/upgrade">
                {t('recipes.generate.components.FreeAIRecipeGenerationsUsed.actions.upgrade')}
              </Link>
            </Button>
          </CardHeader>

          <CardContent>
            <Progress value={recipesUsedPercentage} />

            <div className="flex justify-between items-center mt-2">
              <p className="text-sm">
                {t('recipes.generate.components.FreeAIRecipeGenerationsUsed.progress.numUsed', { numUsed: freeAIRecipeGenerationsUsed, total: RECIPE_GENERATIONS.FREE })}
              </p>
              <p className="text-sm">{t('recipes.generate.components.FreeAIRecipeGenerationsUsed.progress.percentageUsed', { percentage: recipesUsedPercentage })}</p>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  )
}