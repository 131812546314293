import { useTranslation } from "react-i18next";
import { useFieldArray, UseFormReturn } from "react-hook-form";

import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { X } from "lucide-react";

import { GenerateRecipeFormInputs } from "@/routes/recipes/generate/types";

type Props = {
  form: UseFormReturn<GenerateRecipeFormInputs>
}
export default function ByIngredients({ form }: Props) {
  const { t } = useTranslation()

  const {
    fields: ingredientsFields,
    append: ingredientsAppend,
    insert: ingredientsInsert,
    remove: ingredientsRemove
  } = useFieldArray({
    control: form.control,
    name: "ingredients",
    rules: {
      maxLength: {
        value: 100,
        message: `${t("recipes.generate.components.steps.ByIngredients.fields.ingredients.validation.maxItems", { maxItems: 100 })}`
      }
    }
  })

  form.register("onlyIngredients")

  return (
    <>
      {ingredientsFields.map((ingredient, index) => (
        <FormField
          key={ingredient.id}
          control={form.control}
          name={`ingredients.${index}.name`}
          render={({ field }) => (
            <FormItem>
              {index === 0 && (
                <FormLabel>{t("recipes.generate.components.steps.ByIngredients.fields.ingredients.label")}</FormLabel>
              )}
              <FormControl>
                <div className="relative">
                  <Input
                    className="pr-10"
                    placeholder={t("recipes.generate.components.steps.ByIngredients.fields.ingredients.placeholder")}
                    {...form.register(`ingredients.${index}.name`, {
                      maxLength: {
                        value: 500,
                        message: `${t("recipes.generate.components.steps.ByIngredients.fields.ingredients.validation.maxLength", { maxLength: 500 })}`
                      },
                      onChange: () => {
                        // Add a new field when user enters something in an empty field
                        if(index + 1 === ingredientsFields.length && ingredient.name === "") {
                          ingredientsAppend({ name: "" }, { shouldFocus: false })
                        }
                      }
                    })}

                    // TODO: Add some indication to the user that they can do this?
                    // Handle pasting multiple ingredients at once
                    onPaste={(e) => {
                      const clipboardData = e.clipboardData
                      const pastedData = clipboardData.getData("Text")

                      let ingredientsArr = pastedData.replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/)
                      ingredientsArr = ingredientsArr.filter(item => item !== "") // Filter out empty items

                      // If there are multiple items separated by a line break, insert them into their own field
                      if(ingredientsArr.length > 1) {
                        // Insert ingredients at the correct spot
                        ingredientsArr.map((ingredient, i) => {
                          ingredientsInsert(index + 1 + i,
                            {
                              name: ingredient
                            }, { shouldFocus: false }
                          )
                        })

                        // Remove the field pasted into which contains the whole line separated text that we just moved to separate fields
                        ingredientsRemove(index)

                        // If ingredients were pasted in the last field, make sure we add another empty field to the end
                        if(index + 1 === ingredientsFields.length) {
                          ingredientsAppend({ name: "" }, { shouldFocus: false })
                        }
                      }
                    }}

                    // Focus next ingredient field on enter
                    onKeyDown={(e) => {
                      if(e.key === "Enter") {
                        e.preventDefault()
                        form.setFocus(`ingredients.${index + 1}.name`)
                      }
                    }}
                  />

                  {index + 1 < ingredientsFields.length && (
                    <Button
                      type="button"
                      size="icon"
                      variant="ghost"
                      className="absolute right-0 top-0 bottom-0"
                      onClick={() => ingredientsRemove(index)}
                    >
                      <X size={20} className="text-muted-foreground" />
                    </Button>
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      ))}

      <FormField
        control={form.control}
        name="onlyIngredients"
        render={({ field }) => (
          <FormItem>
            <div className="flex flex-row items-start space-x-2 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>
                {t('recipes.generate.components.steps.ByIngredients.fields.onlyIngredients.label')}
              </FormLabel>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}