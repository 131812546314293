import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import useIsMobile from "@/hooks/useIsMobile.tsx";

import { Button, ButtonProps } from "@/components/ui/button.tsx";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu.tsx";

import { EllipsisVertical, Trash2 } from "lucide-react";

import { Recipe } from "@/firebase/types.ts";

type Props = {
  recipe: Recipe
  handleDeleteRecipe: (recipe: Recipe) => void
}
export default function RecipeOptions({ recipe, handleDeleteRecipe }: Props) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

  const TriggerButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ ...props }, ref) => (
    <Button
      type="button"
      variant="background"
      size="icon"
      className="absolute top-2 right-2 h-8 w-8 z-10"
      ref={ref}
      {...props}
    >
      <EllipsisVertical size={20} />
    </Button>
  ))

  const DeleteFromCollectionButton = () => (
    <Button
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      className={`w-full justify-start gap-2 ${isMobile ? "p-0" : "p-2"}`}
      onClick={async (e) => {
        e.preventDefault()

        await handleDeleteRecipe(recipe)

        setDrawerOpen(false)
        setDropdownMenuOpen(false)
      }}
    >
      <Trash2 size={isMobile ? 20 : 16} />
      {t("collections.[id].components.RecipeOptions.actions.delete")}
    </Button>
  )

  return (
    <>
      {isMobile ? (
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          <DrawerTrigger asChild>
            <TriggerButton />
          </DrawerTrigger>

          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>
                {t("collections.[id].components.RecipeOptions.menuTitle")}
              </DrawerTitle>
            </DrawerHeader>

            <div className="flex flex-col gap-2 mx-auto w-full py-4">
              <DeleteFromCollectionButton />
            </div>
          </DrawerContent>
        </Drawer>
      ) : (
        <DropdownMenu open={dropdownMenuOpen} onOpenChange={setDropdownMenuOpen}>
          <DropdownMenuTrigger asChild>
            <TriggerButton />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              {t("collections.[id].components.RecipeOptions.menuTitle")}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              <DropdownMenuItem>
                <DeleteFromCollectionButton />
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}