import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FileDrop } from "react-file-drop";

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import useUserData from "@/hooks/useUserData.tsx";
import useUserSubscriptionStatus from "@/hooks/useUserSubscriptionStatus.tsx";

import { RECIPE_IMAGE_IMPORTS } from "@/globals.js";

import Page from "@/components/page/Page.tsx";
import Header from "@/components/navigation/Header.tsx";

import FreeRecipeImageImportsUsed from "@/routes/recipes/components/general/FreeRecipeImageImportsUsed.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { DialogFooter } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";

import { Camera as LuCamera, Image as LuImage, Loader2 } from "lucide-react";

export default function ImportImage() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isUserSubscribed, loading: loadingUserSubscriptionStatus } = useUserSubscriptionStatus()
  const { userData } = useUserData()

  const freeRecipeImageImportsUsed = userData?.freeRecipeImageImportsUsed || 0
  const freeRecipeImagesImportsLimitReached = !loadingUserSubscriptionStatus && !isUserSubscribed && freeRecipeImageImportsUsed >= RECIPE_IMAGE_IMPORTS.FREE

  const formSchema = z.object({
    image: z.string()
      .min(1, {
        message: t("recipes.importImage.page.form.fields.image.validation.required")
      })
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      image: "",
    }
  })

  const currImage = form.watch("image")

  async function validateImage(imageStr) {
    if(imageStr) {
      const res = await fetch(imageStr)
      const imageBlob = await res.blob()
      const imageFile = new File([imageBlob], `image.${imageBlob.type.replace('image/', '')}`, { type: imageBlob.type })

      const fileSizeMB = (imageFile.size / (1024 * 1024)).toFixed(2) // MB

      if(parseInt(fileSizeMB) > 10) {
        form.setError('image', { message: `${t("lib.images.validation.upload.tooLarge")}` })
        form.setValue('image', "")
        return false
      } else if(imageFile.type !== "image/png" && imageFile.type !== "image/jpeg" && imageFile.type !== "image/webp") {
        form.setError('image', { message: `${t("lib.images.validation.upload.badType")}` })
        form.setValue('image', "")
        return false
      } else {
        form.clearErrors('image')
        return true
      }
    } else {
      return false
    }
  }

  const takePicture = async () => {
    if(!form.formState.isSubmitting) {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          source: CameraSource.Camera,
          allowEditing: false,
          resultType: CameraResultType.Uri
        });

        if(image && image.webPath) {
          if(await validateImage(image.webPath)) {
            form.setValue('image', image.webPath)
          }
        }
      } catch(e) {
        console.error(e)
        // Ignore errors
      }
    }
  };

  async function pickImage() {
    if(!form.formState.isSubmitting) {
      try {
        const galleryPhotos = await Camera.pickImages({
          limit: 1
        })

        if(galleryPhotos.photos && galleryPhotos.photos.length > 0) {
          const pickedImage = galleryPhotos.photos[0]

          if(await validateImage(pickedImage.webPath)) {
            form.setValue('image', pickedImage.webPath)
          }
        }
      } catch(e) {
        console.error(e)
        // Ignore errors
      }
    }
  }

  function deleteImage() {
    if(!form.formState.isSubmitting) {
      form.setValue('image', "")
      form.clearErrors('image')
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { image } = values

    try {
      // Convert image to base64
      const res = await fetch(image)
      const imageBlob = await res.blob()
      const reader = new FileReader()

      reader.readAsDataURL(imageBlob)
      reader.onloadend = () => {
        const base64Image = reader.result

        navigate(`/recipes/import-image/loading`, {
          state: {
            fields: {
              uploadedImage: base64Image
            }
          }
        })
      }
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("recipes.importImage.page.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <Page>
      <Helmet>
        <title>{t("recipes.importImage.page.head.title")}</title>
      </Helmet>

      <Header goBackURL="/recipes" />

      <div className="flex flex-col flex-grow w-full max-w-4xl mx-auto">
        {!loadingUserSubscriptionStatus && !isUserSubscribed && (
          <FreeRecipeImageImportsUsed freeRecipeImageImportsUsed={freeRecipeImageImportsUsed} />
        )}

        <div className="p-4">
          <div className="max-w-md mx-auto mb-8">
            <h2 className="flex flex-wrap justify-center items-center gap-2 font-semibold text-2xl text-center mb-2">
              <LuCamera size={28} style={{ stroke: "url(#ai-gradient)" }} className="flex-shrink-0" />
              {t('recipes.importImage.page.title')}
            </h2>
            <p className="text-center">{t('recipes.importImage.page.description')}</p>
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              {!freeRecipeImagesImportsLimitReached && (
                <FormField
                  control={form.control}
                  name="image"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("recipes.importImage.page.form.fields.image.label")}</FormLabel>
                      <FormDescription className="!mt-0 !mb-2">{t("recipes.importImage.page.form.fields.image.description")}</FormDescription>

                      <FormControl>
                        <>
                          {currImage ? (
                            <div>
                              <img
                                src={currImage}
                                alt=""
                                className="w-full rounded-md"
                              />

                              <div className="flex gap-2 w-full py-2">
                                <Button
                                  type="button"
                                  variant="accent"
                                  className="w-full"
                                  disabled={form.formState.isSubmitting}
                                  onClick={pickImage}
                                >
                                  {t("recipes.importImage.page.form.fields.image.changeImage")}
                                </Button>
                                <Button
                                  type="button"
                                  variant="destructive"
                                  className="w-full"
                                  disabled={form.formState.isSubmitting}
                                  onClick={deleteImage}
                                >
                                  {t("recipes.importImage.page.form.fields.image.deleteImage")}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <Button
                                type="button"
                                variant="accent"
                                size="lg"
                                className="gap-2 w-full"
                                onClick={takePicture}
                                disabled={form.formState.isSubmitting || freeRecipeImagesImportsLimitReached}
                              >
                                <LuCamera size={20} />
                                {t("recipes.importImage.page.form.fields.image.takePhoto")}
                              </Button>

                              <div className="relative !my-4">
                                <div className="absolute inset-0 flex items-center">
                                  <span className="w-full border-t" />
                                </div>
                                <div className="relative flex justify-center text-xs uppercase">
                                  <span className="bg-background px-2 text-muted-foreground">
                                    {t("recipes.importImage.page.form.fields.image.separator")}
                                  </span>
                                </div>
                              </div>

                              <FileDrop
                                targetClassName={`flex flex-col items-center justify-center w-full h-32 bg-background p-4 border-2 border-dashed rounded-md cursor-pointer text-muted-foreground`}
                                draggingOverTargetClassName="bg-primary/20 border-primary text-primary"
                                onTargetClick={pickImage}
                                onDrop={async (files, e) => {
                                  e.preventDefault()

                                  if(!form.formState.isSubmitting && files && files.length > 0) {
                                    const imageStr = URL.createObjectURL(files[0])
                                    if(await validateImage(imageStr)) {
                                      form.setValue('image', imageStr)
                                    }
                                  }
                                }}
                              >
                                <LuImage size={32} className="mb-2" />

                                <p className="text-base font-medium">
                                  {t("recipes.importImage.page.form.fields.image.dropzoneText")}
                                </p>
                              </FileDrop>
                            </>
                          )}
                          <Input
                            id="image"
                            type="text"
                            className="hidden"
                            hidden
                            disabled={form.formState.isSubmitting || freeRecipeImagesImportsLimitReached}
                            {...field}
                          />
                        </>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <DialogFooter>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full"
                  disabled={form.formState.isSubmitting || freeRecipeImagesImportsLimitReached}
                >
                  {form.formState.isSubmitting ? (
                    <Loader2 size={24} className="animate-spin" />
                  ) : t("recipes.importImage.page.form.actions.save")}
                </Button>
              </DialogFooter>

              <FormRootErrorMessage />
            </form>
          </Form>
        </div>
      </div>
    </Page>
  )
}