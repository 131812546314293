import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { analytics, db } from "@/firebase/app.ts";
import { deleteDoc, doc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";

import { Button } from "@/components/ui/button.tsx";
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, } from "@/components/ui/alert-dialog.tsx"
import { Form, FormRootErrorMessage } from "@/components/ui/form.tsx";

import { Loader2 } from "lucide-react";

import { Collection } from "@/firebase/types.ts";

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  collection: Collection | null
}

export default function DeleteCollectionModal({ isOpen = false, setIsOpen, collection }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const form = useForm()

  useEffect(() => {
    if(collection) {
      form.reset()
    }
  }, [collection])

  async function onSubmit() {
    try {
      if(collection?.id) {
        await deleteDoc(doc(db, "collections", collection.id))

        logEvent(analytics, 'delete_collection')

        // This modal is used on the /collections and /collections/[id] pages
        // If on the ID page, go back to collections
        // Otherwise just close the modal
        if(params.hasOwnProperty('id')) {
          navigate('/collections')
        } else {
          setIsOpen(false)
        }
      } else {
        throw new Error(`${t('collections.[id].components.modals.DeleteCollectionModal.modal.form.onSubmit.error.missingParams')}`)
      }
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("collections.[id].components.modals.DeleteCollectionModal.modal.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <AlertDialogHeader>
              <AlertDialogTitle>{t('collections.[id].components.modals.DeleteCollectionModal.modal.title', { collectionTitle: collection?.title })}</AlertDialogTitle>
              <AlertDialogDescription>
                {t('collections.[id].components.modals.DeleteCollectionModal.modal.description')}
              </AlertDialogDescription>
            </AlertDialogHeader>

            <AlertDialogFooter>
              <AlertDialogCancel>{t('collections.[id].components.modals.DeleteCollectionModal.modal.form.actions.cancel')}</AlertDialogCancel>

              <Button
                type="submit"
                variant="destructive"
                disabled={form.formState.isSubmitting}
              >
                {form.formState.isSubmitting ? (
                  <Loader2 size={24} className="animate-spin" />
                ) : t("collections.[id].components.modals.DeleteCollectionModal.modal.form.actions.delete")}
              </Button>
            </AlertDialogFooter>

            <FormRootErrorMessage />
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}