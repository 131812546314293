import { useState, useEffect } from 'react';

import { auth, db } from "@/firebase/app.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, onSnapshot } from "firebase/firestore";

import { User } from "@/firebase/types";

type UserData = User | undefined

export default function useUserData() {
  const [userData, setUserData] = useState<UserData>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  const [user, loadingUser, errorUser] = useAuthState(auth)

  useEffect(() => {
    if(user) {
      const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
        // If the document doesn't exist, this listener will update once it's created - unless it errors out for some reason
        if(doc.exists()) {
          const userData = doc.data() as UserData
          setUserData(userData)
          setLoading(false)
        }
      }, (error) => {
        setError(error)
        setLoading(false)
      })

      return () => unsubscribe()
    }

    if(errorUser) {
      setError(errorUser)
      setLoading(false)
    }
  }, [user])

  return { userData, loading, error };
}