import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

import Ingredient from "@/routes/recipes/[id]/components/recipe/Ingredient.tsx";
import ExportIngredientsToListModal from "@/routes/recipes/[id]/components/modals/ExportIngredientsToListModal.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";

import { Minus, Plus } from "lucide-react";

import { Recipe } from "@/firebase/types"

type Props = {
  recipe?: Recipe | Partial<Recipe>
  isLoading?: boolean
  checkedIngredients: string[]
  setCheckedIngredients: (checkedIngredients: string[]) => void
}
export default function Ingredients({ recipe, isLoading = false, checkedIngredients, setCheckedIngredients }: Props) {
  const { t } = useTranslation()

  const [showExportIngredientsToListModal, setShowExportIngredientsToListModal] = useState(false)

  const [scaledServings, setScaledServings] = useState(0)
  let scaleRatio = 1
  if(recipe?.servings) {
    scaleRatio = scaledServings / recipe?.servings
  }

  useEffect(() => {
    if(recipe?.servings) {
      setScaledServings(recipe.servings)
    }
  }, [recipe?.servings])

  return (
    <>
      <Card>
        <CardHeader className="flex flex-row justify-between items-start pt-4 pb-4">
          <CardTitle className="text-lg font-semibold">{t("recipes.[id].components.recipe.Ingredients.title")}</CardTitle>

          {recipe?.ingredients && !isLoading && (
            <div className="flex items-center !m-0">
              <Button
                type="button"
                variant="outline"
                size="icon"
                className="w-7 h-7"
                onClick={e => {
                  setScaledServings(scaledServings <= 1 ? 1 : scaledServings - 1)
                }}
              >
                <Minus size={16} />
              </Button>
              <span className="text-base mx-4">{t("recipes.[id].page.servings", { numServings: scaledServings })}</span>
              <Button
                type="button"
                variant="outline"
                size="icon"
                className="w-7 h-7"
                onClick={e => {
                  setScaledServings(scaledServings + 1)
                }}
              >
                <Plus size={16} />
              </Button>
            </div>
          )}
        </CardHeader>
        <CardContent className="px-2 pb-4">
          <ul className="flex flex-col gap-2 mb-4">
            {recipe?.ingredients ? (
              <>
                {recipe.ingredients.map((ingredient, i) => (
                  // ID could be missing during streaming
                  <React.Fragment key={ingredient.id || i}>
                    <li>
                      <Ingredient
                        ingredient={ingredient}
                        scaleRatio={scaleRatio}
                        checkedIngredients={checkedIngredients}
                        setCheckedIngredients={setCheckedIngredients}
                      />
                    </li>

                    {/* @ts-ignore it is defined you dumbass */}
                    {i + 1 !== recipe.ingredients.length && recipe.ingredients[i + 1].isGroupHeader && (
                      <div className="px-4">
                        <Separator />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                <li className="px-4">
                  <Skeleton className="w-full h-4" />
                </li>
                <li className="px-4">
                  <Skeleton className="w-full h-4" />
                </li>
                <li className="px-4">
                  <Skeleton className="w-3/4 h-4" />
                </li>
              </>
            )}
          </ul>

          {recipe?.ingredients && !isLoading && (
            <Button
              type="button"
              variant="accent"
              className="w-full"
              onClick={e => {
                e.preventDefault()
                setShowExportIngredientsToListModal(true)
              }}
            >
              {t("recipes.[id].components.recipe.Ingredients.addIngredientsToListButton")}
            </Button>
          )}
        </CardContent>
      </Card>

      {recipe && recipe.ingredients && recipe.ingredients.length > 0 && !isLoading &&
        <ExportIngredientsToListModal
          isOpen={showExportIngredientsToListModal}
          setIsOpen={setShowExportIngredientsToListModal}
          recipe={recipe as Recipe}
          scaleRatio={scaleRatio}
        />
      }
    </>
  )
}