import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

import { analytics, auth } from "@/firebase/app.ts";
import { GoogleAuthProvider, OAuthProvider, signInWithCredential, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { logEvent } from "firebase/analytics";

// Google recommends using the redirect method on mobile and the popup method on desktop
// https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md#usage
export const loginWithGoogle = async (callback = (() => {})) => {
  logEvent(analytics, 'login', {
    method: 'google',
  })

  if(Capacitor.isNativePlatform()) {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle();
    // 2. Sign in on the web layer using the id token
    const credential = GoogleAuthProvider.credential(result.credential?.idToken);
    await signInWithCredential(auth, credential)
  } else {
    const provider = new GoogleAuthProvider()

    if(window.innerWidth < 1024) {
      await signInWithRedirect(auth, provider)
    } else {
      await signInWithPopup(auth, provider)
    }
  }

  callback()
}

export const loginWithApple = async (callback = (() => {})) => {
  logEvent(analytics, 'login', {
    method: 'apple',
  })

  if(Capacitor.isNativePlatform()) {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    // 2. Sign in on the web layer using the id token and nonce
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
    await signInWithCredential(auth, credential)
  } else {
    const provider = new OAuthProvider('apple.com')

    if(window.innerWidth < 1024) {
      await signInWithRedirect(auth, provider)
    } else {
      await signInWithPopup(auth, provider)
    }
  }

  callback()
}