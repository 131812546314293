import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";

import ImportInstagramGuide from "@/routes/recipes/import-social-media/components/ImportInstagramGuide.tsx";
import ImportTiktokGuide from "@/routes/recipes/import-social-media/components/ImportTiktokGuide.tsx";
import ImportYoutubeGuide from "@/routes/recipes/import-social-media/components/ImportYoutubeGuide.tsx";
import ImportFavouriteShareOptionGuide from "@/routes/recipes/import-social-media/components/ImportFavouriteShareOptionGuide.tsx";

import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { Card, CardHeader, CardTitle } from "@/components/ui/card.tsx";

import LogoImg from "@/assets/icon.svg"
import Instagram from "@/assets/images/logos/instagram.svg";
import Tiktok from "@/assets/images/logos/tiktok.svg";
import TiktokDark from "@/assets/images/logos/tiktok-dark.svg";
import Youtube from "@/assets/images/logos/youtube.png";

export default function GuidesListing() {
  const { t } = useTranslation()

  const [showFavouriteShareOptionGuideModal, setShowFavouriteShareOptionGuideModal] = useState(false)
  const [showImportInstagramGuideModal, setShowImportInstagramGuideModal] = useState(false)
  const [showImportTiktokGuideModal, setShowImportTiktokGuideModal] = useState(false)
  const [showImportYoutubeGuideModal, setShowImportYoutubeGuideModal] = useState(false)

  return Capacitor.isNativePlatform() && (
    <>
      <h3 className="text-lg font-semibold mb-4">{t("recipes.importSocialMedia.components.GuidesListing.title")}</h3>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        {Capacitor.getPlatform() === 'ios' && (
          <Card
            className="group flex-shrink-0 overflow-hidden"
            onClick={() => setShowFavouriteShareOptionGuideModal(true)}
            role="button"
          >
            <AspectRatio ratio={16 / 9} className="flex justify-center items-center bg-accent p-4">
              <img src={LogoImg} alt="Flavorish" className="max-w-10 lg:max-w-14 max-h-10 lg:max-h-14" />
            </AspectRatio>

            <CardHeader className="p-4">
              <CardTitle className="text-base sm:text-lg font-semibold group-hover:underline">{t("recipes.importSocialMedia.components.GuidesListing.guides.favouriteShareOptionGuide.title")}</CardTitle>
            </CardHeader>
          </Card>
        )}

        <Card
          className="group flex-shrink-0 overflow-hidden"
          onClick={() => setShowImportInstagramGuideModal(true)}
          role="button"
        >
          <AspectRatio ratio={16 / 9} className="flex justify-center items-center bg-accent p-4">
            <img src={Instagram} alt="Instagram" className="max-w-8 lg:max-w-12 max-h-8 lg:max-h-12" />
          </AspectRatio>

          <CardHeader className="p-4">
            <CardTitle className="text-base sm:text-lg font-semibold group-hover:underline">{t("recipes.importSocialMedia.components.GuidesListing.guides.importInstagramGuide.title")}</CardTitle>
          </CardHeader>
        </Card>

        <Card
          className="group flex-shrink-0 overflow-hidden"
          onClick={() => setShowImportTiktokGuideModal(true)}
          role="button"
        >
          <AspectRatio ratio={16 / 9} className="flex justify-center items-center bg-accent p-4">
            <img src={Tiktok} alt="TikTok" className="dark:hidden max-w-8 lg:max-w-12 max-h-8 lg:max-h-12" />
            <img src={TiktokDark} alt="TikTok" className="hidden dark:block max-w-8 lg:max-w-12 max-h-8 lg:max-h-12" />
          </AspectRatio>

          <CardHeader className="p-4">
            <CardTitle className="text-base sm:text-lg font-semibold group-hover:underline">{t("recipes.importSocialMedia.components.GuidesListing.guides.importTiktokGuide.title")}</CardTitle>
          </CardHeader>
        </Card>

        <Card
          className="group flex-shrink-0 overflow-hidden"
          onClick={() => setShowImportYoutubeGuideModal(true)}
          role="button"
        >
          <AspectRatio ratio={16 / 9} className="flex justify-center items-center bg-accent p-4">
            <img src={Youtube} alt="YouTube" className="max-w-10 lg:max-w-16 max-h-8 lg:max-h-12" />
          </AspectRatio>

          <CardHeader className="p-4">
            <CardTitle className="text-base sm:text-lg font-semibold group-hover:underline">{t("recipes.importSocialMedia.components.GuidesListing.guides.importYoutubeGuide.title")}</CardTitle>
          </CardHeader>
        </Card>
      </div>

      {Capacitor.getPlatform() === 'ios' && (
        <ImportFavouriteShareOptionGuide isOpen={showFavouriteShareOptionGuideModal} setIsOpen={setShowFavouriteShareOptionGuideModal} />
      )}
      <ImportInstagramGuide isOpen={showImportInstagramGuideModal} setIsOpen={setShowImportInstagramGuideModal} />
      <ImportTiktokGuide isOpen={showImportTiktokGuideModal} setIsOpen={setShowImportTiktokGuideModal} />
      <ImportYoutubeGuide isOpen={showImportYoutubeGuideModal} setIsOpen={setShowImportYoutubeGuideModal} />
    </>
  )
}