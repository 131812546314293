import React from "react"
import { useTranslation } from "react-i18next";

import Instruction from "@/routes/recipes/[id]/components/recipe/Instruction.tsx";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { Separator } from "@/components/ui/separator.tsx";

import { Recipe } from "@/firebase/types"

type Props = {
  instructions?: Recipe["instructions"]
  checkedInstructions: string[]
  setCheckedInstructions: (checkedInstructions: string[]) => void
}
export default function Instructions({ instructions, checkedInstructions, setCheckedInstructions }: Props) {
  const { t } = useTranslation()

  let instructionStepCounter = 0

  return (
    <Card>
      <CardHeader className="flex flex-row justify-between items-start pt-4 pb-4">
        <CardTitle className="text-lg font-semibold">{t("recipes.[id].components.recipe.Instructions.title")}</CardTitle>
      </CardHeader>
      <CardContent className="px-2 pb-4">
        <ol className="flex flex-col gap-2 mb-4">
          {instructions ? (
            <>
              {instructions.map((instruction, i) => {
                if(!instruction.isGroupHeader) {
                  instructionStepCounter++
                }

                return (
                  // ID could be missing during streaming
                  <React.Fragment key={instruction.id || i}>
                    <li>
                      <Instruction
                        instruction={instruction}
                        step={instructionStepCounter}
                        checkedInstructions={checkedInstructions}
                        setCheckedInstructions={setCheckedInstructions}
                      />
                    </li>

                    {i + 1 !== instructions.length && instructions[i + 1].isGroupHeader && (
                      <div className="px-4">
                        <Separator />
                      </div>
                    )}
                  </React.Fragment>
                )
              })}
            </>
          ) : (
            <>
              <li className="px-4">
                <Skeleton className="w-full h-4" />
              </li>
              <li className="px-4">
                <Skeleton className="w-full h-4" />
              </li>
              <li className="px-4">
                <Skeleton className="w-3/4 h-4" />
              </li>
            </>
          )}
        </ol>
      </CardContent>
    </Card>
  )
}