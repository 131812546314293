import { db } from "@/firebase/app.ts";
import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import RecipeCard from "@/routes/recipes/components/RecipeCard.tsx";

import { Recipe } from "@/firebase/types";

type Props = {
  id: Recipe["id"]
}
export default function SearchRecipesHit({ id }: Props) {
  const [recipeData, loading, error, snapshot] = useDocumentDataOnce(
    doc(db, "recipes", id)
  )
  const recipe = recipeData as Recipe | undefined

  return (
    <RecipeCard recipe={recipe} isLoading={loading} />
  )
}