import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App.tsx"

// Forces the client to update when a new version is deployed
import { registerSW } from 'virtual:pwa-register'
registerSW({ immediate: true })

import * as Sentry from "@sentry/react";

Sentry.init({
  environment: import.meta.env.VITE_ENV,
  dsn: "https://201d42b89dffe1562f09a765a8c8a6b8@o4506264713822208.ingest.sentry.io/4506264717295616",
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
