import { Trans, useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";

import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import Youtube from "@/assets/images/logos/youtube.png";
import androidYT1 from "@/assets/images/import-social-media-guides/android/YT 1.gif"
import androidYT2 from "@/assets/images/import-social-media-guides/android/YT 2.gif"
import androidYT3 from "@/assets/images/import-social-media-guides/android/YT 3.gif"
import iosYT1 from "@/assets/images/import-social-media-guides/ios/YT 1.gif"
import iosYT2 from "@/assets/images/import-social-media-guides/ios/YT 2.gif"
import iosYT3 from "@/assets/images/import-social-media-guides/ios/YT 3.gif"

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

export default function ImportYoutubeGuide({ isOpen = false, setIsOpen }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-h-dvh overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <img src={Youtube} alt="YouTube" className="max-w-8 max-h-5" />
            {t("recipes.importSocialMedia.components.ImportYoutubeGuide.title")}
          </DialogTitle>
        </DialogHeader>

        <div className="mt-4 space-y-8">
          {Capacitor.getPlatform() === 'android' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.android.step1.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.android.step1.text")}</p>
                <img src={androidYT1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.android.step2.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.android.step2.text")}</p>
                <img src={androidYT2} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.android.step3.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.android.step3.text")}</p>
                <img src={androidYT3} alt="" className="my-4" />
              </div>

              <p className="text-sm">
                <Trans i18nKey="recipes.importSocialMedia.components.ImportYoutubeGuide.content.android.note">
                  <strong>Note:</strong> We can only create exact recipe matches from YouTube videos that include the full recipe in the description. If the description doesn't provide a complete recipe, our AI will generate one based on available information such as dish name and hashtags.
                </Trans>
              </p>
            </>
          )}

          {Capacitor.getPlatform() === 'ios' && (
            <>
              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.ios.step1.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.ios.step1.text")}</p>
                <img src={iosYT1} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.ios.step2.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.ios.step2.text")}</p>
                <img src={iosYT2} alt="" className="my-4" />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.ios.step3.title")}</h3>
                <p>{t("recipes.importSocialMedia.components.ImportYoutubeGuide.content.ios.step3.text")}</p>
                <img src={iosYT3} alt="" className="my-4" />
              </div>

              <p className="text-sm">
                <Trans i18nKey="recipes.importSocialMedia.components.ImportYoutubeGuide.content.ios.note">
                  <strong>Note:</strong> We can only create exact recipe matches from YouTube videos that include the full recipe in the description. If the description doesn't provide a complete recipe, our AI will generate one based on available information such as dish name and hashtags.
                </Trans>
              </p>
            </>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="primary">
              {t("recipes.importSocialMedia.components.ImportYoutubeGuide.actions.close")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}