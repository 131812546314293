import { Helmet } from "react-helmet-async"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

import { analytics, auth } from "@/firebase/app.ts"
import { AuthErrorCodes, signInWithEmailAndPassword } from "firebase/auth"
import { logEvent } from "firebase/analytics";

import Page from "@/components/page/Page.tsx";

import SocialLogins from "@/routes/(auth)/components/SocialLogins.tsx";

import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { Loader2 } from "lucide-react";

import LogoImg from "@/assets/icon.svg"

import { UserAccount } from "@/firebase/types"

export default function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const formSchema = z.object({
    email: UserAccount.shape.email
      .min(1, {
        message: t("(auth).login.page.card.form.fields.email.validation.required")
      })
      .email({
        message: t("(auth).login.page.card.form.fields.email.validation.pattern")
      }),

    password: UserAccount.shape.password
      .min(1, {
        message: t("(auth).login.page.card.form.fields.password.validation.required")
      })
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  })

  function goToNextPage() {
    const redirectTo = searchParams.get('redirectTo')

    if(redirectTo) {
      navigate(redirectTo)
    } else {
      navigate("/")
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { email, password } = values

    logEvent(analytics, 'login', {
      method: 'email',
    })

    return signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Log the user in on the native layer
        if(Capacitor.isNativePlatform()) {
          await FirebaseAuthentication.signInWithEmailAndPassword({ email, password })
        }

        goToNextPage()
      })
      .catch((error) => {
        console.error(error)

        // Auth error codes: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth

        // Not sure why this is called USER_DELETED, it's more like user not found
        // Use the same error message if the user gets the email or password wrong
        // This way we don't leak information like letting a hacker know they got the email right, but the password wrong.
        if(error.code === AuthErrorCodes.USER_DELETED || error.code === AuthErrorCodes.INVALID_PASSWORD) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).login.page.card.form.onSubmit.error.invalidEmailOrPassword")}`
          })
        } else if(error.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).login.page.card.form.onSubmit.error.tooManyAttempts")}`
          })
        } else if(error.code === AuthErrorCodes.INVALID_EMAIL) {
          form.setError("email", {
            type: error.code,
            message: `${t("(auth).login.page.card.form.onSubmit.error.invalidEmail")}`
          })
        } else if(error.code === AuthErrorCodes.USER_DISABLED) {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).login.page.card.form.onSubmit.error.userDisabled")}`
          })
        } else {
          form.setError("root", {
            type: error.code,
            message: `${t("(auth).login.page.card.form.onSubmit.error.generic")}`
          })
        }
      })
  }

  return (
    <Page className="justify-center py-4">
      <Helmet>
        <title>{t("(auth).login.page.head.title")}</title>
      </Helmet>

      <div className="m-4">
        <div className="flex justify-center items-center my-4">
          <img src={LogoImg} alt="Flavorish" className="w-12 mr-4" />
          <p className="font-poppins text-4xl text-base-content dark:text-white">Flavorish</p>
        </div>

        <Card className="max-w-lg mx-auto">
          <CardHeader className="space-y-1">
            <CardTitle className="text-2xl">{t("(auth).login.page.card.title")}</CardTitle>
          </CardHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col space-y-4">
              <CardContent className="flex flex-col gap-4 pb-2">
                <SocialLogins callback={() => goToNextPage()} />

                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      {t("(auth).login.page.card.separator")}
                    </span>
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("(auth).login.page.card.form.fields.email.label")}</FormLabel>
                      <FormControl>
                        <Input type="email" placeholder={t("(auth).login.page.card.form.fields.email.placeholder")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex justify-between items-center">
                        <FormLabel>{t("(auth).login.page.card.form.fields.password.label")}</FormLabel>

                        <Link
                          to="/forgot-password"
                          className="link text-sm"
                        >
                          {t('(auth).login.page.card.form.actions.forgotPassword')}
                        </Link>
                      </div>
                      <FormControl>
                        <Input type="password" placeholder={t("(auth).login.page.card.form.fields.password.placeholder")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>

              <CardFooter className="flex-col gap-4">
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full"
                  disabled={form.formState.isSubmitting}
                >
                  {form.formState.isSubmitting ? (
                    <Loader2 size={24} className="animate-spin" />
                  ) : t("(auth).login.page.card.form.actions.submit")}
                </Button>

                <FormRootErrorMessage />

                <p>
                  <Trans i18nKey="(auth).login.page.card.form.actions.dontHaveAnAccount">
                    Don't have an account yet? <Link to="/signup" className="link">Sign up</Link>
                  </Trans>
                </p>

              </CardFooter>
            </form>
          </Form>
        </Card>
      </div>
    </Page>
  )
}