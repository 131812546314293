import Page from "@/components/page/Page.tsx";

import { Loader2 } from "lucide-react";

export default function Loading() {
  return (
    <Page className="justify-center items-center">
      <Loader2 size={64} className="animate-spin" />
    </Page>
  )
}