import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";

import { GenerateRecipeFormInputs } from "@/routes/recipes/generate/types";

type Props = {
  form: UseFormReturn<GenerateRecipeFormInputs>
}
export default function AdditionalInformation({ form }: Props) {
  const { t } = useTranslation()

  form.register("additionalInformation", {
    maxLength: {
      value: 5000,
      message: `${t("recipes.generate.components.steps.AdditionalInformation.fields.additionalInformation.validation.maxLength", { maxLength: 5000 })}`
    }
  })

  return (
    <FormField
      control={form.control}
      name="additionalInformation"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t("recipes.generate.components.steps.AdditionalInformation.fields.additionalInformation.label")}</FormLabel>
          <FormControl>
            <Textarea placeholder={t("recipes.generate.components.steps.AdditionalInformation.fields.additionalInformation.placeholder")} {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}