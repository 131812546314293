import { auth, storage } from "@/firebase/app.ts";
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { Recipe } from "@/firebase/types";

export function getRecipeImageRef(recipeId: Recipe["id"], imageName: string) {
  if(auth.currentUser && auth.currentUser.uid) {
    return ref(storage, `images/${auth.currentUser.uid}/recipes/${recipeId}/${imageName}`)
  } else {
    throw new Error("lib.images.validation.userMissing")
  }
}

export async function uploadRecipeImage(recipeId: Recipe["id"], image: File) {
  if(auth.currentUser && auth.currentUser.uid) {
    const fileSizeMB = (image.size / (1024 * 1024)).toFixed(2); // MB

    if(parseInt(fileSizeMB) > 10) {
      throw new Error("lib.images.validation.upload.tooLarge")
    } else if(image.type !== "image/png" && image.type !== "image/jpeg" && image.type !== "image/webp") {
      throw new Error("lib.images.validation.upload.badType")
    } else {
      try {
        const imageRef = getRecipeImageRef(recipeId, image.name)

        // Upload the image
        const snapshot = await uploadBytes(imageRef, image)

        // Get the url for the image
        const url = await getDownloadURL(imageRef)

        const imageResult: Recipe["image"] = {
          name: snapshot.ref.name,
          url: url
        }

        return imageResult
      } catch(e) {
        console.error(e)

        throw new Error("lib.images.validation.generic")
      }
    }
  } else {
    throw new Error("lib.images.validation.userMissing")
  }
}

export async function deleteRecipeImage(recipeId: Recipe["id"], imageName: string) {
  if(auth.currentUser && auth.currentUser.uid) {
    const imageRef = getRecipeImageRef(recipeId, imageName)

    await deleteObject(imageRef)
  } else {
    throw new Error("lib.images.validation.userMissing")
  }
}