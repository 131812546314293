import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async";

import Page from "@/components/page/Page.tsx";
import { Button } from "@/components/ui/button.tsx";

import Error1Image from "@/assets/images/Error 1.svg"

// TODO: Pass a message to this for better feedback
export default function ErrorLoading() {
  const { t } = useTranslation()

  return (
    <Page className="justify-center items-center p-4 mb-20">
      <Helmet>
        <title>{t("components.ErrorLoading.head.title")}</title>
      </Helmet>

      <img src={Error1Image} alt={t("components.ErrorLoading.image.alt")} className="max-w-xs" />

      <div className="text-center">
        <h2 className="text-4xl font-semibold mb-2">{t("components.ErrorLoading.title")}</h2>
        <p>{t("components.ErrorLoading.text")}</p>

        <Button
          className="mt-4"
          onClick={() => window.location.reload()}
        >
          {t("components.ErrorLoading.actions.refresh")}
        </Button>
      </div>
    </Page>
  )
}