import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { db, analytics } from "@/firebase/app"
import { logEvent } from "firebase/analytics";
import { doc, serverTimestamp, writeBatch } from "firebase/firestore"

import { useToast } from "@/components/ui/use-toast.ts";

import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { DialogFooter } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormRootErrorMessage } from "@/components/ui/form.tsx";

import { Image, Loader2 } from "lucide-react";

import { Collection, Recipe } from "@/firebase/types.ts";

type Props = {
  collections: Collection[]
  recipe: Recipe
  setIsOpen: (isOpen: boolean) => void
}
export default function SaveToCollectionsModalForm({ collections, recipe, setIsOpen }: Props) {
  const { t } = useTranslation()
  const { toast } = useToast()

  const formSchema = z.object({
    collections: z.object({
      id: Collection.shape.id,
      title: Collection.shape.title,
      recipeIds: Collection.shape.recipeIds,
      recipeImages: Collection.shape.recipeImages,
      checked: z.boolean()
    }).array()
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      collections: collections.map((collection) => {
        return {
          ...collection,
          checked: !!(collection.recipeIds.find(recipeId => recipeId === recipe.id))
        }
      })
    }
  })

  const {
    fields: collectionsFields
  } = useFieldArray({
    control: form.control,
    name: "collections"
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { collections } = values

    try {
      const batch = writeBatch(db)

      for(const collection of collections) {
        const collectionRef = doc(db, "collections", collection.id)

        const recipeIdIndex = collection.recipeIds.indexOf(recipe.id)

        // This helps us by not updating a collection that doesn't need to be changed - keeps _updated timestamp more accurate and costs less writes
        let shouldUpdateThisCollection = false

        const updatedRecipeIds = [...collection.recipeIds]
        const updatedRecipeImages = [...collection.recipeImages]

        if(collection.checked) {
          if(recipeIdIndex > -1) {
            // Recipe already exists in this collection, do nothing
          } else {
            shouldUpdateThisCollection = true
            updatedRecipeIds.push(recipe.id)
            updatedRecipeImages.push(recipe.image?.url || null)
          }
        } else {
          if(recipeIdIndex > -1) {
            shouldUpdateThisCollection = true
            updatedRecipeIds.splice(recipeIdIndex, 1)
            updatedRecipeImages.splice(recipeIdIndex, 1)
          } else {
            // Recipe isn't in this collection anyways, do nothing
          }
        }

        if(shouldUpdateThisCollection) {
          batch.update(collectionRef, {
            _updated: serverTimestamp(),
            recipeIds: updatedRecipeIds,
            recipeImages: updatedRecipeImages
          })
        }
      }

      await batch.commit()

      logEvent(analytics, 'save_recipe_to_collections')

      toast({
        variant: "success",
        title: t("recipes.[id].components.modals.SaveToCollectionsModalForm.form.onSubmit.success.title", { recipeName: recipe.title })
      })

      setIsOpen(false)
    } catch(e) {
      console.error(e)

      form.setError("root", {
        message: `${t("recipes.[id].components.modals.SaveToCollectionsModalForm.form.onSubmit.error.generic")}`
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="space-y-2">
          <FormLabel>{t("recipes.[id].components.modals.SaveToCollectionsModalForm.form.fields.collections.label")}</FormLabel>

          <ul className="flex flex-col gap-4">
            {collectionsFields.map((collection, index) => (
              <li key={collection.id} className="w-full">
                <FormField
                  control={form.control}
                  name={`collections.${index}`}
                  render={({ field }) => {
                    const recipeImages = [...collection.recipeImages].reverse()

                    return (
                      <FormItem>
                        <FormLabel className="flex flex-row justify-between items-center gap-4">
                          <div className="flex items-center gap-4">
                            <div className="w-16 min-w-16 h-16 min-h-16">
                              <AspectRatio ratio={1} className="bg-muted rounded-md overflow-hidden">
                                {recipeImages[0] ? (
                                  <img src={recipeImages[0]} alt="" className="w-full h-full object-cover transition-all hover:scale-105" />
                                ) : (
                                  <div className="flex justify-center items-center w-full h-full">
                                    <Image size={32} className="text-muted-foreground" />
                                  </div>
                                )}
                              </AspectRatio>
                            </div>

                            <div>
                              <p className="text-base">
                                {collection.title}
                              </p>
                              <p className="text-sm text-muted-foreground">
                                {t("recipes.[id].components.modals.SaveToCollectionsModalForm.form.fields.collections.numRecipes", { count: collection.recipeIds.length })}
                              </p>
                            </div>
                          </div>

                          <FormControl>
                            <Checkbox
                              checked={field.value.checked}
                              onCheckedChange={(value) => {
                                field.onChange({
                                  ...field.value,
                                  checked: value
                                })
                              }}
                              className="w-5 h-5"
                            />
                          </FormControl>
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
              </li>
            ))}
          </ul>
        </div>

        <DialogFooter className="max-sm:gap-2">
          <Button
            type="button"
            variant="accent"
            className="w-full"
            onClick={() => setIsOpen(false)}
          >
            {t("recipes.[id].components.modals.SaveToCollectionsModalForm.form.actions.cancel")}
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="w-full"
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? (
              <Loader2 size={24} className="animate-spin" />
            ) : t("recipes.[id].components.modals.SaveToCollectionsModalForm.form.actions.submit")}
          </Button>
        </DialogFooter>

        <FormRootErrorMessage />
      </form>
    </Form>
  )
}