import { useEffect, useRef, useState } from "react";

import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase/app.ts";
import { uuidv4 } from "@firebase/util";

import base64ImageToFile from "@/lib/helpers/base64ImageToFile.ts";
import { uploadRecipeImage } from "@/lib/images.ts";

import { parse } from "best-effort-json-parser";

import { Recipe } from "@/firebase/types";
import { GenerateRecipeFormInputs } from "@/routes/recipes/generate/types";

type GeneratedRecipeType = Pick<Recipe,
  "id" | "title" | "description" | "image" | "ingredients" | "instructions" | "prepTime" | "cookTime" | "totalTime" |
  "servings" | "keywords" | "category" | "cuisine" | "nutrition"
>

// Streams in a recipe from worker and retrieves an image from another worker
export default function useStreamGeneratedRecipe(fields: GenerateRecipeFormInputs, isSubscribed = false) {
  const [streamedRecipe, setStreamedRecipe] = useState<GeneratedRecipeType | null>(null)
  const [recipeImage, setRecipeImage] = useState<Recipe["image"] | null>(null)
  const [recipeError, setRecipeError] = useState<any | null>(null)
  const [imageError, setImageError] = useState<any | null>(null)
  const isStreaming = useRef(false)
  const isFetchingImage = useRef(false)
  const [isComplete, setIsComplete] = useState(false)

  const recipeId = useRef(uuidv4())

  const [titleAndDescriptionIsComplete, setTitleAndDescriptionIsComplete] = useState<any | null>(false)

  const incrementFreeAIRecipeGenerationsFunc = httpsCallable(functions, 'incrementFreeAIRecipeGenerations')

  // Start streaming the recipe when this hook is called
  useEffect(() => {
    if(!isStreaming.current) {
      streamGeneratedRecipe()
    }
  }, [])

  // Start generating the image
  useEffect(() => {
    if(titleAndDescriptionIsComplete && streamedRecipe?.title && streamedRecipe?.description) {
      fetchRecipeImage(streamedRecipe.title, streamedRecipe.description)
    }
  }, [titleAndDescriptionIsComplete])

  // Once the recipe generation is complete, increment the number of free AI generated recipes on the user - only if they're not a premium user and nothing went wrong
  useEffect(() => {
    if(!isSubscribed && isComplete && !recipeError) {
      incrementFreeAIRecipeGenerationsFunc()
    }
  }, [isComplete])

  async function streamGeneratedRecipe() {
    if(fields) {
      try {
        isStreaming.current = true

        const res = await fetch(import.meta.env.VITE_RECIPE_GENERATOR_WORKER, {
          method: "POST",
          body: JSON.stringify(fields)
        })

        if(!res.ok) {
          throw new Error("Something went wrong while fetching recipe from worker")
        }

        if(res.body) {
          let reader = res.body.getReader();
          let result;
          let decoder = new TextDecoder('utf8');

          let recipeStream = ""

          // Keep track of the title and description field lengths
          // Once these chunks are streamed in, we can start generating the image. Instead of waiting for the whole recipe to complete
          let titleLen = 0
          let isTitleComplete = false
          let descriptionLen = 0
          let isDescriptionComplete = false

          while(!result?.done) {
            result = await reader.read();
            let chunk = decoder.decode(result.value);

            recipeStream += chunk

            // Try to update the recipe JSON object
            try {
              const parsedRecipe = parse(recipeStream)
              setStreamedRecipe(parsedRecipe)

              // If previous title/description text length matches current length, we know each field has completed streaming, mark as complete
              if(parsedRecipe.title) {
                if(titleLen < parsedRecipe.title.length) {
                  titleLen = parsedRecipe.title.length
                } else {
                  isTitleComplete = true
                }
              }

              if(parsedRecipe.description) {
                if(descriptionLen < parsedRecipe.description.length) {
                  descriptionLen = parsedRecipe.description.length
                } else {
                  isDescriptionComplete = true
                }
              }

              // Once both fields are completed mark complete so we can start generating the image
              if(isTitleComplete && isDescriptionComplete) {
                setTitleAndDescriptionIsComplete(true)
              }
            } catch(e) {
              console.error(e)
              // If it fails, no worries, the next chunk will probably be successful, just don't error out
            }
          }

          if(result.done) {
            isStreaming.current = false

            if(!isFetchingImage.current) {
              setIsComplete(true)
            }
          }
        }
      } catch(e: any) {
        console.error(e)

        setRecipeError(e)
        isStreaming.current = false

        if(!isFetchingImage.current) {
          setIsComplete(true)
        }
      }
    }
  }

  async function fetchRecipeImage(title: Recipe["title"], description: Recipe["description"]) {
    try {
      isFetchingImage.current = true

      const res = await fetch(import.meta.env.VITE_RECIPE_IMAGE_GENERATOR_WORKER, {
        method: "POST",
        body: JSON.stringify({ title, description })
      })

      if(!res.ok) {
        throw new Error("Something went wrong while fetching recipe image from worker")
      }

      const image = await res.json()

      if(image) {
        const imageFile = await base64ImageToFile(image, `${title.toLowerCase().split(' ').join('-')}-ai-generated-image`)

        const uploadedImage = await uploadRecipeImage(recipeId.current, imageFile)

        if(uploadedImage) {
          setRecipeImage(uploadedImage)
        }

        isFetchingImage.current = false

        if(!isStreaming.current) {
          setIsComplete(true)
        }
      }
    } catch(e: any) {
      console.error(e)

      setImageError(e)
      isFetchingImage.current = false

      if(!isStreaming.current) {
        setIsComplete(true)
      }
    }
  }

  return {
    streamedRecipe: {
      id: recipeId.current,
      ...streamedRecipe,
      image: recipeImage
    } as GeneratedRecipeType,
    isComplete,
    recipeError,
    imageError
  }
}